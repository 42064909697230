import { Route, Routes, Navigate } from 'react-router-dom';

import Layout from "./components/layout/Layout";
import Auth from './pages/Auth';
import Login from './pages/Login';
import MySpaces from "./pages/MySpaces";
import NewClusta from './pages/NewClusta';
import Signup from './pages/Signup';

import AuthContext from './store/auth-context';
import { useContext, useEffect, useState } from 'react'
import ManageAccount from './pages/ManageAccount';
import SignupConfirm from './pages/SignupConfirm';
import ConfirmActivation from './pages/ConfirmActivation';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import CollContext from './store/CollContext';
import ResendActivateEmail from './pages/ResendActivateEmail';
import ToPaymentForm from './components/forms/ToPaymentForm';
import PaymentConfirmation from './components/confirmation/PaymentConfirmation';
import CallNotifications from './components/notifications/CallNotifications';

import { getAuth, signInWithCustomToken, onAuthStateChanged, signOut } from "firebase/auth";

function App() {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  const [firebaseAuth, setFirebaseAuth] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        // const uid = user.uid;
        setFirebaseAuth(user);
        // ...
      } else {
        // User is signed out
        // ...
        setFirebaseAuth(null);
      }
    });
  }, [auth, setFirebaseAuth])

  useEffect(() => {
    if (isLoggedIn) {
      const token = authCtx.firestore_token;
      signInWithCustomToken(auth, token)
        .then((userCredential) => {
          // Signed in
          // const user = userCredential.user;
          return
        })
        .catch((error) => {
          // const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage)
        });
    } if (!isLoggedIn) {
      signOut(auth);
    }
  }, [isLoggedIn, authCtx.firestore_token, auth, user])

  return (
    <Layout>
      {(firebaseAuth && isLoggedIn) && (
        <CallNotifications />
      )}
      <Routes>
        <Route path='/' element={isLoggedIn ? <Navigate replace to='/home' /> : <Navigate replace to='/auth/login' />} />
        <Route path='/home' element={<MySpaces />} />
        <Route path='/auth' element={<Auth />}>
          <Route path={`login`} element={<Login />} />
          <Route path={`signup`} element={<Signup />} />
          <Route path={`account`} element={<ManageAccount />} />
          <Route path={`signup_success`} element={<SignupConfirm />} />
          <Route path={`reset_password`} element={<ForgotPassword />} />
          <Route path={`resend_activation`} element={<ResendActivateEmail />} />
          <Route path={`upgrade`} element={<ToPaymentForm />} />
          <Route path={`payment/:completed`} element={<PaymentConfirmation />} />
        </Route>
        <Route path='/newcollection' element={<NewClusta />} />
        <Route path='/activate/:uid/:token' element={<ConfirmActivation />} />
        <Route path='/password/reset/confirm/:uid/:token' element={<ResetPassword />} />
        <Route path='/:handle/*' element={<CollContext />} />
      </Routes>
    </Layout>
  );
}

export default App;
