import { loadStripe } from '@stripe/stripe-js';
const API_DOMAIN = process.env.REACT_APP_API_URL;
// const STRIPE_KEY = process.env.REACT_APP_STRIPE_API_KEY;

export async function createCheckout() {
    const configResponse = await fetch(`${API_DOMAIN}/payments/config/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const configData = await configResponse.json();

    if (!configResponse.ok) {
        throw new Error(data.detail);
    }

    const key = configData.pubKey

    const stripe = await loadStripe(key);

    const response = await fetch(`${API_DOMAIN}/payments/create-checkout-session/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.error);
    }
    
    return stripe.redirectToCheckout({sessionId: data.sessionId});
}

export async function customerPortal() {
    const response = await fetch(`${API_DOMAIN}/payments/portal/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.error);
    }

    return data;
}