import { Fragment, useContext } from "react";
import { useState } from 'react';
import AuthContext from "../../store/auth-context";
import ThemeChoiceForm from "./themeSelection/ThemeChoiceForm";
import CustomThemeForm from "./themeSelection/CustomThemeForm";
import { isValidHexCode } from "../../store/themesConfig"
import { Link } from 'react-router-dom';
import { ReactComponent as InfinityLogo } from '../../icons/ClustaInfinity2.svg'

const CollSettingsForm = (props) => {
    const authCtx = useContext(AuthContext);
    const subType = authCtx.sub_type;

    const collData = props.collection;

    const removeHashtag = (val) => {
        const firstChar = val[0];
        if (firstChar === '#') {
            return val.slice(1);
        } else {
            return val;
        }
    }

    const noHashHighlight = removeHashtag(collData.custom_theme.highlight)
    const noHashCard = removeHashtag(collData.custom_theme.card)
    const noHashBg = removeHashtag(collData.custom_theme.bg)

    const [confirm, setConfirm] = useState(false);

    const [curTheme, setCurTheme] = useState(collData.theme);
    const [curHandle, setCurHandle] = useState(collData.handle);
    const [curDisplayName, setCurDisplayName] = useState(collData.displayname);

    // the following states record custom theme colour choices
    const [csHighlight, setCsHighlight] = useState(noHashHighlight);
    const [csCard, setCsCard] = useState(noHashCard);
    const [csBg, setCsBg] = useState(noHashBg);

    // all cs colour states in an object, easier to pass into CustomThemeForm component
    const allCsCols = {
        bg: csBg,
        card: csCard,
        highlight: csHighlight
    }

    const updateHandle = (e) => {
        setCurHandle(e.target.value);
    };

    const updateDisplayName = (e) => {
        setCurDisplayName(e.target.value);
    };

    const themeHandler = (e) => {
        setCurTheme(e.target.value);
    };

    const onConfirm = () => {
        setConfirm((prevState) => !prevState);
    };

    const submitDelete = () => {
        props.onDelete(collData.handle);
    };

    const submitLeave = () => {
        props.onLeave()
    };

    function submitCollectionFormHandler(event) {
        event.preventDefault();

        const enteredHandle = curHandle;
        const enteredDisplayname = curDisplayName;
        const selectedTheme = curTheme;

        const formattedHandle = enteredHandle.replace(/\s/g, '');

        const validHighlight = isValidHexCode(csHighlight)
        const validCard = isValidHexCode(csCard)
        const validBg = isValidHexCode(csBg)
        const validCsTheme = validBg && validCard && validHighlight

        if (formattedHandle.match(/[^\w\-]/) || formattedHandle === 'home' || formattedHandle === 'auth' || formattedHandle === 'activate' || !validCsTheme) {
            return;
        } else {
            props.onSubmit({
                handle: collData.handle,
                data: {
                    handle: formattedHandle,
                    displayname: enteredDisplayname,
                    theme: selectedTheme,
                    custom_theme: {
                        bg: `#${csBg}`,
                        card: `#${csCard}`,
                        highlight: `#${csHighlight}`
                    }
                },
            });
        }
    }

    // const theme = collData.theme;
    // const bgColour = theme === 'DF' ? 'rgba(251, 113, 133, 0.5)' : theme === 'BL' ? 'rgba(3, 105, 161, 0.9)' : theme === 'GR' ? 'green' : theme === 'YE' ? 'yellow' : '';

    if (props.context.isOwner) {
        return (
            <Fragment>
                {!confirm && (
                    <div className='px-5 py-5'>
                        <div>
                            <div className="flex flex-col mb-6 md:w-full">
                                <div className="font-elight text-lg pl-3 outline-none text-darkgrey">Collection Name:</div>
                                <input className="font-elight text-lg p-3 outline-none text-darkgrey placeholder-darkgrey/70 bg-transparent border-darkgrey/70 border-b" type="text" placeholder="Name (spaces allowed)" maxLength="30" value={curDisplayName} onChange={updateDisplayName} />
                            </div>
                            <div className="flex flex-col mb-6 md:w-full">
                                <div className="font-elight text-lg pl-3 outline-none text-darkgrey">URL Handle:</div>
                                <div className="relative w-full">
                                    <input className="font-elight w-full text-lg p-3 outline-none text-darkgrey placeholder-darkgrey/70 bg-transparent border-darkgrey/70 border-b" type="text" placeholder="Handle (app.clusta.live/yourhandle)" maxLength="30" value={curHandle} onChange={updateHandle} />
                                    {subType === 'LIT' && (
                                        <Link to="/auth/upgrade" className="absolute top-0 left-0 h-full w-full bg-salmon opacity-0 hover:opacity-100 transition-all text-3xl flex place-content-center">
                                            <InfinityLogo style={{ 'color': `white` }} className="h-full" />
                                        </Link>
                                    )}
                                </div>
                                {/* <input className="font-elight text-lg p-3 outline-none text-darkgrey placeholder-darkgrey/70 bg-transparent border-darkgrey/70 border-b" type="text" placeholder="Handle (app.clusta.live/yourhandle)" maxLength="30" value={curHandle} onChange={updateHandle} /> */}
                            </div>
                        </div>
                        <div className="flex place-content-center text-darkgrey text-2xl font-elight">Theme</div>
                        {/* <div className="flex flex-row gap-4 place-content-center pt-2" onChange={themeHandler}>
                            <input readOnly className="form-check-input cursor-pointer appearance-none rounded-lg h-10 w-10 bg-gradient-to-t from-lightgrey to-salmon checked:border-2 checked:border-darkgrey" name="theme" type="radio" value="DF" checked={curTheme === 'DF'} />
                            <input readOnly className="form-check-input cursor-pointer appearance-none rounded-lg h-10 w-10 bg-gradient-to-t from-[#002C3D] to-[#D81159] checked:border-2 checked:border-darkgrey" name="theme" type="radio" value="BL" checked={curTheme === 'BL'} />
                            {subType !== 'LIT' && (
                                <Fragment>
                                    <input readOnly className="form-check-input cursor-pointer appearance-none rounded-lg h-10 w-10 bg-gradient-to-t from-[#204E4A] to-[#F85E00] checked:border-2 checked:border-darkgrey" name="theme" type="radio" value="GR" checked={curTheme === 'GR'} />
                                    <input readOnly className="form-check-input cursor-pointer appearance-none rounded-lg h-10 w-10 bg-gradient-to-t from-[#FFEBE7] to-[#FFC2D9] checked:border-2 checked:border-darkgrey" name="theme" type="radio" value="YE" checked={curTheme === 'YE'} />
                                </Fragment>
                            )}
                        </div> */}
                        <ThemeChoiceForm updateTheme={themeHandler} curTheme={curTheme} subType={subType} />
                        {curTheme === 'CS' && (
                            <CustomThemeForm
                                colours={allCsCols}
                                setCsBg={setCsBg}
                                setCsCard={setCsCard}
                                setCsHighlight={setCsHighlight}
                            />
                        )}
                        <div onClick={submitCollectionFormHandler} className="mt-10 mx-20 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey border border-darkgrey/30 hover:scale-105 hover:bg-salmon hover:border-salmon hover:text-white transition ease-in-out">Update Collection</div>
                        <div onClick={onConfirm} className="mt-5 mx-20 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey border border-darkgrey/30 hover:scale-105 hover:bg-salmon hover:border-salmon hover:text-white transition ease-in-out">Delete Collection</div>
                    </div>
                )}
                {confirm && (
                    <div className='px-5 flex flex-col h-full place-content-center'>
                        <div className="mt-5 flex place-content-center text-darkgrey text-3xl font-elight">Are you sure?</div>
                        <div className="mt-5 flex place-content-center text-darkgrey text-xl font-elight">All sparks in this collection will also be deleted. All information will be lost.</div>
                        <div onClick={onConfirm} className="mt-10 mx-20 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey border border-darkgrey/30 hover:scale-105 hover:bg-salmon hover:border-salmon hover:text-white transition ease-in-out">Cancel</div>
                        <div onClick={submitDelete} className="mt-5 mx-20 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey border border-darkgrey/30 hover:scale-105 hover:bg-salmon hover:border-salmon hover:text-white transition ease-in-out">Delete Collection</div>
                    </div>
                )}
            </Fragment>
        );
    };

    if (props.context.isMember) {
        return (
            <Fragment>
                {/* <div className="absolute -top-4 w-full flex place-content-center">
                    <div style={{ borderColor: bgColour }} className="border bg-white text-darkgrey/60 font-elight text-xl p-4 rounded-md">{collData.displayname}</div>
                </div> */}
                {!confirm && (
                    <div className='px-5 flex flex-col h-full place-content-center'>
                        <div onClick={onConfirm} className="mx-20 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey/60 border hover:scale-105 hover:border-rose-400 transition ease-in-out">Leave Collection</div>
                    </div>
                )}
                {confirm && (
                    <div className='px-5 flex flex-col h-full place-content-center'>
                        <div className="mt-5 flex place-content-center text-darkgrey/60 text-3xl font-elight">Are you sure?</div>
                        <div className="mt-5 flex place-content-center text-darkgrey/60 text-xl font-elight">You are now leaving {collData.displayname}.</div>
                        <div onClick={onConfirm} className="mt-10 mx-20 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey/60 border hover:scale-105 hover:border-green-400 transition ease-in-out">Cancel</div>
                        <div onClick={submitLeave} className="mt-5 mx-20 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey/60 border hover:scale-105 hover:border-rose-400 transition ease-in-out">Leave Collection</div>
                    </div>
                )}
            </Fragment>
        );
    };

    if (!props.context.isMember && !props.context.isOwner) {
        return <div className="text-darkgrey font-elight">You must be a member of this collection to access this</div>
    };
};

export default CollSettingsForm;