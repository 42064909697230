import { ReactComponent as ImageIcon } from "../../../icons/imageIcon.svg";
import { ReactComponent as PlusIcon } from "../../../icons/plus.svg";
import { ReactComponent as MinusIcon } from "../../../icons/minus.svg";

const ImageUploadField = ({ Preview, setSparkImage, setPreview }) => {

    const addPic = (e) => {
        const file = e.target.files[0];
        if (file.size < 1048576 * 8) {
            setSparkImage(file);
            const objectUrl = URL.createObjectURL(file)
            setPreview(objectUrl)
        }
    };

    const removePic = () => {
        setSparkImage(null)
        setPreview('')
    }

    const wrapperClass = "opacity-60 hover:opacity-80 transition-all px-1 flex flex-row items-center justify-center w-full rounded-lg cursor-pointer border border-gray-300/50 border-dashed"

    return (
        <div>
            {(!Preview) && (
                <label htmlFor="dropzone-file" className={wrapperClass}>
                    <div onDrop={addPic} className="flex flex-row items-center justify-center">
                        {/* <p className="text-md font-elight">-</p> */}
                        <PlusIcon className="w-5 h-5" />
                        <ImageIcon className="w-5 h-5" />
                    </div>
                    <input accept="image/jpeg,image/png" id="dropzone-file" onChange={addPic} type="file" className="hidden" />
                </label>
            )}
            {(Preview) && (
                <div onClick={removePic} className={wrapperClass}>
                    <MinusIcon className="w-5 h-5" />
                    <ImageIcon className="w-5 h-5" />
                </div>
            )}
        </div>
    );
};

export default ImageUploadField;