const API_DOMAIN = process.env.REACT_APP_API_URL;


// User sign up - requires email, password, first_name, last_name, sub_type

export async function userSignup(signupData) {
    const response = await fetch(`${API_DOMAIN}/auth/users/`, {
        method: 'POST',
        body: JSON.stringify(signupData),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    // const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not sign up.');
    }

    return null;
}


// user login - requires email, password

export async function userLogin(loginData) {
    const response = await fetch(`${API_DOMAIN}/auth/token/login/`, {
        method: 'POST',
        body: JSON.stringify(loginData),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not login.');
    }

    if (!data.token) {
        throw new Error('No token found')
    }

    return data;
}


// User activation

export async function userActivate(userData) {
    const response = await fetch(`${API_DOMAIN}/auth/users/activation/`, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Could not activate user');
    }

    return null;
}


// Resend user activation

export async function resendActivation(userEmail) {
    const response = await fetch(`${API_DOMAIN}/auth/users/resend_activation/`, {
        method: 'POST',
        body: JSON.stringify(userEmail),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Could not resend activation email');
    }

    return null;
}


// User detail (GET request)

export async function userDetail() {
    const response = await fetch(`${API_DOMAIN}/auth/users/me/`, {
        method: 'GET',
        headers: {
            // 'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const data = response.json();

    if (!response.ok) {
        throw new Error('Could not fetch user');
    }

    return data;
}


// Update User (for first_name and last_name only - username and pw updated below)

export async function updateUser(userData) {
    const response = await fetch(`${API_DOMAIN}/auth/users/me/`, {
        method: 'PATCH',
        body: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const data = response.json();

    if (!response.ok) {
        throw new Error('Could not update user');
    }

    return data;
}


// User delete

export async function deleteUser(password) {
    const response = await fetch(`${API_DOMAIN}/auth/users/me/`, {
        method: 'DELETE',
        body: JSON.stringify(password),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });

    if (!response.ok) {
        throw new Error('Could not update user');
    }

    return null;
}


// Update email - takes new_email re_new_email and current_password

export async function changeEmail(userData) {
    const response = await fetch(`${API_DOMAIN}/auth/users/set_email/`, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });

    if (!response.ok) {
        throw new Error('Could not change email');
    }

    return null;
}


// Update password - takes new_password, re_new_password and current_password

export async function changePassword(pwData) {
    const response = await fetch(`${API_DOMAIN}/auth/users/set_password/`, {
        method: 'POST',
        body: JSON.stringify(pwData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });

    if (!response.ok) {
        throw new Error('Could not update password');
    }

    return null;
}


// Request reset password - email user link to reset password

export async function requestResetPassword(email) {
    const response = await fetch(`${API_DOMAIN}/auth/users/reset_password/`, {
        method: 'POST',
        body: JSON.stringify(email),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Could not send password reset request');
    }

    return null;
}


// Reset password - takes uid, token, new_password and re_new_password

export async function resetPassword(resetData) {
    const response = await fetch(`${API_DOMAIN}/auth/users/reset_password_confirm/`, {
        method: 'POST',
        body: JSON.stringify(resetData),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Could not resend activation email');
    }

    return null;
}


// Get details on user's sub status - their sub type and status

export async function getSubStatus() {
    const response = await fetch(`${API_DOMAIN}/account/status/`, {
        method: 'GET',
        headers: {
            // 'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const data = response.json();

    if (!response.ok) {
        throw new Error('Could not fetch sub status data');
    }

    return data;
}