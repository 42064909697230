import { useContext } from "react";
import IntroContent from "../card-content/IntroContent";
import AuthContext from "../../store/auth-context";
import SurCardWrapper from "./SurCardWrapper";

const IntroCard = (props) => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme
    const cardBg = theme.card;

    return (
        <SurCardWrapper bg={cardBg}>
            <IntroContent
                type={props.type}
                data={props.data} />
        </SurCardWrapper>
        // <div className="relative flex place-content-center shadow-md rounded-lg bg-white px-4">
        //     <IntroContent
        //         type={props.type}
        //         data={props.data} />
        // </div>
    );
}

export default IntroCard;