import { Fragment, useContext, useEffect } from "react";
import NewClustaForm from "../components/forms/NewClustaForm";
import FormContainer from "../components/forms/FormContainer";
import Navigation from "../components/layout/Navigation";

import useHttp from "../hooks/use-http";
import { createCollection } from "../lib/api";
import { useNavigate } from "react-router-dom";
import AuthContext from "../store/auth-context";

const NewClusta = () => {
    const authCtx = useContext(AuthContext);
    const setTheme = authCtx.updateTheme;
    const { sendRequest, data, error, status } = useHttp(createCollection);
    const navigate = useNavigate()

    useEffect(() => {
        setTheme('DF');
    })

    useEffect(() => {
        if (status === 'completed' && !error) {
            navigate(`/${data.handle}`);
        }
    }, [status, navigate, data, error])

    const collectionFormHandler = (collData) => {
        sendRequest(collData);
    };

    return (
        <Fragment>
            <Navigation state='myGroups' />
            <FormContainer>
                <NewClustaForm onSubmit={collectionFormHandler} />
            </FormContainer>
        </Fragment>
    );
};

export default NewClusta;