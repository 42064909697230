import { ReactComponent as User } from '../../icons/user.svg'
import { ReactComponent as Eye } from '../../icons/eye2.svg'
import { ReactComponent as Members } from '../../icons/users.svg'
import { ReactComponent as Settings } from '../../icons/settings.svg'
import { ReactComponent as PublicToggle } from '../../icons/eye.svg'
import { ReactComponent as CollectionMenu } from '../../icons/circle-dots.svg'

import { Fragment, useContext, useEffect, useState } from 'react';

import AuthContext from '../../store/auth-context';
import { Link, useNavigate } from 'react-router-dom';

import { Menu, Transition } from '@headlessui/react'
import MemberContext from '../../store/isMember-context'


const DropDownMenu = (props) => {

	const authCtx = useContext(AuthContext);
	const theme = authCtx.theme;
	const themePrimary = theme.highlight;
	const memberCtx = useContext(MemberContext);
	const pubCtx = memberCtx.public;

	const navigate = useNavigate()

	const logoutHandler = () => {
		authCtx.logout();
		navigate('/auth/login');
	};

	const togglePublic = () => {
		pubCtx.function((state) => !state);
	}

	const textCol = props.textCol;

	const [textColour, setTextColour] = useState('');

	useEffect(() => {
		setTextColour(textCol);
	}, [textCol, setTextColour])

	const hoverTextCol = () => {
		setTextColour(themePrimary);
		return;
	}

	const defTextCol = () => {
		setTextColour(textCol);
		return;
	}

	if (props.id === 'collection-menu') {
		return (
			<Menu onMouseEnter={hoverTextCol} onMouseLeave={defTextCol} as="div" className={"relative flex place-content-center"}>
				<Menu.Button>
					<CollectionMenu style={{ 'stroke': `${textColour}`}} className={`self-center w-6 h-6 cursor-pointer hover:stroke-salmon`} title={`Collection Menu`} />
				</Menu.Button>
				{pubCtx.state && (
					<div className='absolute flex place-content-center top-1 left-8'>
						<Eye className="w-6 h-6 stroke-darkgrey/60" />
					</div>
				)}
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items style={{ 'stroke': `${textCol}`, 'fill': `${textCol}`}} className="absolute -top-2 left-8 p-1 z-50 font-elight h-auto w-70 stroke-darkgrey/60 fill-darkgrey/60 text-darkgrey/60 shadow-2xl rounded-lg bg-white focus:outline-none">
						<div className='flex flex-col sm:flex-row leading-tight self-center content-center w-full h-full items-center text-sm align-middle text-center'>
							{/* <p className='cursor-default mr-2'>View Modes</p> */}
							<Menu.Item>
								{({ active }) => (
									<div onClick={() => togglePublic()} className={`py-1 w-12 text-darkgrey ${active && 'bg-salmon text-white transition duration-150 ease-out rounded-lg cursor-pointer'}`} >
										<div className='h-6 w-6 m-auto'>
											<PublicToggle
												className='w-6 h-6'
												title={`Toggle Private Sparks`}
											/>
										</div>
										<div className={`font-light text-[0.5rem]`}>Public</div>
									</div>
								)}
							</Menu.Item>
							{/* <Divider className="mx-0 px-0" /> */}
							{props.isOwner && (
								<Menu.Item>
									{({ active }) => (
										<Link to={`managemembers`} className={`py-1 w-12 text-darkgrey ${active && 'bg-salmon text-white transition duration-150 ease-out rounded-lg cursor-pointer'}`} href={`${props.handleName}/managemembers`} >
											<div className='h-6 w-6 m-auto'>
												<Members
													title={`Manage Members`}
													className='w-6 h-6'
												/>
											</div>
											<div className={`font-light text-[0.5rem]`}>Members</div>
										</Link>
									)}
								</Menu.Item>
							)}
							<Menu.Item>
								{({ active }) => (
									<Link to={`settings`} className={`py-1 w-12 text-darkgrey ${active && 'bg-salmon text-white transition duration-150 ease-out rounded-lg cursor-pointer'}`} href={`${props.handleName}/settings`}>
										<div className='h-6 w-6 m-auto'>
											<Settings
												title={`Collection Settings`}
												className='w-6 h-6'
											/>
										</div>
										<div className={`font-light text-[0.5rem]`}>Settings</div>
									</Link>
								)}
							</Menu.Item>
						</div>
					</Menu.Items>
				</Transition>
			</Menu>
		)
	}

	if (props.id === 'account-manager') {
		if (!authCtx.isLoggedIn) {
			return (
				<Link
					title={`Login`}
					to={'/auth/login'}
					onClick={() => { navigate('/auth/login'); }}
				>
					<User />
				</Link>
			)
		}
		if (authCtx.isLoggedIn) {
			return (
				<Menu>
					<Menu.Button className={'relative'}>
						{props.sub_status === 'past_due' && (
							<div className='absolute bg-red-500 top-1 right-1 rounded-full'></div>
						)}
						<User onMouseEnter={hoverTextCol} onMouseLeave={defTextCol} style={{ 'stroke': `${textColour}` }} className={'self-center w-10 h-10 cursor-pointer rounded-lg hover:stroke-salmon p-1'} />
					</Menu.Button>
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<Menu.Items className="absolute top-12 z-50 font-elight w-1/8 origin-right self-center stroke-darkgrey/60 fill-darkgrey/60 text-darkgrey bg-white bg-opacity-85 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div className='grid grid-flow-row gap-0 p-1 text-left grid-rows-2 items-center text-sm align-middle cursor-pointer'>
								{authCtx.sub_type === 'LIT' && (
									<Menu.Item>
									{({ active }) => (
										<Link className={`p-1 rounded-md bg-eggshell/100 font-light ${active && 'text-white transition duration-150 ease-out'}`} href="/auth/upgrade"
											title={`Upgrade`}
											to={'/auth/upgrade'}
										>
											Upgrade to Infinity
										</Link>
									)}
								</Menu.Item>
								)}
								<Menu.Item>
									{({ active }) => (
										<Link className={`p-1 ${active && 'bg-salmon text-white transition duration-150 ease-out rounded-md'}`} href="/auth/account"
											title={`Manage User Settings`}
											to={'/auth/account'}
										>
											{props.sub_status === 'past_due' && (
												<div className='absolute bg-red-500 top-1 right-0 rounded-full p-1'></div>
											)}
											Manage Account
										</Link>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<div className={`p-1 ${active && 'bg-salmon text-white transition duration-150 ease-out rounded-md'}`}
											title={`Logout`}
											onClick={logoutHandler}>
											Logout
										</div>
									)}
								</Menu.Item>
							</div>
						</Menu.Items>
					</Transition>
				</Menu>
			)
		}
	}
}

export default DropDownMenu;