import AuthForm from "../components/forms/AuthForm";
import { userSignup } from "../lib/user-api";
import useHttp from "../hooks/use-http";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Signup = () => {
    const { sendRequest, error, status } = useHttp(userSignup);
    const navigate = useNavigate();

    useEffect(() => {
        if (status === 'completed' && !error) {
            navigate('/auth/signup_success');
        }
    }, [status, navigate, error])

    const signupHandler = (signupData) => {
        sendRequest(signupData);
    };

    return (
        <AuthForm type='signup' onSignup={signupHandler} isLoading={status === 'pending'} />
    );
};

export default Signup;