import { useContext } from "react";
import AuthContext from "../../store/auth-context";
import { textColourChoice } from "../../store/themesConfig";

const Layout = (props) => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme;
    const bgCol = theme.bg;
    const textCol = textColourChoice(bgCol);

    const bgStyle = `z-0 absolute supports-[height:100cqh]:h-[100cqh] supports-[height:100svh]:h-[100svh] inset-0 transition-all`

    return (
        <div style={{'backgroundColor': `${bgCol}`}} className={bgStyle} >
            {/* <div className="h-screen bg-gradient-to-t from-rose-400/40 overflow-hidden"> */}
            <div className={`overflow-hidden h-full`}>
                {props.children}
                <div style={{'color': `${textCol}`}} className="absolute bottom-0 left-2 sm:bottom-2 sm:right-2 font-elight text-[0.7rem] opacity-60">
                    <a className="underline"
                        href="https://app.termly.io/document/terms-of-use-for-saas/61fdfd0a-be00-4b8f-92f0-0bb0cd4a8278"
                        target="_blank" rel="noreferrer">Terms and Conditions</a>  
                     <a className="underline ml-2"
                        href="https://app.termly.io/document/privacy-policy/e5c6c798-7676-4148-a139-85c282231e1b"
                        target="_blank" rel="noreferrer">Privacy
                        Policy</a>
                </div>
                <div style={{'color': `${textCol}`}} className="absolute bottom-0 right-2 sm:bottom-2 sm:right-2 font-elight text-[0.7rem] opacity-60">
                    © Clusta Ltd. 2024
                </div>
            </div>
        </div>
    );
};

export default Layout;