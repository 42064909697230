
// basic wrapper for use with auth and newclusta forms 

import { Transition } from "@headlessui/react";

const FormContainer = (props) => {
    return (
        <Transition
            className={'container relative mx-auto h-3/4 my-14'}
            appear={true}
            show={true}
            enter="transition-opacity duration-700"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 h-full mx-auto relative flex flex-col py-5 shadow-xl border border-salmon/30 rounded-lg bg-white text-base overflow-auto overscroll-contain">
                {props.children}
            </div>
        </Transition>
    );
};

export default FormContainer;