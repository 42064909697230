import Navigation from "../components/layout/Navigation"
import Results from "../components/layout/Results";

import { Fragment, useEffect, useState } from "react";
import { getCollections } from "../lib/api";

import AuthContext from "../store/auth-context";

import useHttp from "../hooks/use-http";
import { useContext } from "react";
import { searchResults } from "../hooks/results-format";
import { useNavigate } from "react-router-dom";

const MySpaces = () => {
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;
    const setTheme = authCtx.updateTheme;

    useEffect(() => {
        setTheme('DF');
    })

    const navigate = useNavigate();

    const [curSearch, setCurSearch] = useState('');

    const { sendRequest, status, data: results, error } = useHttp(
        getCollections,
        true
    );

    useEffect(() => {
        sendRequest(token);
    }, [sendRequest, token]);

    // This randomly stopped working - also used in coll detail, this worked for longer then
    // randomly stopped - once again, have fun figuring this out...
    // useEffect(() => {
    //     if (status === 'completed' && !error) {
    //         authCtx.updateLogoutTimer()
    //     }
    // }, [authCtx, status, error])

    useEffect(() => {
        document.title = "Clusta.";
    })

    let fuseResults = [];

    if (curSearch.length > 0) {
        const newResults = searchResults({
            results: results,
            input: curSearch
        }, 'displayname');
        fuseResults = [...newResults];
    };

    useEffect(() => {
        if (!authCtx.isLoggedIn) {
            navigate('/auth/login');
        }
    })

    // if (status === 'pending') {
    //     return <div></div>
    // }

    // if (error) {
    //     return <div></div>
    // }

    return (
        <Fragment>
            <Navigation
                results={results}
                state='myGroups'
                search={{ state: curSearch, function: setCurSearch }}
            />
            {(status === 'completed' && !error) && (
                <Results
                    type={'mycolls'}
                    results={curSearch.length === 0 ? results : fuseResults}
                    status={status}
                    error={error}
                    search={{ state: curSearch, function: setCurSearch }}
                />
            )}
        </Fragment>
    );
};

export default MySpaces;