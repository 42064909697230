import { Fragment, useContext, useEffect } from "react";
import FormContainer from "../components/forms/FormContainer";

import useHttp from "../hooks/use-http";
import { updateCollectionDetail, deleteCollection } from "../lib/api";
import { useNavigate } from "react-router-dom";
import AuthContext from "../store/auth-context";
import CollSettingsForm from "../components/forms/CollSettingsForm";

const CollectionSettings = (props) => {

    const collection = props.collection;
    const authCtx = useContext(AuthContext);

    const isOwner = authCtx.isLoggedIn && collection.owner.id == authCtx.id;
    const isMember = authCtx.isLoggedIn && collection.members.find(instance => instance.id == authCtx.id);

    const { sendRequest, data, error, status } = useHttp(
        updateCollectionDetail,
        true
    );
    const navigate = useNavigate()

    useEffect(() => {
        if (status === 'completed' && !error) {
            navigate(`/home`);
        }
    }, [status, navigate, data, error])

    const collectionFormHandler = (collData) => {
        sendRequest(collData);
    };

    const onDelete = (handle) => {
        deleteCollection(handle).then(() => {
            navigate(`/home`);
        });
    };

    const onLeave = () => {
        let memberArray = [];
        memberArray.push(authCtx.id);
        updateCollectionDetail({
            handle: props.collection.handle, 
            data: { 
                members: memberArray
            } 
        }).then(() => {
            navigate(`/home`);
        });
    };

    return (
        <Fragment>
            <FormContainer>
                <CollSettingsForm
                    onSubmit={collectionFormHandler}
                    onDelete={onDelete}
                    onLeave={onLeave}
                    collection={collection}
                    context={{ isOwner: isOwner, isMember: isMember }}
                />
            </FormContainer>
        </Fragment>
    );
};

export default CollectionSettings;