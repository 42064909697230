import { Fragment, useCallback, useContext } from "react"

import { ReactComponent as SparkIcon } from "../../icons/circle.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { ReactComponent as PuffIcon } from "../../icons/puff.svg";

import { useRef, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import useHttp from "../../hooks/use-http";
import { newSpark, newSparkImage } from "../../lib/api";
import MemberContext from "../../store/isMember-context";
import CardButtonWrapper from "../buttons/CardButtonWrapper";
import ImageUploadField from "./fields/ImageUploadField";

const NewSparkForm = (props) => {

    const memberCtx = useContext(MemberContext);
    const pubCtx = memberCtx.public;

    // TWO SEPARATE TEXTAREAS FOR HEADER & BODY. BODY REVEALED AND AUTO-FOCUSSED ON 'ENTER', HIDDEN ON 'BACKSPACE' WHEN EMPTY

    const textHeaderRef = useRef();
    const textBodyRef = useRef();
    const sparkLinkRef = useRef();

    const [sparkImage, setSparkImage] = useState(null);
    const [Preview, setPreview] = useState("");

    const [textHeader, setTextHeader] = useState("");
    const [textBody, setTextBody] = useState("");
    const [focussing, setFocussing] = useState('header');
    const [isHidden, setIsHidden] = useState(true);

    const headerChangeHandler = () => { setTextHeader(textHeaderRef.current.value); };
    const bodyChangeHandler = () => { setTextBody(textBodyRef.current.value); };

    const keyPressHandler = (e) => {
        if (e.key === 'Enter') {
            setFocussing('body');
            textBodyRef.current.focus();
        };
        if (e.key === 'Backspace' && !isHidden && textBodyRef.current.value === '') setFocussing('header');
    };

    useEffect(() => {
        if (focussing === 'body') {
            setIsHidden(false);
        };
        if (focussing === 'header') {
            textHeaderRef.current.focus();
            setIsHidden(true);
        };
    }, [focussing, textHeaderRef]);

    // SPARK DATA HANDLING

    const collHandle = props.data.handle;

    const { sendRequest, data, error, status } = useHttp(newSpark);
    const { sendRequest: sendPicRequest, data: picData, error: picError, status: picStatus } = useHttp(newSparkImage);
    const navigate = useNavigate()

    // navigate to new spark once it's created, as long as there is no image submitted
    useEffect(() => {
        if (status === 'completed' && !error && !sparkImage) {
            navigate(`/${collHandle}/spark/${data.id}`);
        }
    }, [status, navigate, data, error, collHandle, sparkImage])

    // if image also created successfully navigate to new spark
    useEffect(() => {
        if (picStatus === 'completed' && !picError) {
            navigate(`/${collHandle}/spark/${data.id}`);
        }
    }, [picStatus, picError, navigate, data, collHandle])

    const collectionFormHandler = (sparkData) => {
        sendRequest(sparkData);
    }

    const newImageHandler = useCallback(() => {
        let formData = new FormData()
        formData.append('image', sparkImage)
        sendPicRequest({
            spark: data.id,
            data: formData,
        })
    }, [sparkImage, sendPicRequest, data])

    const newSparkHandler = (e) => {
        e.preventDefault();

        const text = textHeader.length > 0 ? textHeader.trimStart().trimEnd() + `\n` + textBody.trimStart().trimEnd() : textBody.trimStart().trimEnd();
        const link = sparkLinkRef.current.value;

        if (text.length === 0) return;

        collectionFormHandler({
            data: {
                text: text,
                link: link,
                pinned: true,
                header: textHeader.length > 0 ? true : false,
                public: pubCtx.state ? true : false,
            },
            handle: collHandle
        })
    }

    // DON'T navigate if spark created but image is submitted, instead submit image separately
    useEffect(() => {
        if (status === 'completed' && !error && sparkImage) {
            newImageHandler()
        }
    }, [status, error, sparkImage, newImageHandler])

    const txtAreaStyle = "w-full z-10 h-full absolute top-0 left-0 right-0 bottom-0 resize-none overflow-hidden outline-none bg-transparent "
    const contentStyle = {
        header: txtAreaStyle + "font-light text-lg md:text-xl leading-snug",
        body: txtAreaStyle + "font-elight text-sm md:text-base leading-snug"
    };

    return (
        <div className="relative w-full h-full flex place-content-center">
            {(Preview) && (
                <div className="absolute top-0 left-0 bottom-0 right-0 overflow-hidden opacity-25 rounded-md flex place-content-center">
                    <img className="object-cover rounded-md w-full h-full" src={Preview ? Preview : ""}></img>
                    <img className="-z-10 absolute w-full h-full top-0 left-0 object-cover rounded-md blur-md" src={Preview ? Preview : ""}></img>
                </div>
            )}
            <div className="absolute top-1 left-1 gap-1 flex flex-row">
                <SparkIcon className="h-5 w-5 opacity-60" />
                <ImageUploadField Preview={Preview} setSparkImage={setSparkImage} setPreview={setPreview} />
            </div>
            <div className="mt-9 mb-9 relative flex flex-col w-full overflow-auto">
                <div className="relative mx-2 md:mx-3">
                    <textarea
                        className={contentStyle.header}
                        placeholder="Type your spark here..."
                        maxLength="500"
                        ref={textHeaderRef}
                        onChange={headerChangeHandler}
                        onKeyDown={(e) => keyPressHandler(e)}
                        autoFocus
                    />
                    <div className="opacity-0 pb-1 font-light text-lg md:text-xl leading-snug whitespace-pre-line">{textHeader.length === 0 ? 'Type your spark here...' : textHeader}</div>
                </div>
                <div className="relative mx-2 md:mx-3">
                    <textarea
                        className={contentStyle.body}
                        placeholder="Add more detail here..."
                        maxLength="500"
                        ref={textBodyRef}
                        onChange={bodyChangeHandler}
                        onKeyDown={(e) => keyPressHandler(e)}
                        value={textBody.trimStart()}
                        autoFocus
                    />
                    <div className="opacity-0 pb-1 font-elight text-sm md:text-base leading-snug whitespace-pre-line">{textBody.length === 0 ? 'Add more detail here...' : textBody}</div>
                </div>
            </div>
            <div className="absolute bottom-2 w-full">
                <input
                    className="ml-2 md:ml-4 w-7/12 md:w-9/12 pt-2 outline-none bg-transparent font-elight text-[0.7rem] md:text-base leading-snug overflow-auto overscroll-contain"
                    placeholder="Attach a link..."
                    ref={sparkLinkRef}
                />
            </div>
            {(status !== 'pending') && (
                <Fragment>
                    <div className="absolute flex flex-row bottom-1 right-1 left-50% space-x-1">
                        <CardButtonWrapper type={'submit'} hoverTitle={`Create Spark`} clickAction={newSparkHandler}>
                            <CheckIcon className="h-5 w-5 md:w-9" />
                        </CardButtonWrapper>
                    </div>
                </Fragment>
            )}
            {(status === 'pending') && (
                <div className="absolute flex flex-row bottom-1 right-1 left-50% space-x-1">
                    <CardButtonWrapper type={'pending'} hoverTitle={`Loading`}>
                        <PuffIcon className="h-5 w-5 md:w-9 opacity-0" />
                    </CardButtonWrapper>
                </div>
            )}
            {(picStatus === 'pending') && (
                <div className="absolute flex flex-row bottom-1 right-1 left-50% space-x-1">
                    <CardButtonWrapper type={'pending'} hoverTitle={`Loading`}>
                        <PuffIcon className="h-5 w-5 md:w-9" />
                    </CardButtonWrapper>
                </div>
            )}
        </div>
    );
}

export default NewSparkForm;