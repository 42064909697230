import { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { textColourChoice } from "../../store/themesConfig";

const CentreAddWrapper = (props) => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme;
    const themePrimary = theme.highlight;
    const themeCard = theme.card
    const textCol = textColourChoice(themeCard);

    const [textColour, setTextColour] = useState('');
    const [bgColour, setBgColour] = useState('')

	useEffect(() => {
        const textWithPrimary = textColourChoice(themePrimary);
        const initText = props.type === 'disconnect' ? textWithPrimary : textCol;
        const initBg = props.type === 'disconnect' ? themePrimary : themeCard;
		setTextColour(initText);
        setBgColour(initBg);
	}, [textCol, themeCard, setBgColour, setTextColour])

	const hoverTextCol = () => {
        if (props.type !== 'add') {
            props.hoverOverFunction();
        }
        const textWithPrimary = textColourChoice(themePrimary);
        const newText = props.type === 'disconnect' ? textCol : textWithPrimary;
        const newBg = props.type === 'disconnect' ? themeCard : themePrimary;
		setTextColour(newText);
        setBgColour(newBg);
		return;
	}

	const defTextCol = () => {
        if (props.type !== 'add') {
            props.hoverOutFunction();
        }
		const textWithPrimary = textColourChoice(themePrimary);
        const newText = props.type === 'disconnect' ? textWithPrimary : textCol;
        const newBg = props.type === 'disconnect' ? themePrimary : themeCard;
		setTextColour(newText);
        setBgColour(newBg);
		return;
	}

    if (props.type === 'add') {
        return (
            <div
                onMouseOver={hoverTextCol}
                onMouseLeave={defTextCol}
                style={{ 'stroke': `${textColour}`, 'backgroundColor': `${bgColour}`, 'borderColor': `${themePrimary}` }}
                title={props.hoverTitle}
                onClick={props.clickAction}
                className="z-20 rounded-full cursor-pointer border-2 transition duration-150 ease-out">
                {props.children}
            </div>
        )
    }

    if (props.type === 'connect') {
        return (
            <div
                onMouseOver={hoverTextCol}
                onMouseLeave={defTextCol}
                style={{ 'color': `${textColour}`, 'stroke': `${textColour}`, 'fill': `${textColour}`, 'backgroundColor': `${bgColour}`, 'borderColor': `${themePrimary}` }}
                title={props.hoverTitle}
                onClick={props.clickAction}
                className="border-2 rounded-full p-2 cursor-pointer flex flex-row">
                {props.children}
            </div>
        )
    }

    if (props.type === 'disconnect') {
        return (
            <div
                onMouseOver={hoverTextCol}
                onMouseLeave={defTextCol}
                style={{ 'color': `${textColour}`, 'stroke': `${textColour}`, 'fill': `${textColour}`, 'backgroundColor': `${bgColour}`, 'borderColor': `${themePrimary}` }}
                title={props.hoverTitle}
                onClick={props.clickAction}
                className="border-2 rounded-full p-2 cursor-pointer flex flex-row">
                {props.children}
            </div>
        )
    }


}

export default CentreAddWrapper;