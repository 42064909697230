import CollectionContent from "../card-content/CollectionContent";
import { useContext, useState } from "react";
import AuthContext from "../../store/auth-context";
import CentreCardWrapper from "./CentreCardWrapper";

const CentreCollectionCard = (props) => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme
    const cardBg = theme.bg;
    const centreHighlight = theme.highlight;
    const [isHovering, setIsHovering] = useState(false);

    const hoverLeave = () => {
        setIsHovering(false);
    }

    const hoverEnter = () => {
        setIsHovering(true);
    }

    return (
        <CentreCardWrapper bg={cardBg} highlight={centreHighlight} hoverEnter={hoverEnter} hoverLeave={hoverLeave}>
            <CollectionContent
                    isHovering={isHovering}
                    data={props.data}
                    isCentre={true} />
        </CentreCardWrapper>
    );
}

export default CentreCollectionCard;