import React, { useState } from "react";

const MemberContext = React.createContext({
    state: undefined,
    function: () => { },
});

export const MemberContextProvider = (props) => {
    const [isMember, setIsMember] = useState(undefined);
    const [isPublic, setIsPublic] = useState(false);
    const [isPres, setIsPres] = useState(false);

    const memberValue = {
        state: isMember,
        function: setIsMember,
        public: {
            state: isPublic,
            function: setIsPublic
        },
        pres: {
            state: isPres,
            function: setIsPres
        }
    };

    return (
        <MemberContext.Provider value={memberValue}>
            {props.children}
        </MemberContext.Provider>
    );
}

export default MemberContext;