import { useContext, useState } from "react"
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Confirmation from "../components/confirmation/Confirmation"
import useHttp from "../hooks/use-http";
import { userActivate } from "../lib/user-api";
import { Fragment } from "react";
import Navigation from "../components/layout/Navigation";
import FormContainer from "../components/forms/FormContainer";
import AuthContext from "../store/auth-context";


const ConfirmActivation = () => {
    const authCtx = useContext(AuthContext);
    const setTheme = authCtx.updateTheme;

    useEffect(() => {
        setTheme('DF');
    })

    const params = useParams();

    const uid = params.uid;
    const token = params.token;

    const [isLoading, setIsLoading] = useState(true);

    const {
        sendRequest,
        error,
        status
    } = useHttp(userActivate, true);

    useEffect(() => {
        sendRequest({
            uid: uid,
            token: token
        });
    }, [sendRequest, uid, token])

    useEffect(() => {
        if (status === 'completed' && !error) {
            setIsLoading(false);
        }
    }, [status, error, setIsLoading])

    return (
        <Fragment>
            <Navigation state='myGroups' />
            <FormContainer>
                <Confirmation
                    title={'Account Activated!'}
                    msg={'Login and create your first sparks.'}
                    isLoading={isLoading}
                    error={error}
                    isActivation={true}
                />
            </FormContainer>
        </Fragment>
    )
}

export default ConfirmActivation;