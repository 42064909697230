import { Fragment, useContext, useEffect } from "react"
import { Link, useParams } from "react-router-dom";
import AuthContext from "../../store/auth-context";

const PaymentConfirmation = () => {

    const authCtx = useContext(AuthContext);

    const params = useParams();
    const isSuccess = params.completed;

    useEffect(() => {
        if (isSuccess === 'success') {
            authCtx.updateSub({
                sub_type: 'SOL',
                sub_status: 'active'
            });
            localStorage.setItem('sub_type', 'SOL');
        }
    })

    return (
        <Fragment>
            <div className='w-full h-full flex flex-col px-4 sm:px-6 pt-10'>
                <div className="ml-2 py-4 text-darkgrey text-4xl font-light">Success!</div>
                <br />
                <div className="ml-2 mb-4 text-darkgrey font-elight text-lg">
                    We've received your payment successfully.<br/>
                    <br/>
                    Keep in touch, we'd love to hear from you - reach out at <span className="font-semibold">hello@clusta.live</span> or find our socials on <a className="underline font-semibold" target='_blank' href="https://clusta.live">our website</a>
                </div>
                <Link
                    to={'/home'}
                    className="mt-5 mb-3 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey hover:text-white border border-darkgrey/50 hover:border-salmon hover:scale-105 hover:bg-salmon transition ease-in-out"
                >
                    Return home
                </Link>
                <div className="w-full">.</div>
            </div>
        </Fragment>
    );
}

export default PaymentConfirmation;