import Results from "../components/layout/Results"

import { useEffect, useContext } from "react";
import { getSparkDetail, getFilteredSparks } from "../lib/api";

import AuthContext from "../store/auth-context";

import useHttp from "../hooks/use-http";
import { useParams } from "react-router-dom";
import { useState } from "react";
import SearchContext from "../store/sparkSearch-context";
import MemberContext from "../store/isMember-context";


const SparkDetail = (props) => {

    const memberCtx = useContext(MemberContext);
    const pubCtx = memberCtx.public;

    // ACCESS SPARK ID FROM CLIENT-SIDE URL

    const params = useParams();
    const sparkId = params.sparkId;

    // ACCESS THE HANDLE OF PARENT COLLECTION OF SPARKS 

    const handle = props.collection.handle;

    // ACCESS USER TOKEN FROM AUTH CONTEXT

    const authCtx = useContext(AuthContext);
    const token = authCtx.token;

    // TOGGLE BETWEEN DISPLAYING CONNECTED SPARKS, AND DISPLAYING NEW CON SPARK FORM

    const [addConSpark, setAddConSpark] = useState(false);

    const addConSparkHandler = () => {
        setAddConSpark((prevState) => prevState ? false : true);
    };

    // This function is called when surSpark is selected to be centred as part of reset to original state of this component

    const removeConSparkState = () => {
        setAddConSpark(false);
    };

    // SEARCH BAR STATE

    const searchCtx = useContext(SearchContext);
    const search = searchCtx;
    const curSearchInput = search.state;

    // CURRENT INPUT OF TEXT FIELD ON NEW CON SPARK FORM

    const [curInput, setCurInput] = useState('');

    // MANAGE API CALL TO FETCH SPARK DETAIL DATA

    const { sendRequest, status, data: splitResults, error } = useHttp(
        getSparkDetail,
        true
    );

    // DEFINING THE CENTRE SPARK AND ARRAY OF CONNECTED SPARKS

    let centre;

    if (status === 'completed' && !error) {
        centre = splitResults.centre;
    }

    // Place connected sparks into a state 

    const [connectedResults, setConnectedResults] = useState([]);

    useEffect(() => {
        if (status === 'completed' && !error) {
            if (pubCtx.state) {
                setConnectedResults(splitResults.connected.filter((spark) => spark.public === true));
            } else {
                setConnectedResults(splitResults.connected);
            }
        }
    }, [status, error, setConnectedResults, splitResults, pubCtx])


    // MANAGE API CALL TO GET SPARKS IN COLLECTION FILTERED BY CON SPARK FORM TEXT INPUT

    const { sendRequest: filterSendRequest, status: filterStatus, data: filterSplitResults, error: filterError } = useHttp(
        getFilteredSparks,
        true
    );

    // FUNCTION WHICH MANAGES RECEIVING NEW INPUT IN CON SPARK FROM TEXT FIELD
    // UPDATES THE STATE AND SENDS API FILTER CALL WITH NEW VALUE

    const updateCurInput = (input) => {
        setCurInput(input);
    }

    useEffect(() => {
        if (curInput.length > 0) {
            const handleUpdateResults = setTimeout(() => {
                filterSendRequest({ handle: handle, input: curInput, centre: centre });
            }, 500);

            return () => {
                clearTimeout(handleUpdateResults);
            };
        }
    }, [curInput, handle, centre, filterSendRequest])

    // RESET THE INPUT OF CON SPARK FORM TEXT FIELD

    const resetCurInput = () => {
        setCurInput('');
    }

    // OBJECT TO BE PASSED TO SPARK CONTENT TO MANAGE THE HANDLING OF CONNECTING SPARKS

    const conSparkState = {
        state: addConSpark,
        removeConState: removeConSparkState,
        input: curInput,
        updateInput: updateCurInput,
        resetInput: resetCurInput,
        handler: addConSparkHandler,
        updateFunction: sendRequest,
        updateFiltered: filterSendRequest,
    };


    // FETCHING SPARK DETAIL DATA FROM API ON COMPONENT MOUNT

    useEffect(() => {
        sendRequest(sparkId, token);
    }, [sendRequest, sparkId, token]);
    

    if (status === 'pending') {
        return (<div></div>)
    }

    if (status === 'completed' && !error) {
        return (
            <Results
                type="spark"
                filterStatus={filterStatus}
                filteredSparks={filterSplitResults ? filterSplitResults : []}
                conSparkState={conSparkState}
                collHandle={handle}
                centre={centre}
                results={connectedResults}
                status={status}
                error={error}
                onResultsUpdate={{ state: connectedResults, function: setConnectedResults }}
            />
        )
    }

    if (error) {
        return (
            <div></div>
        )
    }
}

export default SparkDetail;