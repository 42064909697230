import { ReactComponent as Search } from '../../icons/search.svg'
import { ReactComponent as InfinityLogo } from '../../icons/ClustaInfinity2.svg'
import { ReactComponent as InfinitySymbol } from '../../icons/InfinitySymbol.svg'
import { ReactComponent as BellIcon } from "../../icons/bell.svg";

import { Fragment, useContext, useEffect, useState } from 'react';
import AuthContext from '../../store/auth-context';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import SearchContext from '../../store/sparkSearch-context'
import MemberContext from '../../store/isMember-context'
import DropDownMenu from './DropDownMenu'
import useHttp from '../../hooks/use-http';
import { getSubStatus } from '../../lib/user-api';
import { textColourChoice } from '../../store/themesConfig';
import NotificationsModal from '../notifications/NotificationsModal';
import NotificationBell from '../notifications/NotificationBell';
import { getAuth, onAuthStateChanged } from "firebase/auth";


const Navigation = (props) => {
    const authCtx = useContext(AuthContext);
    const searchCtx = useContext(SearchContext);
    const memberCtx = useContext(MemberContext);

    const [firebaseAuth, setFirebaseAuth] = useState(null);

    const auth = getAuth();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                // const uid = user.uid;
                setFirebaseAuth(user);
                // ...
            } else {
                // User is signed out
                // ...
                setFirebaseAuth(null);
            }
        });
    }, [auth, setFirebaseAuth])

    const notifications = authCtx.notifications;
    const [showNotifications, setShowNotifications] = useState(false);

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    const navigate = useNavigate()
    let location = useLocation();
    const locationParam = location.pathname;

    // this block (sendreq and 2 useEffects) gets user sub type and sub status, so we stay synced with server
    // and stripe on the current status of a user's subscription
    // and we can use this to notify them of missed payments from nav and in user settings
    const { sendRequest, data, error: subError, status } = useHttp(getSubStatus)

    useEffect(() => {
        if (authCtx.isLoggedIn) {
            sendRequest();
        }
    }, [sendRequest, authCtx.isLoggedIn])

    useEffect(() => {
        if (status === 'completed' && !subError) {
            authCtx.updateSub({
                sub_type: data.sub_type,
                sub_status: data.sub_status
            })
            // console.log(data);
        }
    }, [status, subError, data, authCtx])

    const [upgradeHover, setUpgradeHover] = useState(false);

    const upgradeHoverHandler = () => {
        setUpgradeHover(!upgradeHover);
    }

    const curTheme = authCtx.theme;
    const themeBg = curTheme.bg;
    const themePrimary = curTheme.highlight;
    const [buttonTextCol, setButtonTextCol] = useState('');
    const [textCol, setTextCol] = useState('')

    useEffect(() => {
        const newTextCol = textColourChoice(themeBg)
        const newButtonTextCol = textColourChoice(themePrimary);
        setTextCol(newTextCol);
        setButtonTextCol(newButtonTextCol);
    }, [setTextCol, setButtonTextCol, themeBg, themePrimary])

    const homeHandler = () => {
        authCtx.isLoggedIn ? navigate('/home') : navigate('/auth/login');
    };

    const isOwner = props.state === 'groupDetail' ? toString(authCtx.id) === toString(props.collection.owner.id) : false;

    const search = props.search ? props.search : {};
    const curSearch = search.state ? search.state : '';
    const searchUpdate = search.function ? search.function : () => { };

    const updateSearch = (e) => {
        searchUpdate(e.target.value);
    };

    const collSearch = searchCtx;

    const updateSearchInput = (e) => {
        const value = e.target.value;
        collSearch.function(value);
    };

    const onCollTitleClick = () => {
        collSearch.function('');
        navigate(`/${props.collection.handle}`);
    };

    const parentStyle = "p-1 flex flex-row items-center whitespace-nowrap overflow-hidden cursor-pointer relative";
    const collTitleStyle = "flex text-center leading-7 text-lg sm:text-2xl font-light outline-none h-full px-1 sm:px-2 bg-transparent cursor-pointer";
    const searchBarStyle = `text-xl font-elight self-center outline-none w-full h-full ml-1 px-1 sm:px-2 bg-transparent`;

    return (
        <Fragment>
            {(firebaseAuth && showNotifications) && (
                <NotificationsModal isOpen={showNotifications} toggle={toggleNotifications} values={notifications.value} loading={notifications.loading} error={notifications.error} />
            )}
            <div style={{ 'color': `${textCol}`, 'borderColor': `${textCol}` }} className="flex flex-row my-6 h-2 px-1 w-full items-center z-50 ">

                <div className={`${props.state === 'groupDetail' ? 'w-1/3' : 'w-1/2'} flex flex-row`}>
                    {(!authCtx.isLoggedIn || authCtx.sub_type === 'LIT') && (
                        <div style={{ 'color': `${themePrimary}` }} onClick={homeHandler} className='ml-2 font-semibold text-4xl cursor-pointer'>Clusta.</div>
                    )}
                    {(authCtx.isLoggedIn && authCtx.sub_type !== 'LIT') && (
                        <div onClick={homeHandler} className='ml-2 py-2 pr-3 cursor-pointer w-36'>
                            <InfinityLogo style={{ 'color': `${themePrimary}` }} className="w-full h-full" />
                        </div>
                    )}
                </div>

                {props.state === 'groupDetail' && (
                    <div className='flex flex-row w-1/3 place-content-center self-center'>
                        <div
                            onClick={onCollTitleClick}
                            to={`/${props.collection.handle}`}
                            className={collTitleStyle}
                        >
                            {props.collection.displayname}
                        </div>
                        {memberCtx.state && (
                            <DropDownMenu
                                id='collection-menu'
                                isOwner={isOwner}
                                handleName={props.collection.handle}
                                textCol={textCol}
                            />)}
                    </div>
                )}

                <div className={`${props.state === 'groupDetail' ? 'w-1/3' : 'w-1/2'} place-content-end`}>
                    {authCtx.isLoggedIn && (
                        <div className='flex flex-row content-center justify-end'>

                            {props.state === 'myGroups' && (
                                <Fragment>
                                    {((locationParam !== '/auth/upgrade') && (authCtx.sub_type === 'LIT') && (props.results && props.results.length !== 0)) && (
                                        <Link to={`/auth/upgrade`} onMouseEnter={upgradeHoverHandler} onMouseLeave={upgradeHoverHandler} className="h-full w-24 sm:mr-2 flex flex-row place-content-center cursor-pointer px-2 py-1 text-base sm:text-lg text-white rounded-full font-semibold bg-salmon">
                                            {!upgradeHover && (
                                                <div className='font-light'>Upgrade</div>
                                            )}
                                            {upgradeHover && (
                                                <Fragment>
                                                    <div>Clusta</div>
                                                    <InfinitySymbol className='w-4 h-4 fill-white' />
                                                </Fragment>
                                            )}
                                        </Link>
                                    )}
                                    <div style={{ 'borderColor': `${textCol}` }} className={`mr-1 hidden sm:flex flex-row ${props.state === 'groupDetail' ? 'w-2/3' : 'w-1/2'} self-center border rounded-full h-2/3`}>
                                        <input
                                            className={searchBarStyle}
                                            type="text"
                                            placeholder="Find a collection..."
                                            value={curSearch}
                                            onChange={updateSearch}
                                        />
                                        <Search style={{ 'stroke': `${textCol}` }} className="h-8 w-8 self-center jusitfy-end cursor-pointerive mx-2" />
                                    </div>
                                </Fragment>
                            )}

                            {props.state === 'groupDetail' && (
                                <div style={{ 'borderColor': `${textCol}` }} className='mr-1 hidden sm:flex flex-row w-2/3 self-center border rounded-full'>
                                    <input
                                        className={searchBarStyle}
                                        type="text"
                                        placeholder="Find a spark..."
                                        value={search.state}
                                        onChange={updateSearchInput}
                                    />
                                    <Search style={{ 'stroke': `${textCol}` }} className="h-8 w-8 self-center jusitfy-end cursor-pointerive mx-2" />
                                </div>
                            )}

                            {(firebaseAuth) && (
                                <div onClick={toggleNotifications} className='p-1 relative cursor-pointer'>
                                    <NotificationBell themePrimary={themePrimary} textCol={textCol} values={notifications.value} />
                                </div>
                            )}
                            {(!firebaseAuth) && (
                                <div className='p-1 relative cursor-pointer opacity-60 animate-pulse'>
                                    <BellIcon style={{ 'stroke': `${textCol}` }} className='h-8 w-8 stroke-1 relative' />
                                </div>
                            )}

                            <DropDownMenu
                                id='account-manager' sub_status={authCtx.sub_status} textCol={textCol}>
                            </DropDownMenu>

                        </div>
                    )}
                    {!authCtx.isLoggedIn && (
                        <div className='flex flex-row justify-end'>
                            <Link
                                title={`Login`}
                                to={`/auth/login?url=${locationParam}`}
                                className={parentStyle}
                            >
                                <div style={{ 'backgroundColor': `${themePrimary}`, 'color': `${buttonTextCol}` }} className='py-1 px-3 font-light hover:scale-105 transition duration-150 ease-in-out rounded-full'>login</div>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </Fragment >
    );
}

export default Navigation;