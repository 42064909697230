import { useContext, useRef, useCallback, useEffect, useState, Fragment } from 'react';
import { collection, query, where, orderBy, startAfter, limit, getDocs } from 'firebase/firestore';
import { firebaseDb } from "../../firebase";
import { ReactComponent as PuffIcon } from "../../icons/puff.svg";
import { ReactComponent as CrossIcon } from "../../icons/x.svg";
import AuthContext from '../../store/auth-context';
import useClickOutside from '../../hooks/useClickOutside';
import { Transition } from '@headlessui/react'
import Notification from './Notification';

const LIMIT = 15;

const NotificationsModal = (props) => {
    const values = props.values;
    const loading = props.loading;
    const error = props.error;

    const authCtx = useContext(AuthContext);
    const userId = authCtx.id;

    const toggleModal = () => {
        props.toggle();
    }

    const [posts, setPosts] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const [postsEnd, setPostsEnd] = useState(false);

    const getMorePosts = async () => {
        setLoadingMore(true);
        const last = posts[posts.length - 1];

        let cursor = last.created_at;
        cursor = cursor.toString();

        const morePostsQuery = query(collection(firebaseDb, "notifications"),
            where("recipients", 'array-contains', userId),
            orderBy('__name__', 'desc'),
            startAfter(cursor),
            limit(LIMIT));

        const newPosts = (await getDocs(morePostsQuery)).docs.map((doc) => doc.data());

        setPosts([...posts, ...newPosts]);
        setLoadingMore(false);

        if (newPosts.length < LIMIT) {
            setPostsEnd(true);
        }
    };

    // Custom hook package to call useful values on firestore query
    // const [values, loading, error] = useCollection(
    //     query(collection(firebaseDb, "notifications"), where("recipients", 'array-contains', userId), orderBy('__name__', 'desc'), limit(LIMIT))
    // );

    useEffect(() => {
        if (values) {
            setPosts(values.docs.map((doc) => doc.data()));
            setPostsEnd(values.docs.length < LIMIT)
        }
    }, [values, setPosts])

    // This config lets user click anywhere outside of modal to close modal
    const notificationPopover = useRef();
    const close = useCallback(() => toggleModal(), []);
    useClickOutside(notificationPopover, close);

    return (
        <Transition
            ref={notificationPopover}
            className={'z-50 fixed top-0 right-0 bottom-0 w-full max-w-md backdrop-blur-sm bg-white/90 p-3 overflow-scroll'}
            appear={true}
            show={true}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
        >
            <div className='flex justify-between mb-2'>
                <div className='font-elight text-2xl'>Notifications</div>
                <div onClick={toggleModal} className='opacity-50 hover:opacity-100 transition-all'>
                    <CrossIcon className='h-8 w-8' />
                </div>
            </div>
            {(loading) && (
                <div className='absolute top-0 left-0 grid place-content-center w-full h-full'>
                    <PuffIcon className='w-full stroke-black' />
                </div>
            )}
            {(error) && (
                <div className='absolute top-10 bottom-10 left-0 grid place-content-center w-full'>
                    <div className='font-light'>{error.message}</div>
                </div>
            )}
            {posts && (
                <Fragment>
                    {(posts.length === 0 || !posts) && (
                        <div className='absolute top-10 bottom-10 left-0 grid place-content-center w-full'>
                            <div className='font-light text-2xl text-center'>No notifications yet...</div>
                            <div className='font-elight text-lg text-center'>Check back soon!</div>
                        </div>
                    )}
                    <div className={'flex flex-col gap-2'}>
                        {posts.map((doc, i) => (
                            <Notification data={doc} key={i} toggleModal={toggleModal} />
                        ))}
                        {/* put a 200 limit on sparks returned for now, just for doc read load, but can increase whenever */}
                        {(!postsEnd && !loading && !loadingMore && posts.length < 200) && (
                            <div className='flex place-content-center'>
                                <div onClick={getMorePosts} className='font-light text-white bg-salmon rounded-md p-2 mt-1 hover:scale-105 cursor-default transition-all ease-in-out'>Load more</div>
                            </div>
                        )}
                    </div>
                </Fragment>
            )}
            {(loadingMore) && (
                <div className='flex place-content-center mt-2'>
                    <PuffIcon className='w-full stroke-black' />
                </div>
            )}
        </Transition>
    )
}

export default NotificationsModal;