import React, { useCallback, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";

import useClickOutside from "../../hooks/useClickOutside";

export const PopoverPicker = ({ color, onChange }) => {
    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    return (
        <div className="relative">
            <div
                className="w-10 h-full cursor-pointer rounded-l-sm"
                style={{ 'background': `#${color}` }}
                onClick={() => toggle(true)}
            />

            {isOpen && (
                <div className="absolute z-10" ref={popover}>
                    <HexColorPicker color={color} onChange={onChange} />
                </div>
            )}
        </div>
    );
};