import React, { useState } from "react";

const SearchContext = React.createContext({
    state: '',
    function: () => { },
});

export const SearchContextProvider = (props) => {
    const [entry, setEntry] = useState('');

    const searchValue = {
        state: entry,
        function: setEntry
    };

    return (
        <SearchContext.Provider value={searchValue}>
            {props.children}
        </SearchContext.Provider>
    )
}

export default SearchContext;