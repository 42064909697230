import { Fragment } from "react"
import Confirmation from "../components/confirmation/Confirmation"
import RequestEmailForm from "../components/forms/RequestEmailForm"
import useHttp from "../hooks/use-http"
import { resendActivation } from "../lib/user-api"


const ResendActivateEmail = () => {

    const {
        sendRequest,
        error,
        status
    } = useHttp(resendActivation);

    const resendActivateRequest = (email) => {
        sendRequest({
            email: email
        });
    }

    if (error) {
        return <div className="text-darkgrey font-elight text-lg">Something went wrong</div>
    }

    return (
        <Fragment>
            {status !== 'completed' && (
                <RequestEmailForm
                    title={'Resend Activation Email'}
                    buttonMsg={'Resend Email'}
                    onSubmit={resendActivateRequest}
                />
            )}
            {status === 'completed' && (
                <Confirmation
                    title={'Activation Email Sent'}
                    msg={'Follow the steps provided in the email we sent you to activate your account.'}
                    isLoading={false}
                    error={false}
                    isActivation={false}
                />
            )}
        </Fragment>
    )
}

export default ResendActivateEmail;