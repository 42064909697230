
const API_DOMAIN = process.env.REACT_APP_API_URL;

// I think that we should formate signup to accept PATCH method for profile edits 

// I also think newCollection and getCollections can be refactored into a single
// function which also accepts PATCH for collection update 

// both can be achieved by passing a 'method' parameter into the function
// I've kept them split up for now so that I can test more specifically
// but should look to refactor soon


// create new collection - requires handle, displayname, theme, owner (passed in with token from authCtx)

// export async function collectionManager(collectionData) {
//     const response = await fetch(`${API_DOMAIN}/collections/`, {
//         method: 'POST',
//         body: JSON.stringify(collectionData),
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Token ` + localStorage.getItem('token'),
//         },
//     });
//     const data = await response.json();

//     if (!response.ok) {
//         throw new Error('Could not create collection.');
//     }

//     return data;
// }


// Create a new collection

export async function createCollection(collectionData) {
    const response = await fetch(`${API_DOMAIN}/createcollection/`, {
        method: 'POST',
        body: JSON.stringify(collectionData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not create collection.');
    }

    return data;
}



export async function collectionDetail(handle) {
    const uToken = localStorage.getItem('token');
    const response = await fetch(`${API_DOMAIN}/collection/${handle}/`, {
        method: 'GET',
        headers: {
            // 'Content-Type': 'application/json',
            'Authorization': uToken ? `Token ` + localStorage.getItem('token') : '',
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not fetch collection.');
    }

    return data;
}


// Use this function when updating collection - serializes collection without nested objects, just primary keys

export async function updateCollectionDetail(updateData) {
    const response = await fetch(`${API_DOMAIN}/collection/${updateData.handle}/`, {
        method: 'PATCH',
        body: JSON.stringify(updateData.data),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not update collection.');
    }

    return data;
}


export async function deleteCollection(handle) {
    const response = await fetch(`${API_DOMAIN}/collection/${handle}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });

    if (!response.ok) {
        throw new Error('Could not update collection.');
    }

    return null;
}


// get collections that user is owner or member of

export async function getCollections() {
    const response = await fetch(`${API_DOMAIN}/mycollections/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not create collection.');
    }

    return data;
}


// Add and remove members from a collection - specify 'action' in requestData of either 'add' or 'remove'

export async function manageCollMembers(requestData) {
    const response = await fetch(`${API_DOMAIN}/user/${requestData.email}/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not fetch user.');
    }

    let memberArray = [];

    memberArray.push(data.id);

    const memberData = { members: memberArray };

    const memberResponse = await fetch(`${API_DOMAIN}/collection/${requestData.handle}/`, {
        method: 'PATCH',
        body: JSON.stringify(memberData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const collData = await memberResponse.json();

    if (!memberResponse.ok) {
        throw new Error('Could not update collection members.');
    }

    return {
        collection: collData,
        user: data,
    }
}


// create new spark - requires collection handle and user token
// sparkData requires text only, but also accepts link, pinned(bool), public(bool)
// if new spark is connected, also needs to have id from sparkDetail page passed into the JSON object in 'connected' field

export async function newSpark(sparkData) {
    const response = await fetch(`${API_DOMAIN}/newspark/${sparkData.handle}/`, {
        method: 'POST',
        body: JSON.stringify(sparkData.data),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not create spark.');
    }

    return data;
}


// No one was having it so having to post spark image separately - enjoy

export async function newSparkImage(sparkImageData) {
    const response = await fetch(`${API_DOMAIN}/create-image/${sparkImageData.spark}/`, {
        method: 'POST',
        body: sparkImageData.data,
        headers: {
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        console.log(response.data);
        throw new Error('Could not create spark image.');
    }

    return data;
}


// update spark image

export async function updateSparkImage(sparkImageData) {
    const response = await fetch(`${API_DOMAIN}/update-image/${sparkImageData.id}/`, {
        method: sparkImageData.method,
        body: sparkImageData.data,
        headers: {
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });

    if (!response.ok) {
        throw new Error('Could not create spark image.');
    }

    if (sparkImageData.method === 'DELETE') {
        return
    }

    const data = await response.json();

    return data;
}


// newConnectedSpark POSTs a new spark object, then uses returned data id to create
// new connected object between new spark and existing spark (id attached to sparkData)

// sparkData includes: handle (collection handle), data (new spark POST data), connector (spark to connect to new spark)

export async function newConnectedSpark(sparkData) {
    const response = await fetch(`${API_DOMAIN}/newspark/${sparkData.handle}/`, {
        method: 'POST',
        body: JSON.stringify(sparkData.data),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const newSparkData = await response.json();

    if (!response.ok) {
        throw new Error('Could not create spark.');
    }

    const connectBody = {
        spark1: newSparkData.id,
        spark2: sparkData.connector,
    };

    const connectResponse = await fetch(`${API_DOMAIN}/connect/`, {
        method: 'POST',
        body: JSON.stringify(connectBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const connectData = await connectResponse.json();

    if (!connectResponse.ok) {
        throw new Error('Could not connect sparks.');
    }

    const data = {
        newSpark: newSparkData,
        connection: connectData,
    }

    return data;
}


// Update a spark - used for pin, public, text, url

export async function updateSpark(sparkData) {
    const response = await fetch(`${API_DOMAIN}/sparkdetail/${sparkData.id}/`, {
        method: 'PATCH',
        body: JSON.stringify(sparkData.data),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not update spark.');
    }

    return data;
}


// Delete a spark 

export async function deleteSpark(sparkData) {
    const response = await fetch(`${API_DOMAIN}/sparkdetail/${sparkData}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });

    if (!response.ok) {
        throw new Error('Could not delete spark.');
    }

    return null;
}


// get pinned sparks of a specific group - requires collection handle and user token

export async function getPinnedSparks(handle) {
    const uToken = localStorage.getItem('token');
    const response = await fetch(`${API_DOMAIN}/pinnedsparks/${handle}/`, {
        method: 'GET',
        headers: {
            'Authorization': uToken ? ` Token ` + localStorage.getItem('token') : '',
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not fetch pinned sparks.');
    }

    return data;
}


// get public sparks of a specific group - requires collection handle and user token

export async function getPublicSparks(handle) {
    const response = await fetch(`${API_DOMAIN}/publicsparks/${handle}/`, {
        method: 'GET',
        headers: {
            'Authorization': ` Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not fetch pinned sparks.');
    }

    return data;
}


// get private sparks of a specific group - requires collection handle and user token

export async function getPrivateSparks(handle) {
    const response = await fetch(`${API_DOMAIN}/privatesparks/${handle}/`, {
        method: 'GET',
        headers: {
            'Authorization': ` Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not fetch pinned sparks.');
    }

    return data;
}


// get all sparks by a specific member of a group

export async function getMemberSparks(queryData) {
    const response = await fetch(`${API_DOMAIN}/membersparks/${queryData.handle}/?search=${queryData.id}/`, {
        method: 'GET',
        headers: {
            'Authorization': ` Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not fetch sparks by this member.');
    }

    return data;
}


// get all sparks in a collection, filtered in text field by user entry 

export async function getFilteredSparks(queryData) {
    // Using the replace method with a regular expression to replace "/" with a space if there's a character on either side,
    // otherwise remove it entirely
    let input = queryData.input
    input = input.replace(/(.)\/(?=.)/g, '$1 ').replace(/(^\/|\/$)/g, '');
    const response = await fetch(`${API_DOMAIN}/sparkfilter/${queryData.handle}/${input}/`, {
        method: 'GET',
        headers: {
            'Authorization': ` Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not fetch pinned sparks.');
    }

    let resultsWithoutCentre = [];

    for (let i = 0; i < data.length; i++) {
        const instance = data[i];
        if (instance.id !== queryData.centre) {
            resultsWithoutCentre.push(instance);
        }
    }

    let splitResults = [];

    for (let i = 0; i < resultsWithoutCentre.length; i += 7) {
        splitResults.push(resultsWithoutCentre.slice(i, i + 7));
    }

    return splitResults;
}


// get all sparks in a collection filtered by search, but this is used for any situation other than connecting sparks around centre spark

export async function filterCollSparks(queryData) {
    const response = await fetch(`${API_DOMAIN}/sparkfilter/${queryData.handle}/${queryData.input}/`, {
        method: 'GET',
        headers: {
            'Authorization': ` Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not fetch pinned sparks.');
    }

    return data;
}


// spark detail - requires spark id and user token

export async function getSparkDetail(sparkId) {
    const uToken = localStorage.getItem('token');
    const response = await fetch(`${API_DOMAIN}/sparkdetail/${sparkId}/`, {
        method: 'GET',
        headers: {
            'Authorization': uToken ? ` Token ` + localStorage.getItem('token') : '',
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch spark.');
    }

    // split up centre spark (loadedSpark) and connected sparks (connectedSparks)

    const loadedSpark = {
        id: sparkId,
        text: data.text,
        link: data.link,
        owner: data.owner,
        group: data.group,
        header: data.header,
        pinned: data.pinned,
        public: data.public,
        connected: data.connected,
        image: data.image
    };

    const allSparks = {
        centre: loadedSpark,
        connected: data.connected,
    };

    return allSparks;

}

// Connect and disconnect 2 sparks - requires spark1: [spark-id], spark2: [spark-id] 

export async function createConnect(connectData) {
    const response = await fetch(`${API_DOMAIN}/connect/`, {
        method: 'POST',
        body: JSON.stringify(connectData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not connect sparks.');
    }

    return data;
};

export async function deleteConnect(connectId) {
    const response = await fetch(`${API_DOMAIN}/disconnect/${connectId}/`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Token ` + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not disconnect sparks.');
    }

    return data;
};

// MARK NOTIFICATION RECORD AS READ
export async function notificationRead(sparkId) {
    const uToken = localStorage.getItem('token');
    const response = await fetch(`${API_DOMAIN}/notification-read/${sparkId}/`, {
        method: 'GET',
        headers: {
            // 'Content-Type': 'application/json',
            'Authorization': uToken ? `Token ` + localStorage.getItem('token') : '',
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not mark notification as read.');
    }

    return data;
}

// DELETE NOTIFICATION RECORD
export async function notificationDelete(sparkId) {
    const uToken = localStorage.getItem('token');
    const response = await fetch(`${API_DOMAIN}/delete-notification/${sparkId}/`, {
        method: 'DELETE',
        headers: {
            // 'Content-Type': 'application/json',
            'Authorization': uToken ? `Token ` + localStorage.getItem('token') : '',
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error('Could not delete notification.');
    }

    return data;
}