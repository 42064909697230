import { useContext } from "react";
import AuthContext from "../../store/auth-context";
import { textColourChoice } from "../../store/themesConfig";

const CardButtonWrapper = (props) => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme;
    const themePrimary = theme.highlight;
    const themeCard = theme.card
    const actionTextCol = textColourChoice(themePrimary);
    const editTextCol = textColourChoice(themeCard);

    if (props.type === 'action') {
        return (
            <div 
            style={{ 'stroke': `${actionTextCol}`, 'fill': `${actionTextCol}`, 'backgroundColor': `${themePrimary}` }}
            title={props.hoverTitle} 
            onClick={props.clickAction} 
            className="transition duration-75 ease-in-out hover:scale-105 rounded-full p-2 cursor-pointer">
                {props.children}
            </div>
        )
    }

    if (props.type === 'edit') {
        return (
            <div 
            style={{ 'stroke': `${editTextCol}`, 'fill': `${editTextCol}`, 'backgroundColor': `${themeCard}`, 'borderColor': `${themePrimary}` }}
            title={props.hoverTitle} 
            onClick={props.clickAction} 
            className="transition duration-75 ease-in-out hover:scale-105 rounded-full p-2 cursor-pointer border">
                {props.children}
            </div>
        )
    }

    if (props.type === 'submit') {
        return (
            <div 
            style={{ 'stroke': `${actionTextCol}`, 'fill': `${actionTextCol}`, 'backgroundColor': `${themePrimary}`, 'color': `${actionTextCol}` }}
            title={props.hoverTitle} 
            onClick={props.clickAction} 
            className="transition duration-75 ease-in-out hover:scale-105 rounded-md p-2 cursor-pointer">
                {props.children}
            </div>
        )
    }

    if (props.type === 'pending') {
        return (
            <div 
            style={{ 'stroke': `${actionTextCol}`, 'fill': `${actionTextCol}`, 'backgroundColor': `${themePrimary}`, 'color': `${actionTextCol}` }}
            title={props.hoverTitle} 
            onClick={props.clickAction} 
            className="rounded-md p-2 cursor-pointer">
                {props.children}
            </div>
        )
    }

}

export default CardButtonWrapper;