import { Fragment, useCallback, useContext } from "react"

import { ReactComponent as SparkIcon } from "../../icons/circle.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { ReactComponent as PuffIcon } from "../../icons/puff.svg";
import { useRef, useEffect, useState } from "react";

import useHttp from "../../hooks/use-http";
import { newConnectedSpark, newSparkImage } from "../../lib/api";
import MemberContext from "../../store/isMember-context";
import CardButtonWrapper from "../buttons/CardButtonWrapper";
import ImageUploadField from "./fields/ImageUploadField";
import { Transition } from "@headlessui/react";

const NewConSparkForm = (props) => {

    const memberCtx = useContext(MemberContext);
    const pubCtx = memberCtx.public;

    // NEW HEADERS FEATURE HERE
    // REFERENCES FOR NEW SPARK TEXT AND LINK INPUTS

    const textHeaderRef = useRef();
    const textBodyRef = useRef();
    const sparkLinkRef = useRef();

    const [sparkImage, setSparkImage] = useState(null);
    const [Preview, setPreview] = useState("");

    const [textHeader, setTextHeader] = useState("");
    const [textBody, setTextBody] = useState("");
    const [focussing, setFocussing] = useState('header');
    const [isHidden, setIsHidden] = useState(true);

    const keyPressHandler = (e) => {
        if (e.key === 'Enter') {
            setFocussing('body');
            textBodyRef.current.focus();
        };
        if (e.key === 'Backspace' && !isHidden && textBodyRef.current.value === '') setFocussing('header');
    };

    useEffect(() => {
        if (focussing === 'body') {
            setIsHidden(false);
        };
        if (focussing === 'header') {
            textHeaderRef.current.focus();
            setIsHidden(true);
        };
    }, [focussing, textHeaderRef]);

    // UPDATE THE CURRENT STATE OF INPUT FOR TEXT FIELD (USED UP-DOM TO FETCH RELATED SPARK RESULTS BY INPUT)

    // const textInputVal = props.conSparkState.input;
    const textInputUpdate = props.conSparkState.updateInput;
    const textInputReset = props.conSparkState.resetInput;

    useEffect(() => {
        textInputUpdate(textHeader + ' ' + textBody)
    }, [textInputUpdate, textBody, textHeader])

    // RELATED DATA FOR CREATING NEW CONNECTED SPARK - COLL HANDLE FOR SPARK AND CENTRE SPARK ID FOR CONNECTION

    const collHandle = props.collHandle;
    const centreSparkId = props.centreSpark.id;

    // FUNCTION TO TOGGLE STATE OF ADDING NEW CON SPARK OR VIEWING CURRENTLY CON SPARKS

    const handler = props.conSparkState.handler;

    // FUNCTION TO UPDATE CURRENT CON SPARKS ON NEW CON SPARK CREATION

    const updateResults = props.conSparkState.updateFunction;

    // REDUCER FUNCTION TO HANDLE STATES OF CREATING NEW CON SPARK

    const { sendRequest, data, error, status } = useHttp(newConnectedSpark);
    const { sendRequest: sendPicRequest, data: picData, error: picError, status: picStatus } = useHttp(newSparkImage);

    // MANAGE THE UPDATE ONCE NEW CON SPARK CREATED - 
    // RESET STATE BACK TO VIEWING CURRENT CON SPARKS & RE-FETCH CONNECTED SPARK RESULTS

    useEffect(() => {
        if (status === 'completed' && !error && !sparkImage) {
            handler();
            updateResults(centreSparkId);
            textInputReset();
        }
    }, [status, handler, data, error, updateResults, centreSparkId, textInputReset, sparkImage])

    // MANAGE UPDATE ONCE NEW SPARK WITH IMAGE CREATED - SEE ABOVE COMMENT FOR EXTRA INFO

    useEffect(() => {
        if (picStatus === 'completed' && !picError) {
            handler();
            updateResults(centreSparkId);
            textInputReset();
        }
    }, [picStatus, picError, handler, updateResults, centreSparkId, textInputReset])

    // HANDLE SUBMISSION OF NEW CON SPARK 

    const collectionFormHandler = (sparkData) => {
        sendRequest(sparkData);
    };

    const newImageHandler = useCallback(() => {
        let formData = new FormData()
        formData.append('image', sparkImage)
        sendPicRequest({
            spark: data.newSpark.id,
            data: formData,
        })
    }, [sparkImage, sendPicRequest, data])

    const newSparkHandler = (e) => {
        e.preventDefault();

        const text = textHeader.length > 0 ? textHeader.trimStart().trimEnd() + `\n` + textBody.trimStart().trimEnd() : textBody.trimStart().trimEnd();
        const link = sparkLinkRef.current.value;

        if (text.length === 0) return;

        collectionFormHandler({
            data: { text: text, link: link, header: textHeader.length > 0 ? true : false, public: pubCtx.state ? true : false },
            handle: collHandle,
            connector: centreSparkId
        });
    };

    // DON'T move back to normal state if spark created but image is submitted, instead submit image separately

    useEffect(() => {
        if (status === 'completed' && !error && sparkImage) {
            newImageHandler()
        }
    }, [status, error, sparkImage, newImageHandler])

    // Handle state toggle of header placeholder text
    const [headerDefault, setHeaderDefault] = useState(true)
    const [headerNew, setHeaderNew] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setHeaderDefault(!headerDefault)
            setHeaderNew(!headerNew)
        }, 4000);
    }, [headerDefault, headerNew])

    const headerChangeHandler = () => {
        setTextHeader(textHeaderRef.current.value);
    };
    const bodyChangeHandler = () => {
        setTextBody(textBodyRef.current.value);
    };


    const txtAreaStyle = "w-full z-10 h-full absolute top-0 left-0 right-0 bottom-0 resize-none overflow-hidden outline-none bg-transparent "
    const contentStyle = {
        header: textHeader.length === 0 ? (txtAreaStyle + "font-light text-lg md:text-xl leading-snug placeholder-transparent") : (txtAreaStyle + "font-light text-lg md:text-xl leading-snug"),
        body: txtAreaStyle + "font-elight text-sm md:text-base leading-snug"
    };

    return (
        <div className="w-full h-full flex place-content-center">
            {(Preview) && (
                <div className="absolute top-0 left-0 bottom-0 right-0 overflow-hidden opacity-25 rounded-md flex place-content-center">
                    <img className="object-cover rounded-md w-full h-full" src={Preview ? Preview : ""}></img>
                    <img className="-z-10 absolute w-full h-full top-0 left-0 object-cover rounded-md blur-md" src={Preview ? Preview : ""}></img>
                </div>
            )}
            <div className="absolute top-1 left-1 gap-1 flex flex-row">
                <SparkIcon className="h-5 w-5 opacity-60" />
                <ImageUploadField Preview={Preview} setSparkImage={setSparkImage} setPreview={setPreview} />
            </div>
            {/* New dynamic text areas here ? */}
            <div className="mt-9 mb-9 relative flex flex-col w-full overflow-auto">
                {(textHeader.length === 0) && (
                    <div className="opacity-70">
                        <Transition
                            className={'w-full h-full absolute px-2 md:px-3 font-light text-lg md:text-xl leading-snug'}
                            show={headerDefault}
                            unmount="true"
                            enter="transition-opacity ease-out duration-500 delay-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-full"
                            leave="transition-opacity ease-in duration-500 -delay-500"
                            leaveFrom="opacity-full"
                            leaveTo="opacity-0"
                        >
                            Type your spark here...
                        </Transition>
                        <Transition
                            className={'w-full h-full absolute px-2 md:px-3 font-light text-lg md:text-xl leading-snug'}
                            show={headerNew}
                            unmount="true"
                            enter="transition-opacity ease-out duration-500 delay-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-full"
                            leave="transition-opacity ease-in duration-500 -delay-500"
                            leaveFrom="opacity-full"
                            leaveTo="opacity-0"
                        >
                            Similar sparks will appear...
                        </Transition>
                    </div>
                )}
                <div className="relative mx-2 md:mx-3">
                    <textarea
                        id="headerArea"
                        className={contentStyle.header}
                        placeholder="Type your spark here..."
                        maxLength="500"
                        ref={textHeaderRef}
                        onChange={headerChangeHandler}
                        onKeyDown={(e) => keyPressHandler(e)}
                        autoFocus
                    />
                    <div className="opacity-0 pb-1 font-light text-lg md:text-xl leading-snug whitespace-pre-line">{textHeader.length === 0 ? (headerDefault ? 'Type your spark here...' : 'Similar sparks will appear...') : textHeader}</div>
                </div>
                <div className="relative mx-2 md:mx-3">
                    <textarea
                        id="bodyArea"
                        className={contentStyle.body}
                        placeholder="Add more detail here..."
                        maxLength="500"
                        ref={textBodyRef}
                        onChange={bodyChangeHandler}
                        onKeyDown={(e) => keyPressHandler(e)}
                        value={textBody.trimStart()}
                        autoFocus
                    />
                    <div className="opacity-0 pb-1 font-elight text-sm md:text-base leading-snug whitespace-pre-line">{textBody.length === 0 ? 'Add more detail here...' : textBody}</div>
                </div>
            </div>
            <div className="absolute bottom-2 w-full">
                <input
                    className="ml-2 md:ml-4 w-7/12 md:w-9/12 pt-2 outline-none bg-transparent font-elight text-sm md:text-base leading-snug overflow-auto overscroll-contain"
                    placeholder="Attach a link..."
                    ref={sparkLinkRef}
                />
            </div>
            <Fragment>
                {(status !== 'pending') && (
                    <div className="absolute flex flex-row bottom-1 right-1 left-50% space-x-1">
                        {/* There's no cancel button here */}
                        <CardButtonWrapper type={'submit'} hoverTitle={`Create Spark`} clickAction={newSparkHandler}>
                            <CheckIcon className="h-5 w-5 md:w-9" />
                        </CardButtonWrapper>
                    </div>
                )}
                {(status === 'pending') && (
                    <div className="absolute flex flex-row bottom-1 right-1 left-50% space-x-1">
                        <CardButtonWrapper type={'pending'} hoverTitle={`Loading`}>
                            <PuffIcon className="h-5 w-5 md:w-9 opacity-0" />
                        </CardButtonWrapper>
                    </div>
                )}
                {(picStatus === 'pending') && (
                    <div className="absolute flex flex-row bottom-1 right-1 left-50% space-x-1">
                        <CardButtonWrapper type={'pending'} hoverTitle={`Loading`}>
                            <PuffIcon className="h-5 w-5 md:w-9" />
                        </CardButtonWrapper>
                    </div>
                )}
            </Fragment>
        </div >
    );
}

export default NewConSparkForm;