import { Fragment, useRef, useState } from "react";

import { ReactComponent as AddUser } from '../../icons/user-plus.svg'
import { ReactComponent as RemoveUser } from '../../icons/user-x.svg'
import { ReactComponent as Tick } from '../../icons/check.svg'
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";

import { manageCollMembers, updateCollectionDetail } from "../../lib/api";
import { useContext } from "react";
import AuthContext from "../../store/auth-context";


const CollectionMembersForm = (props) => {

    const authCtx = useContext(AuthContext);

    const emailRef = useRef();

    const [emailInput, setEmailInput] = useState('');

    const emailChangeHandler = (e) => {
        setEmailInput(e.target.value);
    };

    // const members = props.collection.members;
    const owner = props.collection.owner;
    const displayName = props.collection.displayname;
    const theme = props.collection.theme;
    const handle = props.collection.handle;
    const membersList = props.membersData.list;
    const membersListUpdate = props.membersData.update;

    const bgColour = theme === 'DF' ? 'rgba(251, 113, 133, 0.5)' : theme === 'BL' ? 'rgba(3, 105, 161, 0.9)' : theme === 'GR' ? 'green' : theme === 'YE' ? 'yellow' : '';

    const addMember = () => {
        // const email = emailRef.current.value;
        manageCollMembers({
            email: emailInput,
            handle: handle
        }).then((result) => {
            membersListUpdate([result.user, ...membersList]);
            setEmailInput('');
        })
    }

    const removeMember = (memberId) => {
        let memberArray = [];
        memberArray.push(memberId);
        console.log(memberArray);
        updateCollectionDetail({
            handle: handle,
            data: {
                members: memberArray
            }
        })
        let newMemberList = membersList.filter((item) => item.id !== memberId);
        membersListUpdate(newMemberList);
    }

    if (authCtx.id == owner.id) {
        return (
            <Fragment>
                <div className="w-full h-full my-10 flex flex-col px-4 sm:px-6">
                    <div className="w-full px-2 flex place-content-center">
                        <div className="font-elight text-2xl text-darkgrey">Manage Collection Members</div>
                    </div>
                    <div className="w-full mt-5 px-1 flex flex-row gap-5 place-content-center">
                        <input ref={emailRef} value={emailInput} onChange={emailChangeHandler} className="font-elight w-4/5 text-lg p-2 outline-none text-darkgrey bg-transparent border-darkgrey/50 border-b" type="email" placeholder="add user email" />
                        <div onClick={addMember} className="rounded-md p-3 bg-salmon hover:scale-105 transition duration-75 ease-in-out cursor-pointer">
                            <AddUser className="w-5 h-5 stroke-white" />
                        </div>
                    </div>
                    <Transition
                        className={'mt-5 border border-darkgrey/30 h-3/5 px-4 leading-snug overflow-auto overscroll-contain'}
                        appear={true}
                        show={props.status === 'completed'}
                        enter="transition-opacity duration-700"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="flex py-2 flex-row">
                            <div className="text-darkgrey w-1/3 p-3 font-elight">{owner.first_name} {owner.last_name}</div>
                            <div className="text-darkgrey w-1/3 p-3 font-elight">{owner.email}</div>
                            <div className="w-1/3 flex place-content-end">
                                <div className="rounded-md p-3 bg-eggshell">
                                    <div className="font-light text-white">admin</div>
                                </div>
                            </div>
                        </div>
                        {membersList.map((mem, i) => (
                            <div key={i} className="flex py-2 flex-row">
                                <div className="text-darkgrey w-1/3 p-3 font-elight">{mem.first_name} {mem.last_name}</div>
                                <div className="text-darkgrey w-1/3 p-3 font-elight">{mem.email}</div>
                                <div className="w-1/3 flex place-content-end">
                                    <div onClick={() => removeMember(mem.id)} className="rounded-md p-3 bg-gray-500/70 hover:bg-salmon cursor-pointer">
                                        <RemoveUser className="w-5 h-5 stroke-white" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Transition>
                    <div className="w-full mt-5 flex flex-row place-content-center">
                        <Link to={`/${props.collection.handle}`} className="hover:scale-105 transition duration-75 ease-in-out bg-white hover:bg-salmon border border-darkgrey/30 hover:border-salmon rounded-md text-darkgrey hover:text-white font-light py-2 px-6 cursor-pointer">
                            {/* <Tick className="h-8 w-8 stroke-white" /> */}
                            Finish
                        </Link>
                    </div>
                </div>
            </Fragment>
        )
    } else {
        return <div className="text-white font-elight">Only collection admin can access this</div>
    }
};

export default CollectionMembersForm;