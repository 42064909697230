import CollectionContent from "../card-content/CollectionContent";
import { useState } from "react";
import SurCardWrapper from "./SurCardWrapper";
import { themeSelection } from "../../store/themesConfig";

const CollectionCard = (props) => {
    const [isHovering, setIsHovering] = useState(false);

    const data = props.data;
    const colTheme = data.theme;
    const fullTheme = colTheme === 'CS' ? themeSelection(colTheme, data.custom_theme) : themeSelection(colTheme);
    const themeBg = fullTheme.card;

    const hoverLeave = () => {
        setIsHovering(false);
    }

    const hoverEnter = () => {
        setIsHovering(true);
    }

    return (
        <SurCardWrapper bg={themeBg} hoverEnter={hoverEnter} hoverLeave={hoverLeave}>
            <CollectionContent
                isHovering={isHovering}
                data={props.data}
                fullTheme={fullTheme} />
        </SurCardWrapper>
    );
}

export default CollectionCard