export const palettes = {
    default: {
        bg: "#EEEEEE",
        highlight: "#FF6261",
        card: "#FFFFFF",
    },

    yellow: {
        bg: "#FFEBE7",
        highlight: "#468189",
        card: "#FFC2D9",
    },

    dark: {
        bg: "#002C3D",
        highlight: "#D81159",
        card: "#011936",
    },

    green: {
        bg: "#204E4A",
        highlight: "#F85E00",
        card: "#554348",
    },

    oz: {
        bg: "#FFD892",
        highlight: "#40ADD3",
        card: "#EF4A29",
    },

    hive: {
        bg: "#8C9EA8",
        highlight: "#023ED3",
        card: "#EAA639",
    },

    slime: {
        bg: "#D7C9F4",
        highlight: "#227F22",
        card: "#BCE29E",
    },
};

export const textColourChoice = (bg) => {
    const curBg = bg;
    const hexToRgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
    const rgbCol = hexToRgb(curBg)
    if ((rgbCol.r*0.299 + rgbCol.g*0.587 + rgbCol.b*0.114) > 150) {
        return '#171818'
    } else {
        return '#EEEEEE'
    }
}

export const themeSelection = (selection, customCols = {}) => {
    switch(selection) {
        case 'YE':
            return palettes.yellow;
        case 'BL':
            return palettes.dark;
        case 'GR':
            return palettes.green;
        case 'DF':
            return palettes.default;
        case 'OZ':
            return palettes.oz;
        case 'HI':
            return palettes.hive;
        case 'SL':
            return palettes.slime;
        case 'CS':
            return customCols;
        default:
            return palettes.default;
    }
};

export const isValidHexCode = (hexCode) => {
    // Check if the string is a valid hex code without the '#'
    const hexRegex = /^[0-9A-Fa-f]+$/;
    
    // Ensure the length is either 3 or 6 characters
    const validLength = hexCode.length === 3 || hexCode.length === 6;
  
    return validLength && hexRegex.test(hexCode);
  }