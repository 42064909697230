import { createCheckout } from "../../lib/stripe-api";
import { ReactComponent as ClustaInfinity } from "../../icons/ClustaInfinity2.svg"
import useHttp from "../../hooks/use-http";
import { Fragment } from "react";

const ToPaymentForm = () => {

    const { sendRequest, status, data, error } = useHttp(createCheckout);

    const checkoutHandler = () => sendRequest();

    return (
        <Fragment>
            <div className='absolute top-0 left-0 overflow-scroll h-full bg-gradient-to-br to-salmon/40 from-eggshell/40 flex flex-col'>
                <div className="h-28">
                    <ClustaInfinity className="w-full h-full text-salmon" />
                </div>
                <div className="flex flex-col p-4 gap-y-3 bg-salmon/10 rounded-t-md md:mx-16">
                    <div className=" text-darkgrey text-base font-light">
                        {/* For the Clusta lovers - you need unlimited collections, and you need to take those collections further. */}
                        Take the handbrake off, and support our mission to share and explore information online - without the doomscrolling.
                    </div>
                    <div className="font-semibold text-8xl">£3<span className="text-3xl text-darkgrey">.60 p/m</span></div>
                    {status !== 'pending' && (
                        <div onClick={checkoutHandler} className="mt-2 rounded-lg flex place-content-center cursor-pointer p-3 text-base font-semibold text-darkgrey hover:text-white bg-white/90 shadow-md hover:scale-105 hover:bg-salmon transition duration-150 ease-in-out">Go Limitless</div>
                    )}
                    {status === 'pending' && (
                        <div className="mt-8 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-white bg-salmon">...</div>
                    )}
                </div>
                <div className="flex flex-col p-4 gap-y-3 bg-eggshell/10 rounded-b-md md:mx-16">
                    <div className="w-full text-darkgrey text-sm md:text-lg font-light mt-3">
                        - Infinite collections<br />
                        - Fully customisable collection themes<br />
                        - Custom collection URL handles<br />
                        - Infinite collection members<br />
                        <span className="opacity-60">
                            {/* - Member permissions<span className="ml-1 font-light text-sm p-1 bg-salmon text-white rounded-md">coming soon</span><br />
                            - Custom collection home page<span className="ml-1 font-light text-sm p-1 bg-salmon text-white rounded-md">coming soon</span><br /> */}
                            - and more soon...
                        </span>
                    </div>
                </div>
                {/* <div className="w-full">.</div> */}
            </div>
        </Fragment>
    );
}

export default ToPaymentForm;