import { useContext } from "react";
import AuthContext from "../../store/auth-context";

const InvisCard = (props) => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme;
    const cardBg = props.position === "start" ? theme.card : 'none';

    const invisStyle = props.position === "start" ? "col-start-1 row-start-1 rounded-lg" : "col-start-2 row-start-2"

    return (
        <div style={{ 'backgroundColor': `${cardBg}` }} className={invisStyle} />
    );
}

export default InvisCard;