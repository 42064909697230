import { Fragment } from "react";
import CollectionMembersForm from "../components/forms/CollectionMembersForm";
import FormContainer from "../components/forms/FormContainer";

const ManageCollMembers = (props) => {

    return (
        <Fragment>
            <FormContainer>
                <CollectionMembersForm collection={props.collection} membersData={props.membersData} status={props.status} />
            </FormContainer>
        </Fragment>
    );
};

export default ManageCollMembers;