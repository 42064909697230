import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, query, where, orderBy, limit } from 'firebase/firestore';
import { firebaseDb } from "../../firebase";
import { useContext, useEffect } from 'react';
import AuthContext from '../../store/auth-context';

const LIMIT = 15;

const CallNotifications = () => {
    const authCtx = useContext(AuthContext);

    // Custom hook package to call useful values on firestore query
    const [value, loading, error] = useCollection(
        query(collection(firebaseDb, "notifications"), where("recipients", 'array-contains', authCtx.id), orderBy('__name__', 'desc'), limit(LIMIT)));
    
    useEffect(() => {
        authCtx.updateNotifications({value: value, loading: loading, error: error})
    }, [value, loading, error, authCtx.updateNotifications])

    return null;
}

export default CallNotifications;