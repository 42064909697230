
// This container is placed within 'Outer' - it is used as the carousel to toggle between 'pages' of surrounding cards

const GridContainer = (props) => {
    return (
        <div style={{transform: `translateX(-${props.page}00%)`}} className={`transition duration-500 ease-in-out flex flex-col flex-wrap space-x-0 mx-auto w-full h-full`}>
            {props.children}
        </div>
    );
};

export default GridContainer;