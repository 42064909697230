import { Fragment } from "react"

import GridContainer from "../grid/Container"
import Grid from "../grid/Grid"

import InvisCard from "../cards/InvisCard"
import Card from "../cards/Card"
import CentreCollectionCard from "../cards/CentreCollectionCard"
import IntroCard from "../cards/IntroCard"
import { connectedConfig } from "../../hooks/results-format"
import { useContext } from "react"
import MemberContext from "../../store/isMember-context"


const PinnedSparksResults = (props) => {

    const curPage = props.curPage;
    const allResults = props.results;

    const memberCtx = useContext(MemberContext);

    const splitResults = connectedConfig(props.results);

    return (
        <Fragment>
            <GridContainer page={curPage}>
                {allResults.length === 0 && (
                    <Grid type="list">
                        {memberCtx.state && (
                            <IntroCard data={props.collData} type='CollPinned' />
                        )}
                    </Grid>
                )}
                {allResults.length > 0 && (
                    <Fragment>
                        {splitResults.map((grid, i) => (
                            <Grid type="list" key={i}>
                                {grid.length > 4 && (
                                    <InvisCard />
                                )}
                                {grid.map((card) => (
                                    <Card state='spark' key={card.id} collHandle={props.collHandle} data={card} onResultsUpdate={props.onResultsUpdate} />
                                ))}
                            </Grid>
                        ))}
                    </Fragment>
                )}
            </GridContainer>
            <CentreCollectionCard data={props.collData} />
        </Fragment>
    )
}

export default PinnedSparksResults;