import { Fragment } from 'react';
import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useRef } from 'react';

const AuthForm = (props) => {

    // LOGIN LOGIC

    const emailInputRef = useRef();
    const pwInputRef = useRef();

    function submitLoginHandler(event) {
        event.preventDefault();

        const enteredEmail = emailInputRef.current.value;
        const enteredPw = pwInputRef.current.value;

        props.onLogin({ username: enteredEmail, password: enteredPw });
    }

    // SIGNUP LOGIC

    const emailRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();

    const [curPw, setCurPw] = useState('');
    const [curPwCheck, setCurPwCheck] = useState('');
    const [pwValid, setPwValid] = useState(curPw === curPwCheck);

    const [marketingConsent, setMarketingConsent] = useState(false)

    const pwChangeHandler = (e) => {
        setCurPw(e.target.value);
    };

    const pwCheckChangeHandler = (e) => {
        setCurPwCheck(e.target.value);
    }

    const onUpdateMarConsent = () => {
        setMarketingConsent(!marketingConsent);
    }

    useEffect(() => {
        const identifier = setTimeout(() => {
            setPwValid(
                curPw === curPwCheck
            );
        }, 500);

        return () => {
            clearTimeout(identifier);
        };
    }, [curPwCheck, curPw, setPwValid]);


    const signupSubmitHandler = (e) => {
        e.preventDefault();

        const email = emailRef.current.value;
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const pw = curPw;

        if (pwValid) {
            props.onSignup({ 
                email: email, 
                first_name: firstName, 
                last_name: lastName, 
                password: pw,
                marketing_consent: marketingConsent
             })
        }
    }


    const pwFormClass = pwValid ? "font-elight md:text-lg p-3 outline-none placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" : "font-elight md:text-lg p-3 outline-none placeholder-darkgrey/70 text-darkgrey bg-transparent border-rose-500 border-b";

    // login form JSX
    if (props.type === 'login') {
        return (
            <Fragment>
                <Link to={`/auth/signup`} className="absolute text-sm flex place-content-center text-darkgrey/70 hover:text-salmon cursor-pointer font-elight top-3 w-full">New to Clusta? Create an account</Link>
                <div className='w-full h-full flex flex-col px-4 sm:px-6 py-14'>
                    <div>
                        <div className="ml-2 py-4 text-darkgrey text-3xl font-elight">Login</div>
                        <div className="mb-4 md:flex md:flex-wrap md:justify-between">
                            <div className="flex flex-col mb-4 md:w-full">
                                <input className="font-elight text-lg p-3 outline-none placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" type="email" placeholder="email" ref={emailInputRef} />
                            </div>
                            <div className="flex flex-col mb-6 md:w-full">
                                <input className="font-elight text-lg p-3 outline-none placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" type="password" placeholder="Password" ref={pwInputRef} />
                            </div>
                        </div>
                        {!props.isLoading && (
                            <div onClick={submitLoginHandler} className="mt-10 mb-2 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey hover:text-white border border-darkgrey/50 hover:border-salmon hover:scale-105 hover:bg-salmon transition ease-in-out">Login</div>
                        )}
                        {props.isLoading && (
                            <div className="mt-10 mb-2 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-white bg-salmon">...</div>
                        )}
                        <Link to={'/auth/reset_password'} className='mb-3 w-full flex place-content-center p-4 text-darkgrey/70 text-sm font-elight underline cursor-pointer'>Forgot Password</Link>
                    </div>
                </div>
            </Fragment>
        );
    }

    // sign up form JSX
    if (props.type === 'signup') {
        return (
            <Fragment>
                <Link to={`/auth/login`} className="absolute text-sm flex place-content-center text-darkgrey/80 hover:text-salmon cursor-pointer font-elight top-3 w-full">Have an account? Login</Link>
                <div className='w-full h-full flex flex-col px-4 sm:px-6 py-5'>
                    <div className="ml-2 py-4 text-darkgrey text-2xl md:text-3xl font-elight">Sign Up</div>
                    <div className="mb-4 md:flex md:flex-wrap md:justify-between">
                        <div className="flex flex-col mb-4 md:w-1/2">
                            <input className="p-3 md:text-lg font-elight outline-none md:mr-2 placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" type="text" placeholder="First Name" ref={firstNameRef} />
                        </div>
                        <div className="flex flex-col mb-4 md:w-1/2">
                            <input className="p-3 md:text-lg font-elight outline-none md:ml-2 placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" type="text" placeholder="Last Name" ref={lastNameRef} />
                        </div>
                        <div className="flex flex-col mb-4 md:w-full">
                            <input className="font-elight md:text-lg p-3 outline-none placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" type="email" placeholder="email" ref={emailRef} />
                        </div>
                        <div className="flex flex-col mb-6 md:w-full">
                            <input className={pwFormClass} type="password" value={curPw} onChange={pwChangeHandler} placeholder="Password" />
                        </div>
                        <div className="flex flex-col mb-6 md:w-full">
                            <input className={pwFormClass} type="password" value={curPwCheck} onChange={pwCheckChangeHandler} placeholder="Password (again)" />
                        </div>
                        <div className="flex flex-row gap-2 mb-2 mx-auto">
                            <input type="checkbox" checked={marketingConsent} onChange={onUpdateMarConsent} />
                            <div className="font-elight text-base">I'd like to receive the latest news and updates from Clusta.</div>
                        </div>
                        <div className='text-darkgrey/70 mx-auto font-elight text-[0.7rem]'>
                            By clicking 'create account' you agree to our <a className="underline"
                                href="https://app.termly.io/document/terms-of-use-for-saas/61fdfd0a-be00-4b8f-92f0-0bb0cd4a8278"
                                target="_blank" rel="noreferrer">Terms and Conditions</a> and
                            <a className="underline"
                                href="https://app.termly.io/document/privacy-policy/e5c6c798-7676-4148-a139-85c282231e1b"
                                target="_blank" rel="noreferrer"> Privacy
                                Policy</a>
                        </div>
                    </div>
                    {!props.isLoading && (
                        <div onClick={signupSubmitHandler} className=" md:mb-3 mx-20 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey border border-darkgrey/50 hover:scale-105 hover:bg-salmon hover:border-salmon hover:text-white transition ease-in-out">Create Account</div>
                    )}
                    {props.isLoading && (
                        <div className="md:mb-3 mx-20 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey border">...</div>
                    )}
                </div>
            </Fragment>
        );
    }
};

export default AuthForm;