import { Fragment, useRef, useState, useContext } from "react"
import { Transition } from '@headlessui/react'

import Outer from "../../components/grid/Outer";
import MyCollectionsResults from "../results-state/MyCollectionsResults";
import PinnedSparksResults from "../results-state/PinnedSparksResults";
import SparkDetailResults from "../results-state/SparkDetailResults";
import { ReactComponent as ChevronRightIcon } from "../../icons/chevron-right.svg";
import { ReactComponent as ChevronLeftIcon } from "../../icons/chevron-left.svg";
import { textColourChoice } from "../../store/themesConfig";
import AuthContext from "../../store/auth-context";

// this component compiles all the components required for the grid of cards and accepts props from the relevant page component that is rendered

const Results = (props) => {

    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme
    const highlight = theme.highlight

    // curPage state controls the translateX of the GridContainer component (Container.js) to 'paginate'

    const [curPage, setCurPage] = useState(0);

    let pageCount;

    if (props.type === 'spark' && props.conSparkState.state) {
        pageCount = props.filteredSparks.length
    } else {
        pageCount = (props.results.length / 8) % 1 === 0 ? (props.results.length / 8) : (Math.floor(props.results.length / 8) + 1);
    }

    // these handler functions will format to array.length of results once api connection is established

    const pageUpHandler = () => {
        setCurPage((prevState) => prevState >= pageCount - 1 ? 0 : (prevState + 1))
    };

    const pageDwnHandler = () => {
        setCurPage((prevState) => prevState <= 0 ? pageCount - 1 : (prevState - 1))
    };

    const pageResetHandler = () => {
        setCurPage(0);
    }

    // Scroll through pages with dynamic cursor style

    const scrollLeftRef = useRef()
    const scrollRightRef = useRef()
    const [inHoverState, setInHoverState] = useState(null);
    const setIsHovering = (e) => {
        if (scrollLeftRef.current.contains(e.target)) {
            setInHoverState("left")
        }
        if (scrollRightRef.current.contains(e.target)) {
            setInHoverState("right")
        }
    }

    const setNotHovering = () => {
        setInHoverState(null)
    }

    const chevronColour = textColourChoice(highlight)

    const leftOnePageStyle = "absolute z-40 left-0 h-[86%] w-[9%] mt-1"
    const rightOnePageStyle = "absolute z-40 right-0 h-[86%] w-[9%] mt-1"
    const leftMultiPageStyle = leftOnePageStyle + " cursor-hidden"
    const rightMultiPageStyle = rightOnePageStyle + " cursor-hidden"

    return (
        <Fragment>
            <div ref={scrollRightRef} onClick={pageUpHandler} onMouseEnter={setIsHovering} onMouseLeave={setNotHovering} className={pageCount > 1 ? rightMultiPageStyle : rightOnePageStyle}>
                <div className="absolute top-0 bottom-0 right-2 grid content-center">
                    <Transition
                        show={(inHoverState === "right") && (pageCount > 1)}
                        enter="transition-opacity duration-75"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-75"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="z-40 rounded-full w-12 h-12"
                        style={{ background: highlight }}>
                        <ChevronRightIcon className="ml-[0.1rem] w-full h-full" style={{ color: chevronColour }} />

                    </Transition>
                </div>
            </div>
            <div ref={scrollLeftRef} onClick={pageDwnHandler} onMouseEnter={setIsHovering} onMouseLeave={setNotHovering} className={pageCount > 1 ? leftMultiPageStyle : leftOnePageStyle}>
                <div className="absolute top-0 bottom-0 left-2 grid content-center">
                    <Transition
                        show={(inHoverState === "left") && (pageCount > 1)}
                        enter="transition-opacity duration-75"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-75"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="z-40 rounded-full w-12 h-12"
                        style={{ background: highlight }}>
                        <ChevronLeftIcon className="-ml-[0.1rem] w-full h-full" style={{ color: chevronColour }} />
                    </Transition>
                </div>
            </div>
            <Outer>
                <Transition
                    className={'w-full h-full'}
                    appear={true}
                    show={props.status === 'completed'}
                    enter="transition-opacity duration-700"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {(props.type === 'mycolls') && (
                        <MyCollectionsResults results={props.results} curPage={curPage} />
                    )}

                    {(props.type === 'pinned') && (
                        <PinnedSparksResults
                            curPage={curPage}
                            results={props.results}
                            collHandle={props.collHandle}
                            collData={props.collData}
                            onResultsUpdate={props.onResultsUpdate}
                        />
                    )}

                    {(props.type === 'spark' && props.status === 'completed' && !props.error) && (
                        <SparkDetailResults
                            type={props.type}
                            filterStatus={props.filterStatus}
                            filteredSparks={props.filteredSparks}
                            firstFilteredSparks={props.firstFilteredSparks}
                            conSparkState={props.conSparkState}
                            collHandle={props.collHandle}
                            centre={props.centre}
                            results={props.results}
                            status={props.status}
                            error={props.error}
                            onResultsUpdate={props.onResultsUpdate}
                            curPage={curPage}
                            pageResetHandler={pageResetHandler}
                        />
                    )}
                </Transition>
            </Outer>
        </Fragment>
    );
};

export default Results;