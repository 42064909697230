import { Fragment } from "react"

import GridContainer from "../grid/Container"
import Grid from "../grid/Grid"

import InvisCard from "../cards/InvisCard"
import Card from "../cards/Card"
import { connectedConfig } from "../../hooks/results-format"
import { useState, useEffect, useContext } from "react"
import MemberContext from "../../store/isMember-context"
import IntroCard from "../cards/IntroCard"
import ConSparkCard from "../cards/ConSparkCard"
import NewConSparkCard from "../cards/NewConSparkCard"


const SparkDetailResults = (props) => {

    const memberCtx = useContext(MemberContext);

    const curPage = props.curPage;
    const pageResetHandler = props.pageResetHandler;

    const [splResults, setSplResults] = useState([]);

    useEffect(() => {
        setSplResults(connectedConfig(props.results));
    }, [setSplResults, props.results]);

    const onRandomise = () => {
        const newRes = connectedConfig(props.results, true);
        setSplResults(newRes);
    };

    // const splitResults = connectedConfig(props.results);

    return (
        <Fragment>
            {!props.conSparkState.state && (
                <GridContainer page={curPage}>
                    {(props.results.length === 0 && memberCtx.state) && (
                        <Grid type="list">
                            <IntroCard data={props.conSparkState} type='SparkDetail' />
                        </Grid>
                    )}
                    {props.results.length > 0 && (
                        <Fragment>
                            {splResults.map((grid, i) => (
                                <Grid type="list" key={i}>
                                    {grid.length > 4 && (
                                        <InvisCard />
                                    )}
                                    {grid.map((card) => (
                                        <ConSparkCard
                                            key={card.id}
                                            onResultsUpdate={props.onResultsUpdate}
                                            pageReset={pageResetHandler}
                                            collHandle={props.collHandle}
                                            centreSparkId={props.centre.id}
                                            conSparkState={props.conSparkState}
                                            data={card} />
                                    ))}
                                </Grid>
                            ))}
                        </Fragment>
                    )}
                </GridContainer>
            )}
            {props.conSparkState.state && (
                <Fragment>
                    <GridContainer page={curPage}>
                        {props.filteredSparks.map((grid, i) => (
                            <Grid type="list" key={i}>
                                <InvisCard position="start" />
                                {grid.length > 4 && (
                                    <InvisCard />
                                )}
                                {grid.map((card) => (
                                    <ConSparkCard
                                        key={card.id}
                                        onResultsUpdate={props.onResultsUpdate}
                                        pageReset={pageResetHandler}
                                        collHandle={props.collHandle}
                                        centreSparkId={props.centre.id}
                                        conSparkState={props.conSparkState}
                                        data={card} />
                                ))}
                            </Grid>
                        ))}
                    </GridContainer>
                </Fragment>
            )}
            {props.conSparkState.state && (
                <NewConSparkCard
                collHandle={props.collHandle}
                centreSpark={props.centre}
                conSparkState={props.conSparkState} />
            )}
            <Card
                state="centre"
                conSparkState={props.conSparkState}
                data={props.centre}
                collHandle={props.collHandle}
                onRandomise={onRandomise}
                pageReset={pageResetHandler} />
        </Fragment>
    )
}

export default SparkDetailResults;