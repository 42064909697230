import { Fragment, useContext } from "react"
import { useNavigate, Link } from "react-router-dom";

import { ReactComponent as SpaceIcon } from "../../icons/grid.svg";
import { ReactComponent as SettingsIcon } from "../../icons/settings.svg";
import { ReactComponent as MembersIcon } from "../../icons/users.svg";
import AuthContext from "../../store/auth-context";
import MemberContext from "../../store/isMember-context";
import HeadingContent from "./HeadingContent";
import { textColourChoice } from "../../store/themesConfig";

const CollectionContent = (props) => {
    const authCtx = useContext(AuthContext);
    const memCtx = useContext(MemberContext);
    const fullTheme = props.fullTheme ? props.fullTheme : authCtx.theme;
    const themePrimary = fullTheme.highlight;
    const textCol = textColourChoice(themePrimary);

    const navigate = useNavigate();

    const isMember = props.data.members.filter((instance) => instance.id == authCtx.id).length != 0;

    function groupDetailHandler() {
        navigate(`/${props.data.handle}`)
    }

    const showPlus = (props.isCentre && memCtx.state) ? true : false;
    const showButtons = props.isHovering && !props.isCentre ? true : false;
    const parentStyle = "absolute flex flex-row -bottom-4 w-12 h-12 left-50% space-x-1";

    return (
        <Fragment>
            <div onClick={groupDetailHandler} className="w-full h-full flex place-content-center cursor-pointer">
                <SpaceIcon className="absolute top-1 left-1 h-5 w-5 opacity-60" />
                <div className="absolute top-1.5 right-1.5 space-x-2 opacity-60">
                    <div className="inline space-x-0.5">
                        <MembersIcon className="inline align-top h-4 w-4" />
                        <div className="inline align-top font-light text-sm">{props.data.members.length + 1}</div>
                    </div>
                </div>
                <div className="my-auto mx-3 text-center font-light text-xl sm:text-3xl">{props.data.displayname}</div>
            </div>
            {showPlus &&
                <HeadingContent className={parentStyle} id="CollCentre" />
            }
            {showButtons && (
                <div className="absolute flex flex-row -bottom-2 left-50% space-x-1">
                    {authCtx.id == props.data.owner.id && (
                        <Link style={{'backgroundColor': `${themePrimary}`}} to={`/${props.data.handle}/managemembers`} className="transition duration-75 ease-in-out hover:scale-105 rounded-full p-2 cursor-pointer">
                            <MembersIcon style={{'stroke': `${textCol}`}} className="h-5 w-5" />
                        </Link>
                    )}
                    {(isMember || authCtx.id == props.data.owner.id) && (
                        <Link style={{'backgroundColor': `${themePrimary}`}} to={`/${props.data.handle}/settings`} className="transition duration-75 ease-in-out hover:scale-105 rounded-full p-2 cursor-pointer">
                            <SettingsIcon style={{'stroke': `${textCol}`}} className="h-5 w-5" />
                        </Link>
                    )}
                </div>
            )}
        </Fragment>
    )
}

export default CollectionContent;