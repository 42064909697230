import { Fragment, useState, useEffect, useContext } from "react"

import { Link } from 'react-router-dom';

import { ReactComponent as SpaceIcon } from "../../icons/grid.svg";
import { ReactComponent as ExploreIcon } from "../../icons/search.svg";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import { textColourChoice } from "../../store/themesConfig";
import AuthContext from "../../store/auth-context";

const HeadingContent = (props) => {

    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme;
    const cardBg = theme.card;
    const themeBg = theme.bg;
    const themePrimary = theme.highlight;

    const buttonCol = props.id === 'CollCentre' ? themeBg : cardBg;
    const textCol = props.id === 'CollCentre' ? textColourChoice(themeBg) : textColourChoice(cardBg);

    const [textColour, setTextColour] = useState('')
    const [buttonBgCol, setButtonBgCol] = useState('')

    useEffect(() => {
        setTextColour(textCol);
        setButtonBgCol(buttonCol);
    }, [setTextColour, cardBg, buttonCol, textCol]);

    const hoverTextCol = () => {
        const hoverTextColour = textColourChoice(props.content === 'mycollections' ? '#EEEEEE' : themePrimary);
        setButtonBgCol(props.content === 'mycollections' ? '#EEEEEE' : themePrimary);
        setTextColour(hoverTextColour);
        return;
    }

    const defTextCol = () => {
        setButtonBgCol(buttonCol);
        setTextColour(textCol);
        return;
    }

    const parentStyle = "absolute flex flex-row -bottom-3 w-12 h-12 left-50% space-x-1 rounded-full";

    if (props.content === 'mycollections') {
        return (
            <Fragment>
                <div className="flex h-full w-full place-content-center">
                    <div
                        className="flex flex-col w-full place-content-center cursor-pointer px-1"
                    >
                        {/* {Need to add this welcom message eventually} */}
                        {/* <div className="font-elight text-2xl text-white w-full mt-2 text-center">
                            {`Hello ${authCtx.firstName}`}
                        </div> */}
                        <div className="w-16 mx-auto flex place-content-center">
                            <SpaceIcon className="h-20 w-20 stroke-white" />
                        </div>
                        <div className="font-elight text-lg md:text-2xl text-white w-full text-center">
                            My Collections
                        </div>
                    </div>
                </div>
                <Link
                    onMouseOver={hoverTextCol}
                    onMouseLeave={defTextCol}
                    style={{ 'backgroundColor': `${buttonBgCol}` }}
                    title={`Create New Collection`}
                    to={'/newcollection'}
                    className={parentStyle}
                >
                    <PlusIcon style={{ 'stroke': `${textColour}`, 'borderColor': `${themePrimary}` }} className="w-full h-full transition duration-150 ease-out border-2 rounded-full p-2 cursor-pointer" />
                </Link>
            </Fragment>
        );
    }

    if (props.content === 'lockedmycollections') {
        return (
            <Fragment>
                <div className="flex h-full w-full place-content-center">
                    <div
                        className="flex flex-col w-full place-content-center cursor-pointer"
                    >
                        <div className="w-16 mx-auto flex place-content-center">
                            <SpaceIcon className="h-20 w-20 stroke-white" />
                        </div>
                        <div className="font-elight text-2xl text-white w-full text-center">
                            My Collections
                        </div>
                    </div>
                </div>
                <Link
                    onMouseOver={hoverTextCol}
                    onMouseLeave={defTextCol}
                    style={{ 'backgroundColor': `${buttonBgCol}` }}
                    title={`You must upgrade your account to create a new collection`}
                    to={`/auth/upgrade`}
                    className={parentStyle}
                >
                    <LockIcon style={{ 'stroke': `${textColour}`, 'borderColor': `${themePrimary}` }} className="w-full h-full transition duration-150 ease-out border-2 rounded-xl p-2 cursor-pointer" />
                </Link>
            </Fragment>
        );
    }

    if (props.id === 'CollCentre') {
        return (
            <Link
                onMouseOver={hoverTextCol}
                onMouseLeave={defTextCol}
                style={{ 'backgroundColor': `${buttonBgCol}` }}
                title={`Create New Spark`}
                to={`newspark`}
                className={parentStyle}
            >
                <PlusIcon style={{ 'stroke': `${textColour}`, 'borderColor': `${themePrimary}` }} className="w-full h-full transition duration-150 ease-in-out border-2 rounded-full p-2 cursor-pointer" />
            </Link>
        )
    }

    if (props.content === 'explore') {
        return (
            <Fragment>
                <div className="flex h-full w-full place-content-center">
                    <div
                        className="flex flex-col w-full place-content-center cursor-pointer"
                    >
                        <div className="w-16 mx-auto flex place-content-center">
                            <ExploreIcon style={{ 'stroke': `${textColour}` }} className="h-20 w-20" />
                        </div>
                        <div style={{ 'color': `${textColour}` }} className="font-elight text-xl w-full text-center">
                            search results
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }


}

export default HeadingContent;