import { Fragment } from "react";
import Outer from "../grid/Outer";
import { Transition } from '@headlessui/react'
import NewSparkCard from "../cards/NewSparkCard";


const NewSparkResults = (props) => {
    return (
        <Fragment>
            <Outer>
                <Transition
                    className={'w-full h-full'}
                    appear={true}
                    show={true}
                    enter="transition-opacity duration-700"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <NewSparkCard data={props.collection} />
                </Transition>
            </Outer>
        </Fragment >
    );
}

export default NewSparkResults;