import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Navigation from "../components/layout/Navigation"
import CollectionPinned from "./CollectionPinned";
import useHttp from "../hooks/use-http";
import { collectionDetail } from "../lib/api";
import SparkDetail from "./SparkDetail";

import SearchContext from "../store/sparkSearch-context";
import MemberContext from "../store/isMember-context";

import NewSpark from "./NewSpark";
import CollectionDashboard from "./CollectionDashboard";
// import MySparks from "./MySparks";
import ManageCollMembers from "./ManageCollMembers";
import CollectionSettings from "./CollectionSettings";
import { useContext } from "react";
import AuthContext from "../store/auth-context";
import { Fragment } from "react";

const CollectionDetail = (props) => {

    const memberCtx = useContext(MemberContext);
    const authCtx = useContext(AuthContext);
    const searchCtx = useContext(SearchContext);

    const collectionHandle = props.handle;

    const { sendRequest, status, data: collectionData, error } = useHttp(
        collectionDetail,
        true
    );

    const [memberList, setMemberList] = useState([])

    const membersData = {
        list: memberList,
        update: setMemberList,
    }

    const setMemFunction = memberCtx.function;
    const updateLogoutTimer = authCtx.updateLogoutTimer;

    useEffect(() => {
        sendRequest(collectionHandle);
    }, [sendRequest, collectionHandle]);

    useEffect(() => {
        if (status === 'completed' && !error) {
            setMemberList(collectionData.members);
        }
    }, [status, error, setMemberList, collectionData])

    useEffect(() => {
        if (status === 'completed' && !error) {
            document.title = `${collectionData.displayname} | Clusta.`;
            const memberStatus = collectionData.members.filter((instance) => instance.id == authCtx.id).length != 0;
            const ownerStatus = authCtx.id == collectionData.owner.id;
            const totalStatus = memberStatus || ownerStatus;
            setMemFunction(totalStatus);
        }
    }, [status, error, memberCtx, authCtx, collectionData, setMemFunction]);

    // This randomly stopped working and immediately logs the user out when theme isn't default
    // It seems updating theme in authctx resets the logout timer in the let to 0
    // No idea why it worked absolutely fine for a month, have fun fixing that one

    // useEffect(() => {
    //     if (status === 'completed' && !error) {
    //         updateLogoutTimer();
    //     }
    // }, [updateLogoutTimer, status, error])

    const setTheme = authCtx.updateTheme;

    useEffect(() => {
        if (status === 'completed' && !error) {
            if (collectionData.theme === 'CS') {
                setTheme(collectionData.theme, collectionData.custom_theme);
            } else {
                setTheme(collectionData.theme);
            }
        }
    }, [status, error, collectionData, setTheme]);

    if (status === 'pending') {
        return (
            <div></div>
        )
    };

    if (!error && status === 'completed') {
        return (
            <Fragment>
                <Navigation
                    state="groupDetail"
                    collection={collectionData}
                />
                {searchCtx.state.length > 0 && (
                    <CollectionDashboard collection={collectionData} />
                )}
                {(status === 'completed' && searchCtx.state.length === 0) && (
                    <Routes>
                        <Route
                            path="/"
                            element={<CollectionPinned
                                collection={collectionData}
                            />}
                        />
                        <Route
                            path="/settings"
                            element={<CollectionSettings
                                collection={collectionData}
                            />}
                        />
                        <Route
                            path="/spark/:sparkId"
                            element={<SparkDetail
                                collection={collectionData}
                            />}
                        />
                        <Route
                            path="/newspark"
                            element={<NewSpark
                                collection={collectionData}
                            />}
                        />
                        {/* <Route
                            path="/mysparks"
                            element={<MySparks
                                collection={collectionData}
                            />}
                        /> */}
                        <Route
                            path="/managemembers"
                            element={<ManageCollMembers
                                collection={collectionData}
                                membersData={membersData}
                                status={status} error={error}
                            />}
                        />
                    </Routes>
                )}
            </Fragment>
        )
    }


    if (error) {
        return (
            <div className="text-darkgrey font-elight">Something went wrong</div>
        )
    }
};



export default CollectionDetail;