import { textColourChoice } from "../../store/themesConfig";

const SurCardWrapper = (props) => {
    const bgColour = props.bg;
    const textColour = textColourChoice(bgColour)
    const hoverEnter = props.hoverEnter;
    const hoverLeave = props.hoverLeave;

    return (
        <div
            style={{'backgroundColor': `${bgColour}`, 'color': `${textColour}`, 'stroke': `${textColour}`, 'fill': `${textColour}`, 'borderColor': `${textColour}` }}
            onMouseEnter={hoverEnter}
            onMouseLeave={hoverLeave}
            className="relative flex place-content-center hover:shadow-lg transition duration-300 ease-in-out rounded-lg shadow-md">
            {props.children}
        </div>
    );
};

export default SurCardWrapper;