
// grid container for surrounding cards - currently also formatted for centre card but this is due to be removed

const Grid = (props) => {

    if (props.type === 'list') {
        return (
            <div className={`grid grid-rows-3 grid-cols-3 grid-flow-row gap-1 sm:gap-4 h-full w-full px-2 sm:px-6 2xl:px-8`}>
                {props.children}
            </div>
        );
    };

    if (props.type === 'centre') {
        return (
            <div className="absolute grid grid-rows-3 grid-cols-3 grid-flow-row gap-1 sm:gap-4 h-full w-full px-1 sm:px-7 xl:px-24 top-0">
                {props.children}
            </div>
        );
    };
};

export default Grid;