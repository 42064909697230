import { useContext, useEffect } from "react";
import ExploreCollectionResults from "../components/results-state/ExploreCollectionResults";
import useHttp from "../hooks/use-http";
import { filterCollSparks } from "../lib/api";
import SearchContext from "../store/sparkSearch-context";


const CollectionDashboard = (props) => {

    const handle = props.collection.handle;
    const searchCtx = useContext(SearchContext);
    const curSearch = searchCtx.state;

    const { sendRequest, status, data, error } = useHttp(
        filterCollSparks,
    );

    useEffect(() => {
        if (curSearch.length > 0) {
            const handleUpdateResults = setTimeout(() => {
                sendRequest({ handle: handle, input: curSearch });
            }, 500);

            return () => {
                clearTimeout(handleUpdateResults);
            };
        };
    }, [curSearch, sendRequest, handle])

    let filteredSparks = [];

    // CONDITIONALLY SPLITTING FIRST PAGE AND REMAINING PAGES OF FILTERED SPARKS RESULTS

    if (status === 'completed' && !error) {
        filteredSparks = [...data];

        if (data.length === 0) {
            filteredSparks = [];
        }
    }

    // REMOVE RESULTS IF CON SPARK FORM TEXT INPUT IS EMPTY

    if (curSearch.length === 0) {
        filteredSparks = [];
    }

    return (
        <ExploreCollectionResults
            results={filteredSparks}
            collection={props.collection}
            curSearch={curSearch}
            collHandle={handle}
            status={status}
            error={error}
        />
    )
}

export default CollectionDashboard;