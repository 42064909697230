import SparkContent from "../card-content/SparkContent";
import { useState, useEffect, useContext } from "react";

import { createConnect, deleteConnect } from "../../lib/api.js"
import useHttp from "../../hooks/use-http";
import AuthContext from "../../store/auth-context";
import SurCardWrapper from "./SurCardWrapper";

const ConSparkCard = (props) => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme
    const cardBg = theme.card;
    const [isHovering, setIsHovering] = useState(false);

    const hoverLeave = () => {
        setIsHovering(false);
    }

    const hoverEnter = () => {
        setIsHovering(true);
    }

    // CONTROLS TO CONNECT / DISCONNECT 2 SPARKS

    const data = props.data;

    const connectObject = data.connected_count.find(instance => instance.spark2 == props.centreSparkId || instance.spark1 == props.centreSparkId)

    const connectObjId = connectObject ? connectObject.id : '';

    // CONTROLS FOR CONNECTING / DISCONNECTING SURROUNDING SPARK TO / FROM CENTRE SPARK

    const [isConnected, setIsConnected] = useState(connectObject ? true : false);

    const { sendRequest, error, status } = useHttp(createConnect, true);
    const { sendRequest: deleteSendRequest } = useHttp(deleteConnect, true);

    const updateFunction = props.conSparkState.updateFunction;
    const updateFiltered = props.conSparkState.updateFiltered;

    const updateConnectState = () => {
        setIsConnected((prevState) => prevState ? false : true)
    };

    const submitConnectHandler = () => {
        updateConnectState();
        if (!isConnected) {
            const curResults = props.onResultsUpdate.state;
            const updateResults = props.onResultsUpdate.function;
            let newData = data;
            newData.connected_count.push({
                spark1: data.id,
                spark2: props.centreSparkId
            })
            let newResults = [newData, ...curResults];
            newResults.sort((a, b) => {
                return a.created_at - b.created_at;
            });
            updateResults(newResults);
            sendRequest({ spark1: props.centreSparkId, spark2: data.id });
        }
        if (isConnected) {
            const curResults = props.onResultsUpdate.state;
            const updateResults = props.onResultsUpdate.function;
            deleteSendRequest(connectObjId);
            let newResults = curResults.filter((item) => item.id !== props.data.id);
            updateResults(newResults);
        }
    };

    useEffect(() => {
        if (status === 'completed' && !error) {
            // updateFunction(props.centreSparkId);
            // updateFiltered({ handle: props.collHandle, input: props.conSparkState.input })
        }
    }, [status, error, updateFunction, props.centreSparkId, updateFiltered, props.collHandle, props.conSparkState.input])


    return (
        <SurCardWrapper bg={cardBg} hoverEnter={hoverEnter} hoverLeave={hoverLeave}>
            <SparkContent
                type='surSpark'
                data={props.data}
                collHandle={props.collHandle}
                isHovering={isHovering}
                pageReset={props.pageReset}
                centreSparkId={props.centreSparkId}
                conSparkState={props.conSparkState}
                isConnected={isConnected}
                submitConnectHandler={submitConnectHandler}
                connectObject={connectObject}
                onResultsUpdate={props.onResultsUpdate}
            />
        </SurCardWrapper>
        // <div onMouseEnter={hoverEnter} onMouseLeave={hoverLeave} className="relative flex place-content-center shadow-md hover:shadow-lg transition duration-200 ease-in-out rounded-lg bg-white">
        //     <SparkContent
        //         type='surSpark'
        //         data={props.data}
        //         collHandle={props.collHandle}
        //         isHovering={isHovering}
        //         pageReset={props.pageReset}
        //         centreSparkId={props.centreSparkId}
        //         conSparkState={props.conSparkState}
        //         isConnected={isConnected}
        //         submitConnectHandler={submitConnectHandler}
        //         connectObject={connectObject}
        //         onResultsUpdate={props.onResultsUpdate}
        //     />
        // </div>
    );
}

export default ConSparkCard;