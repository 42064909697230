import { useRef } from "react";


const RequestEmailForm = (props) => {

    const emailInputRef = useRef();

    const submitEmail = () => {
        props.onSubmit(emailInputRef.current.value);
    };

    return (
        <div className='w-full h-full flex flex-col place-content-center px-4 sm:px-6'>
            <div>
                <div className="ml-2 mb-4 py-4 text-darkgrey text-3xl font-elight">{props.title}</div>
                <div className="mb-4 md:flex md:flex-wrap md:justify-between">
                    <div className="flex flex-col mb-4 md:w-full">
                        <input className="font-elight text-lg p-3 outline-none text-darkgrey placeholder-darkgrey/70 bg-transparent border-darkgrey/50 border-b" type="email" placeholder="email" ref={emailInputRef} />
                    </div>
                </div>
                {!props.isLoading && (
                    <div onClick={submitEmail} className="mt-8 mb-3 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey hover:text-white border border-darkgrey/50 hover:border-salmon hover:scale-105 hover:bg-salmon transition ease-in-out">{props.buttonMsg}</div>
                )}
                {props.isLoading && (
                    <div className="mt-8 mb-3 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-white bg-salmon">...</div>
                )}
            </div>
        </div>
    );
}

export default RequestEmailForm;