import { useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthForm from "../components/forms/AuthForm";
import useHttp from "../hooks/use-http";
import { userLogin } from "../lib/user-api";
import AuthContext from '../store/auth-context';

const Login = () => {
    const authCtx = useContext(AuthContext);
    const { sendRequest, data, error, status } = useHttp(userLogin);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get('url')

    useEffect(() => {
        if (status === 'completed' && !error) {
            authCtx.login({ token: data.token, id: data.id, expiry: data.expiry, sub_type: data.sub_type, firestore_token: data.firestore_token });
            navigate(redirectUrl ? redirectUrl : '/home');
        }
    }, [status, navigate, authCtx, data, error])

    const loginHandler = (loginData) => {
        sendRequest(loginData);
    };

    return (
        <AuthForm type='login' isLoading={status === 'pending'} error={error} onLogin={loginHandler} />
    );
};

export default Login;