import React, { useState, useEffect, useCallback } from 'react';
import { themeSelection } from './themesConfig';

let logoutTimer;

// set the object keys here for vscode autocomplete

const AuthContext = React.createContext({
    token: '',
    userId: '',
    sub_type: '',
    sub_status: '',
    firestore_token: '',
    isLoggedIn: false,
    theme: {},
    notifications: {},
    updateNotifications: (data) => { },
    updateTheme: (data) => { },
    login: (data) => { },
    logout: () => { },
    updateSub: () => { },
    updateLogoutTimer: () => { },
});

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();

    const remainingDuration = adjExpirationTime - currentTime;

    return remainingDuration;
};

// fetch user token and id from local storage

const retrieveStoredInfo = () => {
    const storedToken = localStorage.getItem('token');
    const storedExpirationDate = localStorage.getItem('expiry');
    const storedId = localStorage.getItem('id');
    const storedSub = localStorage.getItem('sub_type');

    const remainingTime = calculateRemainingTime(storedExpirationDate);

    if (remainingTime <= 0) {
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        localStorage.removeItem('expiry');
        localStorage.removeItem('sub_type');
        return null;
    }

    return {
        storedToken: storedToken,
        expiry: remainingTime,
        storedId: storedId,
        storedSub: storedSub,
    };
};


export const AuthContextProvider = (props) => {
    const initTheme = themeSelection();
    const userData = retrieveStoredInfo();
    // need to test this eventually - pretty sure if I set the initial
    // values of these to empty strings it would fix some typing issues elsewhere...
    let initialToken;
    let initialId;
    let initialSub;
    if (userData) {
        initialToken = userData.storedToken;
        initialId = userData.storedId;
        initialSub = userData.storedSub;
    }

    const [token, setToken] = useState(initialToken);
    const [id, setId] = useState(initialId);
    const [subType, setSubType] = useState(initialSub);
    const [firestoreToken, setFirestoreToken] = useState(null);
    const [subStatus, setSubStatus] = useState('');
    const [theme, setTheme] = useState(initTheme);
    const [notifications, setNotifications] = useState({});

    const userIsLoggedIn = !!token;

    const logoutHandler = useCallback(() => {
        setToken(null);
        setId(null);
        setFirestoreToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        localStorage.removeItem('expiry');
        localStorage.removeItem('sub_type');

        if (logoutTimer) {
            clearTimeout(logoutTimer);
        }
    }, []);

    const loginHandler = (data) => {
        setToken(data.token);
        setId(data.id);
        setSubType(data.sub_type);
        setFirestoreToken(data.firestore_token);
        localStorage.setItem('token', data.token);
        localStorage.setItem('id', data.id);
        localStorage.setItem('expiry', data.expiry);
        localStorage.setItem('sub_type', data.sub_type);

        const remainingTime = calculateRemainingTime(data.expiry);

        logoutTimer = setTimeout(logoutHandler, remainingTime);
    };

    const updateSubHandler = (data) => {
        setSubType(data.sub_type)
        setSubStatus(data.sub_status)
        localStorage.setItem('sub_type', data.sub_type)
    }

    const updateTheme = (data, customCols = {}) => {
        const newTheme = themeSelection(data, customCols);
        setTheme(newTheme);
    }

    const updateLogoutTimer = () => {
            const curTime = new Date().getTime();
            const expTime = new Date(new Date().getTime() + 60 * 60 * 24 * 1000);
            const remainingDuration = expTime - curTime;
            userData.expiry = expTime;
            localStorage.setItem('expiry', expTime);
            logoutTimer = setTimeout(logoutHandler, remainingDuration);
    }

    useEffect(() => {
        if (userData) {
            logoutTimer = setTimeout(logoutHandler, userData.expiry);
        }
    }, [userData, logoutHandler]);

    const contextValue = {
        token: token,
        id: id,
        sub_type: subType,
        sub_status: subStatus,
        firestore_token: firestoreToken,
        isLoggedIn: userIsLoggedIn,
        theme: theme,
        notifications: notifications,
        updateNotifications: setNotifications,
        updateTheme: updateTheme,
        login: loginHandler,
        logout: logoutHandler,
        updateSub: updateSubHandler,
        updateLogoutTimer: updateLogoutTimer
    };

    return <AuthContext.Provider value={contextValue}>
        {props.children}
    </AuthContext.Provider>;
}

export default AuthContext;