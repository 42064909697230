import { useState } from "react";
import UpdateUserForm from "../components/forms/UpdateUserForm"
import { userDetail, updateUser, deleteUser, changeEmail, changePassword } from '../lib/user-api'
import { customerPortal } from "../lib/stripe-api";
import useHttp from "../hooks/use-http";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../store/auth-context";

const ManageAccount = () => {

    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);

    const [isVisible, setIsVisible] = useState('default');
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isPending, setIsPending] = useState(false);

    const {
        sendRequest,
        data,
        error,
        status
    } = useHttp(userDetail, true);
    const {
        sendRequest: updateReq,
        error: updateErr,
        status: updateStatus
    } = useHttp(updateUser);
    const {
        sendRequest: deleteReq,
        error: deleteErr,
        status: deleteStatus
    } = useHttp(deleteUser);
    const {
        sendRequest: chEmailReq,
        error: chEmailErr,
        status: chEmailStatus
    } = useHttp(changeEmail);
    const {
        sendRequest: chPwReq,
        error: chPwErr,
        status: chPwStatus
    } = useHttp(changePassword);
    const {
        sendRequest: subPortalReq,
        data: subPortalData,
        error: subPortalErr,
        status: subPortalStatus
    } = useHttp(customerPortal);

    
    useEffect(() => {
        sendRequest();
    }, [sendRequest]);


    const fullnameUpdate = (data) => {
        updateReq(data);
    }

    const updateEmail = (data) => {
        chEmailReq(data);
    }

    const updatePassword = (data) => {
        chPwReq(data);
    }

    const deleteAccount = (data) => {
        deleteReq(data)
    }

    const goToPortal = () => {
        subPortalReq();
    }



    useEffect(() => {
        if (updateStatus === 'completed' || deleteStatus === 'completed' || chEmailStatus === 'completed' || chPwStatus === 'completed') {
            setIsVisible('default');
            setSuccess(true);
            setIsPending(false);
        }
    }, [updateStatus, deleteStatus, chEmailStatus, chPwStatus, setIsVisible, setSuccess])

    useEffect(() => {
        if ((updateStatus === 'pending') || (deleteStatus === 'pending') || (chEmailStatus === 'pending') || (chPwStatus === 'pending')) {
            setIsPending(true);
        }
    }, [updateStatus, deleteStatus, chEmailStatus, chPwStatus, setIsPending])

    useEffect(() => {
        if ((updateErr) || (deleteErr) || (chEmailErr) || (chPwErr)) {
            setIsError(true);
        }
    }, [updateErr, deleteErr, chEmailErr, chPwErr, setIsError])

    useEffect(() => {
        if (deleteStatus === 'completed' && !deleteErr) {
            authCtx.logout();
            navigate('/auth/login');
        }
    }, [deleteStatus, deleteErr, navigate, authCtx])

    useEffect(() => {
        if (subPortalStatus === 'completed' && !subPortalErr) {
            window.location.replace(subPortalData.portal_link)
        }
    })



    if (status === 'pending') {
        return <div></div>
    }

    if (error) {
        return <div></div>
    }

    if (status === 'completed' && !error) {
        return (
            <UpdateUserForm
                success={{ state: success, function: setSuccess }}
                isError={{ state: isError, function: setIsError }}
                userData={data}
                visibility={{ state: isVisible, function: setIsVisible }}
                fullnameUpdate={fullnameUpdate}
                updateEmail={updateEmail}
                updatePassword={updatePassword}
                deleteAccount={deleteAccount}
                goToPortal={goToPortal}
                isLoading={isPending}
            />
        )
    }
}

export default ManageAccount;