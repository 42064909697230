import { Fragment, useEffect, useRef, useState } from "react";
import { ReactComponent as BellIcon } from "../../icons/bell.svg";
import { ReactComponent as CircleIcon } from "../../icons/circle.svg";
import { usePrevious } from "../../store/notification-utils";

const NotificationBell = (props) => {
    const isInitialMount = useRef(true);

    const values = props.values;
    const prevValues = usePrevious({ values: values });

    const themePrimary = props.themePrimary;
    const textCol = props.textCol;

    const [hover, setHover] = useState(false);
    const [isNewNot, setIsNewNot] = useState(false);
    const [newNotis, setNewNotis] = useState(false);

    const hoverOff = () => {
        setHover(false);
    }
    const hoverOn = () => {
        setHover(true);
    }

    const alertNewNot = () => {
        setIsNewNot(true);
        setTimeout(() => {
            setIsNewNot(false)
        }, 1000);
    }

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (prevValues.values) {
                const prevFirst = prevValues ? prevValues.values.docs[0] : null;
                const newFirst = values ? values.docs[0] : null;
                const prevId = prevFirst ? prevFirst.data().spark_id : null;
                const newId = newFirst ? newFirst.data().spark_id : null;
                if (prevId !== newId) {
                    alertNewNot()
                }
            }
        }
    }, [values, prevValues])

    useEffect(() => {
        if (values) {
            const unreadNotis = values.docs.filter((doc) => {
                const docData = doc.data()
                const readUsers = docData.read_by.filter((user) => {
                    return user == localStorage.getItem('id')
                })
                return readUsers.length === 0;
            })
            if (unreadNotis.length > 0) {
                setNewNotis(true);
            } else {
                setNewNotis(false);
            }
        }
    }, [values, setNewNotis])


    return (
        <Fragment>
            <BellIcon onMouseEnter={hoverOn} onMouseLeave={hoverOff} style={{ 'stroke': `${hover ? themePrimary : textCol}` }} className='h-8 w-8 stroke-1 relative' />
            {newNotis && (
                <Fragment>
                    <CircleIcon onMouseEnter={hoverOn} onMouseLeave={hoverOff} style={{ 'fill': `${themePrimary}` }} className="absolute w-4 h-4 top-0 right-1" />
                    {isNewNot && (
                        <CircleIcon onMouseEnter={hoverOn} onMouseLeave={hoverOff} style={{ 'fill': `${themePrimary}` }} className="absolute w-4 h-4 top-0 right-1 animate-ping" />
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

export default NotificationBell;