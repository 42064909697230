import { Fragment, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
// import AuthForm from "../components/forms/AuthForm";
import FormContainer from "../components/forms/FormContainer";
import Navigation from "../components/layout/Navigation";
import AuthContext from "../store/auth-context";

const Auth = (props) => {
    const authCtx = useContext(AuthContext);
    const setTheme = authCtx.updateTheme;

    useEffect(() => {
        setTheme('DF');
    })

    useEffect(() => {
        document.title = "Clusta.";
    })

    return (
        <Fragment>
            <Navigation state='myGroups' />
            <FormContainer>
                <Outlet />
            </FormContainer>
        </Fragment>
    );
};

export default Auth;