import { useContext, useState } from "react";
import HeadingContent from "../card-content/HeadingContent";
import AuthContext from "../../store/auth-context";
import CentreCardWrapper from "./CentreCardWrapper";


const HeadingCard = (props) => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme
    const cardBg = theme.highlight;
    const centreHighlight = "#666";

    const [isHovering, setIsHovering] = useState(false);

    const hoverLeave = () => {
        setIsHovering(false);
    }

    const hoverEnter = () => {
        setIsHovering(true);
    }

    return (
        <CentreCardWrapper bg={cardBg} highlight={centreHighlight} hoverEnter={hoverEnter} hoverLeave={hoverLeave}>
            <HeadingContent
                    isHovering={isHovering}
                    content={props.type}
                    collection={props.collection} />
        </CentreCardWrapper>
    );
}

export default HeadingCard;