import { useParams } from "react-router-dom"
import CollectionDetail from "../pages/CollectionDetail"
import { MemberContextProvider } from "./isMember-context"
import { SearchContextProvider } from "./sparkSearch-context";

const CollContext = () => {
    const params = useParams();
    const collectionHandle = params.handle;

    return (
        <MemberContextProvider>
            <SearchContextProvider>
                <CollectionDetail handle={collectionHandle} />
            </SearchContextProvider>
        </MemberContextProvider>
    )
}

export default CollContext;