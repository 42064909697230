import { Fragment } from "react";
import { textColourChoice } from "../../store/themesConfig";

const CentreCardWrapper = (props) => {
    const outlineColour = props.highlight;
    const bgColour = props.bg;
    const textColour = textColourChoice(bgColour)
    const hoverEnter = props.hoverEnter;
    const hoverLeave = props.hoverLeave;

    return (
        <Fragment>
            <div
                style={{'backgroundColor': `${outlineColour}`}}
                className="absolute left-0 right-0 mx-auto w-[7.4rem] sm:w-auto sm:mx-[14.2rem] md:mx-[17rem] lg:mx-[22.2rem] xl:mx-[27.5rem] 2xl:mx-[33rem] h-[33%] sm:h-[31.5%] top-[33.5%] sm:top-[34.2%] blur-sm opacity-90" />
            <div
                style={{'backgroundColor': `${bgColour}`, 'color': `${textColour}`, 'stroke': `${textColour}`, 'fill': `${textColour}`}}
                onMouseEnter={hoverEnter}
                onMouseLeave={hoverLeave}
                className="absolute left-0 right-0 mx-auto w-[7.4rem] sm:w-auto sm:mx-[14.2rem] md:mx-[17rem] lg:mx-[22.2rem] xl:mx-[27.5rem] 2xl:mx-[33rem] h-[33%] sm:h-[31.5%] top-[33.5%] sm:top-[34.2%] flex place-content-center rounded-lg ">
                {props.children}
            </div>
        </Fragment>
    );
}

export default CentreCardWrapper;