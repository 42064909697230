// https://fusejs.io
import Fuse from 'fuse.js'

// Split array of cards into sets of 8
export function splitGridResults(results) {
    let splitCards = [];

    for (let i = 0; i < results.length; i += 8) {
        splitCards.push(results.slice(i, i + 8));
    }

    return splitCards;
}

// Sort connected sparks surrounding centre spark in reverse-chronological order
export function surSparksSort(sparks) {
    sparks.sort((a, b) => {
        const keyA = new Date(a.created_at);
        const keyB = new Date(b.created_at);

        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
    });

    return sparks;
}

// Randomise the order of surrounding sparks 
export function randomiseSparks(sparks) {
    let randomSparks = sparks;
    for (let i = randomSparks.length -1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [randomSparks[i], randomSparks[j]] = [randomSparks[j], randomSparks[i]];
    }
    return randomSparks;
};

// Handler function to configure sparks connected to centre (sorted and split into grids)
export function connectedConfig(sparks, randomise=false) {
    const sorted = randomise ? randomiseSparks(sparks) : surSparksSort(sparks);
    const finished = splitGridResults(sorted);
    return finished;
}

// Split array of sparks into sets of 8 EXCEPT first set, which is 7, 
// to account for new con spark form carf
// ALSO filter results to remove centre spark from them - don't connect spark to itself!
export function toConnectResults(data) {
    const filteredSparks = data.results.filter((item) => item.id !== data.centre.id);

    let splitResults = [];

    for (let i = 0; i < filteredSparks.length; i += 8) {
        if (i === 0) {
            splitResults.push(filteredSparks.slice(i, i + 7));
        }
        splitResults.push(filteredSparks.slice(i, i + 8));
    }
    return splitResults;
}

// Change the 'page' of results
export function changePage(data) {
    const curPage = data.curPage;
    const results = data.results;
    let newPage;
    if (data.direction === 'up') {
        newPage = curPage >= (results.length - 1) ? 0 : curPage + 1;
    }
    if (data.direction === 'down') {
        newPage = curPage === 0 ? (results.length - 1) : curPage - 1;
    }
    return newPage;
}

// Filter current set of results
export function searchResults(data, key = 'text') {
    const fuse = new Fuse(data.results, { keys: [key] });
    const results = fuse.search(data.input);
    const array = results.map((res) => res.item);
    return array;
}