import Results from "../components/layout/Results";

import { Fragment, useEffect, useContext, useState } from "react";
import { getPinnedSparks } from "../lib/api";

import AuthContext from "../store/auth-context";

import useHttp from "../hooks/use-http";
import { searchResults } from "../hooks/results-format";
import SearchContext from "../store/sparkSearch-context";
import MemberContext from "../store/isMember-context";

const CollectionPinned = (props) => {
    const authCtx = useContext(AuthContext);
    const memberCtx = useContext(MemberContext);
    const pubCtx = memberCtx.public;
    const token = authCtx.token;

    const handle = props.collection.handle;
    const data = props.collection;

    const searchCtx = useContext(SearchContext);
    const search = searchCtx;
    const curSearchInput = search.state;

    const [resultsState, setResultsState] = useState([]);

    const { sendRequest, status, data: pinnedResults, error } = useHttp(
        getPinnedSparks,
        true
    );

    useEffect(() => {
        sendRequest(handle, token);
    }, [sendRequest, handle, token]);

    useEffect(() => {
        if (status === 'completed' && !error) {
            if (pubCtx.state) {
                setResultsState(pinnedResults.filter((spark) => spark.public === true))
            } else {
                setResultsState(pinnedResults);
            }
        };
    }, [status, error, setResultsState, pinnedResults, pubCtx.state]);

    // fuseresults is dependent on a parent component's state (search-context) so doesn't need to be wrapped in useEffect hook

    let fuseResults = [];

    if (curSearchInput.length > 0) {
        const newResults = searchResults({
            results: resultsState,
            input: curSearchInput
        });
        fuseResults = [...newResults];
    };

    if (status === 'pending') {
        return <div></div>
    }

    if (error) {
        return <div></div>
    }

    if (status === 'completed' && !error) {
        return (
            <Fragment>
                <Results
                    type={'pinned'}
                    collHandle={handle}
                    collData={data}
                    results={curSearchInput.length === 0 ? resultsState : fuseResults}
                    status={status}
                    error={error}
                    onResultsUpdate={{ state: resultsState, function: setResultsState }}
                />
            </Fragment>
        );
    }

};

export default CollectionPinned;

