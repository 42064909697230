import { useState, useEffect, Fragment, useContext } from "react";
import { useRef } from "react";
import { ReactComponent as LeftArrow } from '../../icons/arrow-left.svg'
import { ReactComponent as InfinityLogo } from '../../icons/ClustaInfinity2.svg'
import { Link } from "react-router-dom";
import AuthContext from "../../store/auth-context";

const UpdateUserForm = (props) => {

    const authCtx = useContext(AuthContext);

    const deleteAccountPwRef = useRef();
    const updatePwPwRef = useRef();
    const updateEmailPwRef = useRef();

    const isVisible = props.visibility.state;
    const setIsVisible = props.visibility.function;

    const [firstName, setFirstName] = useState(props.userData.first_name);
    const [lastName, setLastName] = useState(props.userData.last_name);

    const [newEmail, setNewEmail] = useState('');
    const [reNewEmail, setReNewEmail] = useState('');
    const [newEmailValid, setNewEmailValid] = useState(newEmail === reNewEmail);

    const [newPw, setNewPw] = useState('');
    const [reNewPw, setReNewPw] = useState('');
    const [newPwValid, setNewPwValid] = useState(newPw === reNewPw);

    const [marketingConsent, setMarketingConsent] = useState(props.userData.marketing_consent)

    const updateFirstName = (e) => {
        setFirstName(e.target.value);
    }

    const updateLastName = (e) => {
        setLastName(e.target.value);
    }

    const updateNewEmail = (e) => {
        setNewEmail(e.target.value);
    }
    const updateReNewEmail = (e) => {
        setReNewEmail(e.target.value);
    }

    const updateNewPw = (e) => {
        setNewPw(e.target.value);
    }

    const updateReNewPw = (e) => {
        setReNewPw(e.target.value);
    }

    const showEmailUpdate = () => {
        setIsVisible('emailUpdate');
    };

    const showPwUpdate = () => {
        setIsVisible('pwUpdate');
    };

    const showConfirmDelete = () => {
        setIsVisible('confirmDelete');
    }

    const showDefault = () => {
        setIsVisible('default');
    };

    const onUpdateFullName = () => {
        props.fullnameUpdate({
            first_name: firstName,
            last_name: lastName,
            marketing_consent: marketingConsent,
        });
    }

    const onUpdateMarConsent = () => {
        setMarketingConsent(!marketingConsent);
    }

    const onDelete = () => {
        props.deleteAccount({
            current_password: deleteAccountPwRef.current.value
        });
    }

    const onUpdatePw = () => {
        if (newPwValid) {
            props.updatePassword({
                new_password: newPw,
                current_password: updatePwPwRef.current.value
            });
        }
    }

    const onUpdateEmail = () => {
        if (newEmailValid) {
            props.updateEmail({
                new_email: newEmail,
                current_password: updateEmailPwRef.current.value
            });
        }
    }

    const goToPortal = () => {
        props.goToPortal();
    };

    useEffect(() => {
        const pwIdentifier = setTimeout(() => {
            setNewPwValid(
                newPw === reNewPw
            );
        }, 500);

        return () => {
            clearTimeout(pwIdentifier);
        };
    }, [newPw, reNewPw, setNewPwValid]);

    useEffect(() => {
        const emailIdentifier = setTimeout(() => {
            setNewEmailValid(
                newEmail === reNewEmail
            );
        }, 500);

        return () => {
            clearTimeout(emailIdentifier);
        };
    }, [newEmail, reNewEmail, setNewEmailValid]);

    const newPwFormClass = newPwValid ? "font-elight md:text-lg p-3 outline-none placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" : "font-elight md:text-lg p-3 outline-none text-darkgrey bg-transparent border-rose-500 border-b";
    const newEmailFormClass = newEmailValid ? "font-elight md:text-lg p-3 outline-none placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" : "font-elight md:text-lg p-3 outline-none text-darkgrey bg-transparent border-rose-500 border-b";

    const defaultDisplay = (
        <Fragment>
            <div className="pb-4 text-darkgrey text-3xl font-elight">Manage your Account</div>
            <div className="mb-4 md:flex md:flex-wrap md:justify-between">
                <div className="flex flex-col w-full">
                    {(authCtx.sub_type === 'SOL' && authCtx.sub_status === 'past_due') && (
                        <div className="flex flex-col bg-salmon text-white font-light rounded-md p-2">
                            <div className="text-lg">Payment failure</div>
                            <div className="text-sm">Please select 'manage subscription' below to update your payment information as soon as possible.</div>
                        </div>
                    )}
                    <div className="flex flex-row justify-between bg-salmon/10 rounded-md p-2 w-full">
                        <div className="h-10">
                            <InfinityLogo className="h-10 text-salmon" />
                        </div>
                        {(authCtx.sub_type === 'SOL') && (
                            <div onClick={goToPortal} className="font-light text-darkgrey hover:text-white shadow-md bg-white/60 hover:scale-105 hover:border-salmon hover:bg-salmon rounded-md py-2 px-6 cursor-pointer transition ease-in-out">
                                Manage subscription
                            </div>
                        )
                        }
                        {authCtx.sub_type === 'LIT' && (
                            <Link to='/auth/upgrade' className="font-light text-darkgrey hover:text-white shadow-md bg-white/60 hover:scale-105 hover:border-salmon hover:bg-salmon rounded-md py-2 px-6 cursor-pointer transition ease-in-out">
                                Upgrade to Limitless
                            </Link>
                        )}
                        {authCtx.sub_type === 'LUN' && (
                            <div className="font-light text-darkgrey shadow-md bg-white/60 rounded-md py-2 px-6">
                                Lifetime member
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-col mb-4 md:w-1/2">
                    <input className="p-3 md:text-lg font-elight outline-none md:mr-2 placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" type="text" placeholder="First Name" value={firstName} onChange={updateFirstName} />
                </div>
                <div className="flex flex-col mb-2 md:w-1/2">
                    <input className="p-3 md:text-lg font-elight outline-none md:ml-2 placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" type="text" placeholder="Last Name" value={lastName} onChange={updateLastName} />
                </div>
                <div className="flex flex-row w-full gap-2 mb-2">
                    <input type="checkbox" checked={marketingConsent} onChange={onUpdateMarConsent} />
                    <div className="font-elight text-sm">Receive updates and communications from Clusta.</div>
                </div>
                {!props.isLoading &&
                    <div onClick={onUpdateFullName} className="mb-4 w-full rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey border border-darkgrey/50 hover:scale-105 hover:border-salmon hover:bg-salmon hover:text-white transition duration-75 ease-in-out">Update basic info</div>
                }
                {props.isLoading && (
                    <div className="mb-4 w-full rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-white bg-salmon">...</div>
                )}
                <div className="flex flex-col mb-4 w-full">
                    <input className="font-elight text-lg p-3 outline-none placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" type="email" placeholder="email" value={props.userData.email} readOnly />
                </div>
                <div onClick={showEmailUpdate} className="mb-8 w-full rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey hover:text-white border border-darkgrey/50 hover:scale-105 hover:border-salmon hover:bg-salmon transition ease-in-out">Update Email</div>
                <div onClick={showPwUpdate} className="md:w-5/12 mb-2 md:mb-0 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey hover:text-white border border-darkgrey/50 hover:scale-105 hover:border-salmon hover:bg-salmon transition ease-in-out">Change Password</div>
                <div onClick={showConfirmDelete} className="md:w-5/12 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey hover:text-white border border-darkgrey/50 hover:scale-105 hover:border-salmon hover:bg-salmon transition ease-in-out">Delete Account</div>
            </div>
            <div className="my-2 w-full flex flex-row gap-4 place-content-center">
                <Link to={`/home`} className="font-light text-darkgrey hover:text-white border border-darkgrey/50 hover:scale-105 hover:border-salmon hover:bg-salmon rounded-md py-2 px-6 cursor-pointer flex flex-row">
                    {/* <div className="text-white font-light mr-2">Finish</div>
                    <Tick className="h-6 w-6 stroke-white" /> */}
                    Finish
                </Link>
            </div>
        </Fragment>
    );

    const updateEmailDisplay = (
        <div>
            <div className="ml-2 py-4 text-darkgrey text-3xl font-elight">Change email</div>
            <div className="mb-4 md:flex md:flex-wrap md:justify-between">
                <div className="flex flex-col mb-4 md:w-full">
                    <input className={newEmailFormClass} type="email" placeholder="new email" value={newEmail} onChange={updateNewEmail} />
                </div>
                <div className="flex flex-col mb-4 md:w-full">
                    <input className={newEmailFormClass} type="email" placeholder="new email (again)" value={reNewEmail} onChange={updateReNewEmail} />
                </div>
                <div className="flex flex-col mb-6 md:w-full">
                    <input className="font-elight text-lg p-3 outline-none placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" type="password" placeholder="password" ref={updateEmailPwRef} />
                </div>
            </div>
            {!props.isLoading && (
                <div onClick={onUpdateEmail} className="mt-5 mb-3 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey hover:text-white border border-darkgrey/50 hover:scale-105 hover:border-salmon hover:bg-salmon transition ease-in-out">Update email</div>
            )}
            {props.isLoading && (
                <div className="mt-5 mb-3 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-white bg-salmon">...</div>
            )}
        </div>
    );

    const updatePasswordDisplay = (
        <div>
            <div className="ml-2 py-4 text-darkgrey text-3xl font-elight">Change password</div>
            <div className="mb-4 md:flex md:flex-wrap md:justify-between">
                <div className="flex flex-col mb-4 md:w-full">
                    <input className="font-elight text-lg p-3 outline-none text-darkgrey placeholder-darkgrey/70 bg-transparent border-darkgrey/50 border-b" type="password" placeholder="new password" value={newPw} onChange={updateNewPw} />
                </div>
                <div className="flex flex-col mb-4 md:w-full">
                    <input className={newPwFormClass} type="password" placeholder="new password (again)" value={reNewPw} onChange={updateReNewPw} />
                </div>
                <div className="flex flex-col mb-6 md:w-full">
                    <input className={newPwFormClass} type="password" placeholder="current password" ref={updatePwPwRef} />
                </div>
            </div>
            {!props.isLoading && (
                <div onClick={onUpdatePw} className="mt-5 mb-3 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey hover:text-white border border-darkgrey/50 hover:scale-105 hover:border-salmon hover:bg-salmon transition ease-in-out">Update Password</div>
            )}
            {props.isLoading && (
                <div className="mt-5 mb-3 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-white bg-salmon">...</div>
            )}
        </div>
    );

    const confirmDeleteDisplay = (
        <div>
            <div className="ml-2 py-4 text-darkgrey text-3xl font-elight">Are you sure?</div>
            <div className="ml-2 mb-4 flex place-content-center text-darkgrey text-base font-elight">All sparks and collections associated with this account will be deleted. You will not be able to reverse this action.</div>
            <div className="mb-4 md:flex md:flex-wrap md:justify-between">
                <div className="flex flex-col mb-6 md:w-full">
                    <input className="font-elight text-lg p-3 outline-none placeholder-darkgrey/70 text-darkgrey bg-transparent border-darkgrey/50 border-b" type="password" placeholder="password" ref={deleteAccountPwRef} />
                </div>
            </div>
            {!props.isLoading && (
                <div onClick={onDelete} className="mt-10 mb-3 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey hover:text-white border border-darkgrey/50 hover:scale-105 hover:border-salmon hover:bg-salmon transition ease-in-out">Delete Account</div>
            )}
            {props.isLoading && (
                <div className="mt-10 mb-3 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-white bg-salmon">...</div>
            )}
        </div>
    );

    return (
        <Fragment>
            <div className='w-full h-full flex flex-col px-4 py-8 sm:px-6'>
                {props.success.state && (
                    <div className="absolute top-0 left-0 w-full">
                        <div className="mx-20 flex flex-row place-content-center gap-5 rounded-md p-4 bg-salmon">
                            <div className="font-elight text-sm text-white">Update Successful</div>
                            <div onClick={() => { props.success.function(false) }} className="font-elight underline text-sm cursor-pointer text-white/80">close</div>
                        </div>
                    </div>
                )}
                {props.isError.state && (
                    <div className="absolute -top-0 left-0 w-full">
                        <div className="mx-20 flex flex-row place-content-center gap-5 rounded-md  p-4 bg-salmon">
                            <div className="font-elight text-sm text-white">Unable to update</div>
                            <div onClick={() => { props.isError.function(false) }} className="font-elight underline text-sm cursor-pointer text-white/80">close</div>
                        </div>
                    </div>
                )}
                {isVisible !== 'default' && (
                    <LeftArrow onClick={showDefault} className='h-7 w-7 stroke-darkgrey/80 cursor-pointer hover:stroke-salmon absolute top-3 left-3' />
                )}
                {isVisible === 'default' && (
                    defaultDisplay
                )}
                {isVisible === 'emailUpdate' && (
                    updateEmailDisplay
                )}
                {isVisible === 'pwUpdate' && (
                    updatePasswordDisplay
                )}
                {isVisible === 'confirmDelete' && (
                    confirmDeleteDisplay
                )}
            </div>
        </Fragment>
    );
}

export default UpdateUserForm;