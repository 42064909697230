import { ReactComponent as PinIcon } from "../../icons/pin2.svg";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as InfinityLogo } from "../../icons/ClustaInfinity2.svg";

import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";

const IntroContent = (props) => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme;
    const themePrimary = theme.highlight;

    const navigate = useNavigate();

    const [isPlusHovering, setIsPlusHovering] = useState(false);
    const [isBigPlusHovering, setIsBigPlusHovering] = useState(false);
    const [colour, setColour] = useState('inherit');

    const hoverLeave = (type) => {
        setColour('inherit')
        if (type === 'plus') {
            setIsPlusHovering(false);
        }
        if (type === 'bigPlus') {
            setIsBigPlusHovering(false);
        }
    }

    const hoverEnter = (type) => {
        if (type === 'plus') {
            setIsPlusHovering(true);
            setColour(themePrimary)
        }
        if (type === 'bigPlus') {
            setIsBigPlusHovering(true);
            setColour(themePrimary)
        }
    }

    const style = "w-4 h-4";
    const hoverStyle = "w-4 h-4"

    if (props.type === 'CollPinned') {
        return (
            <div className="flex flex-col w-full place-content-center mx-4">
                <div
                    className="p-3 flex flex-row cursor-default border-b border-inherit"
                >
                    <PinIcon className="w-4 h-4" />
                    <div className="font-elight text-[0.7rem] sm:text-sm ml-3">
                        Pinned sparks will appear here
                    </div>
                </div>
                <Link
                    to={`/${props.data.handle}/newspark`}
                    onMouseEnter={() => hoverEnter('plus')}
                    onMouseLeave={() => hoverLeave('plus')}
                    className="p-3 flex flex-row"
                >
                    <PlusIcon style={{'stroke': `${colour}`}} className={isPlusHovering ? hoverStyle : style} />
                    <div className="font-elight text-[0.7rem] sm:text-sm ml-3">
                        Create your first spark
                    </div>
                </Link>
            </div>
        )
    }
    
    if ((props.type === 'SparkDetail') || (props.type === 'MyColls')) {
        const clickLink = props.type === 'SparkDetail' ? props.data.handler : () => navigate(`/newcollection`);
        const text = props.type === 'SparkDetail' ? 'Add a connected spark' : 'Create your first collection';
        const bigStyle = "w-16 h-16"
        const bigHoverStyle = "w-16 h-16"
        return (
            <div
                onClick={clickLink}
                onMouseEnter={() => hoverEnter('bigPlus')}
                onMouseLeave={() => hoverLeave('bigPlus')}
                className="flex flex-col w-full place-content-center cursor-pointer"
            >
                <div className="w-full flex place-content-center">
                    <PlusIcon style={{'stroke': `${colour}`}} className={isBigPlusHovering ? bigHoverStyle : bigStyle} />
                </div>
                <div style={{'color': `inherit`}} className="font-elight text-sm text-darkgrey w-full mt-2 text-center">
                    {text}
                </div>
            </div>
        )
    }

    if (props.type === 'infinity') {
        const clickLink = () => navigate(`/auth/upgrade`);
        const text = 'Upgrade to infinity.';
        const bigStyle = "w-64 h-16 text-salmon stroke-salmon"
        return (
            <div
                onClick={clickLink}
                onMouseEnter={() => hoverEnter('bigPlus')}
                onMouseLeave={() => hoverLeave('bigPlus')}
                className="flex flex-col w-full place-content-center bg-salmon/20 rounded-md cursor-pointer"
            >
                <div className="w-full flex place-content-center">
                    <InfinityLogo className={bigStyle} />
                </div>
                <div style={{'color': `black`}} className="font-elight text-sm text-darkgrey w-full mt-2 text-center">
                    {text}
                </div>
            </div>
        )
    }

    if (props.type === 'brightSparks') {
        const clickLink = () => navigate(`/sparks`);
        const text = 'Inspiration for collections.';
        const bigStyle = "w-64 h-16 text-darkgrey text-lg md:text-2xl font-light text-center w-full py-5"
        return (
            <div
                onClick={clickLink}
                onMouseEnter={() => hoverEnter('bigPlus')}
                onMouseLeave={() => hoverLeave('bigPlus')}
                className="flex flex-col w-full place-content-center bg-[#FFC2D9]/60 rounded-md cursor-pointer"
            >
                <div className="relative w-full flex place-content-center">
                    <div className={bigStyle}>✨ Bright Sparks ✨</div>
                </div>
                <div style={{'color': `black`}} className="font-elight text-sm text-darkgrey w-full mt-2 text-center">
                    {text}
                </div>
            </div>
        )
    }

}

export default IntroContent;