import { useEffect, useState } from "react";
import { PopoverPicker } from "../../layout/PopoverPicker";
import { isValidHexCode } from "../../../store/themesConfig";

const CustomThemeForm = (props) => {
    const colours = props.colours;

    const [highlightError, setHighlightError] = useState(false);
    const [cardError, setCardError] = useState(false);
    const [bgError, setBgError] = useState(false);

    useEffect(() => {
        const checkHexErrors = setTimeout(() => {
            const highlightValid = isValidHexCode(colours.highlight);
            const cardValid = isValidHexCode(colours.card);
            const bgValid = isValidHexCode(colours.bg);
            if (!highlightValid) {
                setHighlightError(true)
            }
            if (highlightValid) {
                setHighlightError(false)
            }
            if (!cardValid) {
                setCardError(true)
            }
            if (cardValid) {
                setCardError(false)
            }
            if (!bgValid) {
                setBgError(true)
            }
            if (bgValid) {
                setBgError(false)
            }
        }, 500);

        return () => {
            clearTimeout(checkHexErrors);
        };
    }, [colours.highlight, colours.card, colours.bg])

    // This function checks if there's a hashtag at the start of a hex code, and removes it if so
    // I did this because colour picker always sends hashtag, and easier to handle without it
    // it also checks if the hex is an object or string, dependent on if colour is chosen from picker or written in input field
    const removeHashtag = (hex) => {
        let val = hex;
        if (typeof hex === 'object') {
            val = val.target.value;
        }
        const firstChar = val[0];
        if (firstChar === '#') {
            return val.slice(1);
        } else {
            return val;
        }
    }

    // These functions handle updating custom theme colours
    const updateHighlight = (e) => {
        const formattedVal = removeHashtag(e);
        props.setCsHighlight(formattedVal)
    };
    const updateBg = (e) => {
        const formattedVal = removeHashtag(e);
        props.setCsBg(formattedVal);
    };
    const updateCard = (e) => {
        const formattedVal = removeHashtag(e);
        props.setCsCard(formattedVal)
    };

    const inputStyle = "p-2 outline-none border w-full font-elight";
    const labelStyle = "font-elight";
    const miniCardStyle = "rounded-md"
    const errorStyle = `${labelStyle} text-red-500`

    return (
        <div className="flex flex-col md:flex-row mt-6 gap-4">
            <div className="flex flex-col w-full md:w-1/2">
                <div className="flex flex-col gap-1">
                    <div className="flex flex-row gap-2">
                        <label htmlFor="bg" className={labelStyle}>Background</label>
                        {bgError && (
                            <div className={errorStyle}>Invalid hex code</div>
                        )}
                    </div>
                    <div className="flex flex-row">
                        <PopoverPicker color={colours.bg} onChange={updateBg} />
                        <input className={inputStyle} type="text" id="bg" name="bg" placeholder="Enter a valid hex code..." value={colours.bg} onChange={updateBg} />
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-row gap-2">
                        <label htmlFor="card" className={labelStyle}>Card</label>
                        {cardError && (
                            <div className={errorStyle}>Invalid hex code</div>
                        )}
                    </div>
                    <div className="flex flex-row">
                        <PopoverPicker color={colours.card} onChange={updateCard} />
                        <input className={inputStyle} type="text" id="card" name="card" placeholder="Enter a valid hex code..." value={colours.card} onChange={updateCard} />
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-row gap-2">
                        <label htmlFor="highlight" className={labelStyle}>Highlight</label>
                        {highlightError && (
                            <div className={errorStyle}>Invalid hex code</div>
                        )}
                    </div>
                    <div className="flex flex-row">
                        <PopoverPicker color={colours.highlight} onChange={updateHighlight} />
                        <input className={inputStyle} type="text" id="highlight" name="highlight" placeholder="Enter a valid hex code..." value={colours.highlight} onChange={updateHighlight} />
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2">
                <div style={{ 'background': `#${colours.bg}` }} className="relative rounded-md w-full h-full p-2">
                    <div style={{ 'background': `#${colours.highlight}` }} className="rounded-full absolute -top-3 -left-3 w-7 h-7" />
                    <grid className="grid grid-rows-3 grid-cols-3 grid-flow-row gap-2 h-full w-full p-2 aspect-video md:aspect-auto">
                        <div className={miniCardStyle} style={{ 'background': `#${colours.card}` }}></div>
                        <div className={miniCardStyle} style={{ 'background': `#${colours.card}` }}></div>
                        <div className={miniCardStyle} style={{ 'background': `#${colours.card}` }}></div>
                        <div className={miniCardStyle} style={{ 'background': `#${colours.card}` }}></div>
                        <div className={miniCardStyle} style={{ 'background': `#${colours.card}` }}></div>
                        <div className={miniCardStyle} style={{ 'background': `#${colours.card}` }}></div>
                        <div className={miniCardStyle} style={{ 'background': `#${colours.card}` }}></div>
                        <div className={miniCardStyle} style={{ 'background': `#${colours.card}` }}></div>
                        <div className={miniCardStyle} style={{ 'background': `#${colours.card}` }}></div>
                    </grid>
                </div>
            </div>
        </div>
    )
}

export default CustomThemeForm;