import { textColourChoice, themeSelection } from "../../store/themesConfig"
import { ReactComponent as CircleIcon } from "../../icons/circle.svg";
import { ReactComponent as CrossIcon } from "../../icons/x.svg";
import { ReactComponent as PuffIcon } from "../../icons/puff.svg";
import { useNavigate } from "react-router-dom";
import { notificationRead, notificationDelete } from "../../lib/api";
import timeAgo from "../../store/notification-utils";
import { useEffect, useState } from "react";
import useHttp from "../../hooks/use-http";


const Notification = (props) => {
    const navigate = useNavigate()

    // Check if user id is in list of read_by user ids
    const filterForUser = props.data.read_by.filter((user) => {
        return user === localStorage.getItem('id')
    })
    // const isRead = filterForUser.length > 0;
    const [isHovering, setIsHovering] = useState(false);
    const [isRead, setIsRead] = useState(false);
    const [isClickedRead, setIsClickedRead] = useState(false);

    const hoverOn = () => {
        setIsHovering(true);
    }

    const hoverOff = () => {
        setIsHovering(false);
    }

    useEffect(() => {
        setIsRead(filterForUser.length > 0)
    }, [setIsRead, filterForUser])

    const updateToRead = () => {
        if (!isRead) {
            notificationRead(props.data.spark_id);
            setIsClickedRead(true);
        }
    };

    const { sendRequest, data, error, status } = useHttp(notificationDelete)

    const deleteNotification = () => {
        sendRequest(props.data.spark_id)
    }

    // Navigate to spark detail view - mark noti as read through backend and hide noti modal
    const goToSpark = () => {
        if (!isRead) {
            notificationRead(props.data.spark_id);
        }
        props.toggleModal();
        // GET request to server to set as read
        navigate(`/${props.data.collection.handle}/spark/${props.data.spark_id}`)
    }

    const theme = props.data.collection.theme;
    const customTheme = props.data.collection.custom_theme;
    const themeConfig = themeSelection(theme, customTheme)
    const bgTextCol = textColourChoice(themeConfig.bg);
    const cardTextCol = textColourChoice(themeConfig.card)
    const highlightTextCol = textColourChoice(themeConfig.highlight)

    return (
        <div onMouseOver={hoverOn} onMouseLeave={hoverOff} className="flex flex-row gap-2 relative">
            {isHovering && (
                <div className="absolute top-0 right-2 bottom-0 z-50 grid place-content-center">
                    <div style={{ 'background': `${themeConfig.highlight}` }} onClick={deleteNotification} className="rounded-full p-2 w-full hover:scale-105 cursor-pointer transition-all ease-in-out">
                        {(status !== 'pending') && (
                            <CrossIcon className="w-5 h-5" style={{ 'stroke': `${highlightTextCol}` }} />
                        )}
                        {(status === 'pending') && (
                            <PuffIcon className="w-5 h-5" style={{ 'stroke': `${highlightTextCol}` }} />
                        )}
                    </div>
                </div>
            )}
            <div className="grid place-content-center w-5">
                <CircleIcon onClick={updateToRead} style={{ 'fill': `${(isRead || isClickedRead) ? "#C0C0C0" : "#FF6261"}`, 'stroke': `${(isRead || isClickedRead) ? "#C0C0C0" : "#FF6261"}` }} className="w-full cursor-pointer" />
            </div>
            <div className="relative w-full cursor-pointer" onClick={goToSpark}>
                <div style={{ 'background': `${themeConfig.highlight}` }} className="-z-10 rounded-md absolute w-full h-full"></div>
                <div style={{ 'background': `${themeConfig.bg}` }} className={`p-2 rounded-md hover:-translate-y-1 transition-all ease-in-out flex flex-col gap-1`}>
                    <div style={{ 'color': `${bgTextCol}` }} className="flex justify-between">
                        <div className="font-light text-sm">{props.data.collection.displayname}</div>
                        <div className="text-xs font-elight opacity-80 p-1">{timeAgo(props.data.created_at)}</div>
                    </div>
                    <div style={{ 'color': `${cardTextCol}`, 'background': `${themeConfig.card}` }} className="p-2 rounded-md font-elight text-md text-ellipsis w-full">{props.data.spark.text.slice(0, 50)}{props.data.spark.text.length > 30 ? '...' : ''}</div>
                </div>
            </div>
        </div>
    )
}

export default Notification;