// Icons for space card

import { useContext, useState } from "react";

import SparkContent from "../card-content/SparkContent";
import AuthContext from "../../store/auth-context";
import CentreCardWrapper from "./CentreCardWrapper";
import SurCardWrapper from "./SurCardWrapper";

const Card = (props) => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme
    const cardBg = theme.card;
    const centreHighlight = theme.highlight;
    const [isHovering, setIsHovering] = useState(false);

    const hoverLeave = () => {
        setIsHovering(false);
    }

    const hoverEnter = () => {
        setIsHovering(true);
    }

    if (props.state === 'spark') {
        return (
            <SurCardWrapper bg={cardBg} hoverEnter={hoverEnter} hoverLeave={hoverLeave}>
                <SparkContent
                    type='normal'
                    onResultsUpdate={props.onResultsUpdate}
                    data={props.data}
                    collHandle={props.collHandle}
                    isHovering={isHovering} />
            </SurCardWrapper>
        );
    };


    if (props.state === 'centre') {
        return (
            <CentreCardWrapper bg={cardBg} highlight={centreHighlight} hoverEnter={hoverEnter} hoverLeave={hoverLeave}>
                <SparkContent
                    type="centre"
                    conSparkState={props.conSparkState}
                    collHandle={props.collHandle}
                    data={props.data}
                    isHovering={isHovering}
                    onRandomise={props.onRandomise}
                    pageReset={props.pageReset} />
            </CentreCardWrapper>
        );
    };

};

export default Card;