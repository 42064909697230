import { Fragment } from "react";
import { ReactComponent as Light } from '../../../icons/themeIcons/Light.svg'
import { ReactComponent as Dark } from '../../../icons/themeIcons/Dark.svg'
import { ReactComponent as Platypus } from '../../../icons/themeIcons/Platypus.svg'
import { ReactComponent as Sugar } from '../../../icons/themeIcons/Sugar.svg'
import { ReactComponent as Oz } from '../../../icons/themeIcons/Oz.svg'
import { ReactComponent as Hive } from '../../../icons/themeIcons/Hive.svg'
import { ReactComponent as Slime } from '../../../icons/themeIcons/Slime.svg'
import { ReactComponent as Custom } from '../../../icons/themeIcons/Custom.svg'

const ThemeChoiceForm = (props) => {

    const updateTheme = props.updateTheme;
    const curTheme = props.curTheme;
    const subType = props.subType;

    const radioStyle = "absolute top-0 left-0 h-full w-full form-check-input cursor-pointer appearance-none rounded-lg border-2 checked:border-darkgrey"
    const wrapperStyle = "relative h-20 w-20 p-2 flex flex-col gap-1 place-content-center"
    const svgStyle = "-ml-1"
    const labelStyle = "text-xs font-elight text-darkgrey/70 text-center"

    return (
        <div className="flex flex-col gap-4 place-content-center pt-2" onChange={updateTheme}>
            <div className="flex flex-row gap-4 place-content-center">
                <div className={wrapperStyle}>
                    <input readOnly className={radioStyle} name="theme" type="radio" value="DF" checked={curTheme === 'DF'} />
                    <Light className={svgStyle} />
                    <div className={labelStyle}>Default</div>
                </div>
                <div className={wrapperStyle}>
                    <input readOnly className={radioStyle} name="theme" type="radio" value="BL" checked={curTheme === 'BL'} />
                    <Dark className={svgStyle} />
                    <div className={labelStyle}>Dark</div>
                </div>
                {subType !== 'LIT' && (
                    <Fragment>
                        <div className={wrapperStyle}>
                            <input readOnly className={radioStyle} name="theme" type="radio" value="YE" checked={curTheme === 'YE'} />
                            <Sugar className={svgStyle} />
                            <div className={labelStyle}>Sugar</div>
                        </div>
                        <div className={wrapperStyle}>
                            <input readOnly className={radioStyle} name="theme" type="radio" value="GR" checked={curTheme === 'GR'} />
                            <Platypus className={svgStyle} />
                            <div className={labelStyle}>Platypus</div>
                        </div>
                    </Fragment>
                )}
            </div>
            {subType !== 'LIT' && (
                <div className="flex flex-row gap-4 place-content-center">
                    <div className={wrapperStyle}>
                        <input readOnly className={radioStyle} name="theme" type="radio" value="OZ" checked={curTheme === 'OZ'} />
                        <Oz className={svgStyle} />
                        <div className={labelStyle}>Oz</div>
                    </div>
                    <div className={wrapperStyle}>
                        <input readOnly className={radioStyle} name="theme" type="radio" value="HI" checked={curTheme === 'HI'} />
                        <Hive className={svgStyle} />
                        <div className={labelStyle}>Hive</div>
                    </div>
                    <div className={wrapperStyle}>
                        <input readOnly className={radioStyle} name="theme" type="radio" value="SL" checked={curTheme === 'SL'} />
                        <Slime className={svgStyle} />
                        <div className={labelStyle}>Slime</div>
                    </div>
                    <div className={wrapperStyle}>
                        <input readOnly className={radioStyle} name="theme" type="radio" value="CS" checked={curTheme === 'CS'} />
                        <Custom className={svgStyle} />
                        <div className={labelStyle}>Custom</div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ThemeChoiceForm;