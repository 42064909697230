
// Standard container for the grid - formats to min-width of the standard tailwind breakpoints

const Outer = (props) => {
        return (
            <div className="max-w-[400px] sm:container relative flex place-content-center mx-auto h-[86%] my-3 xl:my-7">
                {props.children}
            </div>
        );
};

export default Outer;