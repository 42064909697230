import { useParams } from "react-router-dom"
import { Fragment, useContext, useEffect } from "react";
import useHttp from "../hooks/use-http";
import { resetPassword } from "../lib/user-api";
import Navigation from "../components/layout/Navigation";
import FormContainer from "../components/forms/FormContainer";
import ResetPasswordForm from "../components/forms/ResetPasswordForm";
import Confirmation from "../components/confirmation/Confirmation";
import AuthContext from "../store/auth-context";


const ResetPassword = () => {
    const authCtx = useContext(AuthContext);
    const setTheme = authCtx.updateTheme;
    const params = useParams();

    useEffect(() => {
        setTheme('DF');
    })

    const uid = params.uid;
    const token = params.token;

    const { 
        sendRequest, 
        error, 
        status 
    } = useHttp(resetPassword);

    const submitNewPw = (password) => {
        sendRequest({
            uid: uid,
            token: token,
            new_password: password
        })
    }

    if (error) {
        return <div className="text-darkgrey font-elight text-lg">Something went wrong</div>
    }

    return (
        <Fragment>
            <Navigation state={'myGroups'} />
            <FormContainer>
                {status !== 'completed' && (
                    <ResetPasswordForm onSubmit={submitNewPw} loadingStatus={status} />
                )}
                {status === 'completed' && (
                    <Confirmation 
                    title={'Password Reset Successful'}
                    msg={'Sign in with your new password'}
                    isActivation={true}
                    isLoading={false}
                    error={false}
                     />
                )}
            </FormContainer>
        </Fragment>
    )
}

export default ResetPassword;