import { Fragment, useContext } from "react";
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useRef } from "react";
import AuthContext from "../../store/auth-context";
import ThemeChoiceForm from "./themeSelection/ThemeChoiceForm";
import CustomThemeForm from "./themeSelection/CustomThemeForm";
import { isValidHexCode } from "../../store/themesConfig"

import { ReactComponent as InfinityLogo } from '../../icons/ClustaInfinity2.svg'

const NewClustaForm = (props) => {
    const authCtx = useContext(AuthContext);
    const subType = authCtx.sub_type;

    // curTheme defines current choice for collection theme
    const [curTheme, setCurTheme] = useState('DF')

    // the following states record custom theme colour choices
    const [csHighlight, setCsHighlight] = useState('FF6261');
    const [csCard, setCsCard] = useState('FFFFFF');
    const [csBg, setCsBg] = useState('EEEEEE');

    // all cs colour states in an object, easier to pass into CustomThemeForm component
    const allCsCols = {
        bg: csBg,
        card: csCard,
        highlight: csHighlight
    }

    const handleRef = useRef()
    const displaynameRef = useRef()

    function submitCollectionFormHandler(event) {
        event.preventDefault();

        const enteredHandle = handleRef.current.value;
        const enteredDisplayname = displaynameRef.current.value;
        const selectedTheme = curTheme;

        const formattedHandle = enteredHandle.replace(/\s/g, '');

        const validHighlight = isValidHexCode(csHighlight)
        const validCard = isValidHexCode(csCard)
        const validBg = isValidHexCode(csBg)
        const validCsTheme = validBg && validCard && validHighlight

        if (formattedHandle.match(/[^\w\-]/) || formattedHandle === 'home' || formattedHandle === 'auth' || formattedHandle === 'activate' || !validCsTheme) {
            return;
        } else {
            props.onSubmit({
                handle: subType === 'LIT' ? 'abc' : formattedHandle,
                displayname: enteredDisplayname,
                theme: selectedTheme,
                custom_theme: {
                    bg: `#${csBg}`,
                    card: `#${csCard}`,
                    highlight: `#${csHighlight}`
                }
            });
        }
    }

    // themeHandler handles selection of different theme option
    const themeHandler = (e) => {
        setCurTheme(e.target.value);
    }

    return (
        <Fragment>
            <div className='px-5 py-5'>
                <div className="flex place-content-center text-darkgrey text-3xl font-elight">Create a New Collection</div>
                <div className="mt-5">
                    <div className="flex flex-col mb-6 md:w-full">
                        <input className="font-elight text-lg p-3 outline-none text-darkgrey placeholder-darkgrey/70 bg-transparent border-darkgrey/30 border-b" type="text" placeholder="Name (spaces allowed)" maxLength="30" ref={displaynameRef} />
                    </div>
                    <div className="relative flex flex-col mb-6 md:w-full">
                        <input className="font-elight text-lg p-3 outline-none text-darkgrey placeholder-darkgrey/70 bg-transparent border-darkgrey/30 border-b" type="text" placeholder="Handle (app.clusta.live/yourhandle)" maxLength="30" ref={handleRef} />
                        {subType === 'LIT' && (
                            <Link to="/auth/upgrade" className="absolute h-full w-full bg-salmon opacity-0 hover:opacity-100 transition-all text-3xl flex place-content-center">
                                <InfinityLogo style={{ 'color': `white` }} className="h-full" />
                            </Link>
                        )}
                    </div>
                </div>
                <div className="flex place-content-center text-darkgrey text-2xl font-elight">Theme</div>
                <ThemeChoiceForm updateTheme={themeHandler} curTheme={curTheme} subType={subType} />
                {curTheme === 'CS' && (
                    <CustomThemeForm
                        colours={allCsCols}
                        setCsBg={setCsBg}
                        setCsCard={setCsCard}
                        setCsHighlight={setCsHighlight}
                    />
                )}
                <div onClick={submitCollectionFormHandler} className="mt-7 mx-20 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey border border-darkgrey/30 hover:scale-105 hover:bg-salmon hover:border-salmon hover:text-white transition ease-in-out">Create</div>
            </div>
        </Fragment>
    );
};

export default NewClustaForm;