import { useState, useEffect } from "react";


const ResetPasswordForm = (props) => {

    const [curPw, setCurPw] = useState('');
    const [rePw, setRePw] = useState('');
    const [pwValid, setPwValid] = useState(curPw === rePw);

    const pwChange = (e) => {
        setCurPw(e.target.value);
    }

    const rePwChange = (e) => {
        setRePw(e.target.value);
    }

    const submitPw = () => {
        if (pwValid) {
            props.onSubmit(curPw);
        }
    };

    useEffect(() => {
        const identifier = setTimeout(() => {
            setPwValid(
                curPw === rePw
            );
        }, 500);

        return () => {
            clearTimeout(identifier);
        };
    }, [rePw, curPw, setPwValid]);

    const pwFormClass = pwValid ? "font-elight md:text-lg p-3 outline-none text-white bg-transparent border-white border-b" : "font-elight md:text-lg p-3 outline-none text-white bg-transparent border-rose-500 border-b";

    return (
        <div className='w-full h-full flex flex-col place-content-center px-4 sm:px-6'>
            <div>
                <div className="ml-2 mb-4 py-4 text-white text-3xl font-elight">Reset your password</div>
                <div className="mb-4 md:flex md:flex-wrap md:justify-between">
                    <div className="flex flex-col mb-4 md:w-full">
                        <input className={pwFormClass} type="password" placeholder="new password" value={curPw} onChange={pwChange} />
                    </div>
                    <div className="flex flex-col mb-4 md:w-full">
                        <input className={pwFormClass} type="password" placeholder="new password (again)" value={rePw} onChange={rePwChange} />
                    </div>
                </div>
                {props.loadingStatus !== 'pending' && (
                    <div onClick={submitPw} className="mt-8 mb-3 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-white border hover:scale-105 hover:border-green-400 transition ease-in-out">Reset Password</div>
                )}
                {props.loadingStatus === 'pending' && (
                    <div className="mt-8 mb-3 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-white border">...</div>
                )}
            </div>
        </div>
    );
}

export default ResetPasswordForm;