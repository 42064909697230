import AuthContext from "../../store/auth-context";
import { textColourChoice } from "../../store/themesConfig";
import NewConSparkForm from "../forms/NewConSparkForm";
import { useContext, useState } from "react";

const NewConSparkCard = (props) => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme;
    const bgColour = theme.card;
    const textColour = textColourChoice(bgColour)
    const [isHovering, setIsHovering] = useState(false);

    const hoverLeave = () => {
        setIsHovering(false);
    }

    const hoverEnter = () => {
        setIsHovering(true);
    }

    return (
        <div
        style={{'backgroundColor': `${bgColour}`, 'color': `${textColour}`, 'stroke': `${textColour}`, 'fill': `${textColour}` }}
            onMouseEnter={hoverEnter}
            onMouseLeave={hoverLeave}
            className="absolute top-0 left-0 ml-2 sm:ml-6 2xl:ml-8 h-[33%] sm:h-[32%] w-[125px] sm:w-[185px] md:w-[230px] lg:w-[315px] xl:w-[400px] 2xl:w-[480px] flex place-content-center shadow-md rounded-lg">
            <NewConSparkForm
                isHovering={isHovering}
                collHandle={props.collHandle}
                centreSpark={props.centreSpark}
                conSparkState={props.conSparkState} />
        </div>
    );
}

export default NewConSparkCard;