import { Fragment } from "react";
import NewSparkResults from "../components/results-state/NewSparkResults";


const NewSpark = (props) => {
    return (
        <Fragment>
            <NewSparkResults collection={props.collection} />
        </Fragment>
    )
}

export default NewSpark;