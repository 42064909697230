import { useContext } from "react";
import AuthContext from "../../store/auth-context";

const LoadingCard = () => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme;
    const cardBg = theme.card;
    return (
        <div style={{'backgroundColor': `${cardBg}`}} className="relative flex place-content-center rounded-lg animate-pulse"></div>
    )
}

export default LoadingCard;