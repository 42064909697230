import { Fragment, useContext } from "react"

import Grid from "../grid/Grid"
import GridContainer from "../grid/Container"

import InvisCard from "../cards/InvisCard"
import CollectionCard from "../cards/CollectionCard"
import HeadingCard from "../cards/HeadingCard"
import IntroCard from "../cards/IntroCard"
import { connectedConfig } from "../../hooks/results-format"
import AuthContext from "../../store/auth-context"


const MyCollectionsResults = (props) => {

    const authCtx = useContext(AuthContext);

    const curPage = props.curPage;
    const splitResults = connectedConfig(props.results);

    const isLite = authCtx.sub_type === 'LIT';
    const isMax = props.results.length >= 8;

    const showLock = isLite && isMax;

    return (
        <Fragment>
            <GridContainer page={curPage}>
                {props.results.length === 0 && (
                    <Grid type="list">
                        <IntroCard data={null} type='MyColls' />
                        <IntroCard data={null} type='brightSparks' />
                        {authCtx.sub_type === 'LIT' && (
                            <IntroCard data={null} type='infinity' />
                        )}
                    </Grid>
                )}
                {props.results.length > 0 && (
                    <Fragment>
                        {splitResults.map((grid, i) => (
                            <Grid type="list" key={i}>
                                {grid.length > 4 && (
                                    <InvisCard />
                                )}
                                {grid.map((card) => (
                                    <CollectionCard key={card.id} data={card} />
                                ))}
                            </Grid>
                        ))}
                    </Fragment>
                )}
            </GridContainer>
            {!showLock && (
                <HeadingCard type="mycollections" />
            )}
            {showLock && (
                <HeadingCard type="lockedmycollections" />
            )}
        </Fragment>
    )
}

export default MyCollectionsResults;