import Confirmation from "../components/confirmation/Confirmation"

const SignupConfirm = () => {

    return (
        <Confirmation
            title={'Signup Successful'}
            msg={'Activate your account by clicking the link sent to your email address.'}
            isLoading={false}
            error={false}
            isActivation={false}
        />
    )
}

export default SignupConfirm;