import { Fragment } from "react"
import { Link } from "react-router-dom";

const Confirmation = (props) => {
    return (
        <div className='w-full h-full absolute top-0 left-0 flex flex-col place-content-center px-4 sm:px-6'>
            {(props.isLoading && !props.error) && (
                <div className="text-darkgrey font-elight flex place-content-center text-lg">Loading...</div>
            )}
            {(!props.isLoading && !props.error) && (
                <Fragment>
                    <div className="ml-2 py-4 text-darkgrey flex place-content-center text-3xl font-elight">{props.title}</div>
                    <div className="ml-2 mb-4 flex place-content-center text-darkgrey text-base font-elight">{props.msg}</div>
                    {props.title === 'Signup Successful' && (
                        <Fragment>
                            <div className="text-sm mx-auto font-elight">Haven't received an email after 5 minutes?</div>
                            <Link to={'/auth/resend_activation'} className="mx-auto rounded-md mt-2 cursor-pointer p-3 text-sm font-elight text-darkgrey border border-darkgrey/50 hover:scale-110 hover:border-salmon hover:bg-salmon hover:text-white transition duration-75 ease-in-out">
                                Resend activation
                            </Link>
                        </Fragment>
                    )}
                    {props.isActivation && (
                        <Link
                            to={'/auth/login'}
                            className="mt-5 mb-3 mx-10 md:mx-40 rounded-lg flex place-content-center cursor-pointer p-3 text-sm font-elight text-darkgrey hover:text-white border border-darkgrey/50 hover:border-salmon hover:scale-105 hover:bg-salmon transition ease-in-out"
                        >
                            Login
                        </Link>
                    )}
                </Fragment>
            )}
            {props.error && (
                <div className="text-darkgrey font-elight text-lg">Something went wrong</div>
            )}
        </div>
    )
}

export default Confirmation;