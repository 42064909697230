import NewSparkForm from "../forms/NewSparkForm";
import { useContext, useState } from "react";
import AuthContext from "../../store/auth-context";
import CentreCardWrapper from "./CentreCardWrapper";

const NewSparkCard = (props) => {
    const authCtx = useContext(AuthContext);
    const theme = authCtx.theme
    const cardBg = theme.card;
    const centreHighlight = theme.highlight;
    const [isHovering, setIsHovering] = useState(false);

    const hoverLeave = () => {
        setIsHovering(false);
    }

    const hoverEnter = () => {
        setIsHovering(true);
    }

    return (
        <CentreCardWrapper bg={cardBg} highlight={centreHighlight} hoverEnter={hoverEnter} hoverLeave={hoverLeave}>
            <NewSparkForm
                    data={props.data}
                    isHovering={isHovering} />
        </CentreCardWrapper>
    );
}

export default NewSparkCard;