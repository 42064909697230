import { Fragment } from "react"
import { Transition } from '@headlessui/react'

import Grid from "../grid/Grid"
import GridContainer from "../grid/Container"
import Card from "../cards/Card"
import LoadingCard from "../cards/LoadingCard"

import InvisCard from "../cards/InvisCard"
import HeadingCard from "../cards/HeadingCard"
import Outer from "../grid/Outer"
import { useContext } from "react"
import MemberContext from "../../store/isMember-context"
import { useEffect, useState } from "react"
import { connectedConfig } from "../../hooks/results-format"


const ExploreCollectionResults = (props) => {

    const memberCtx = useContext(MemberContext);
    const curSearch = props.curSearch;
    const results = props.results;

    const status = props.status;
    const error = props.error;

    const splitResults = connectedConfig(results);

    const [curPage, setCurPage] = useState(0);
    const pageCount = props.results === (props.results.length / 8) % 1 === 0 ? (props.results.length / 8) : (Math.floor(props.results.length / 8) + 1);

    // these handler functions will format to array.length of results once api connection is established

    const pageUpHandler = () => {
        setCurPage((prevState) => prevState >= pageCount - 1 ? 0 : (prevState + 1))
    };

    const pageDwnHandler = () => {
        setCurPage((prevState) => prevState <= 0 ? pageCount - 1 : (prevState - 1))
    };

    const pageResetHandler = () => {
        setCurPage(0);
    }

    useEffect(() => {
        if (curSearch.length === 0) {
            pageResetHandler();
        }
    })

    return (
        <Fragment>
            <div onClick={pageUpHandler} className="absolute z-50 right-0 h-[80%] w-[9%] my-auto"></div>
            <div onClick={pageDwnHandler} className="absolute z-50 left-0 h-[80%] w-[9%] my-auto"></div>
            <Outer>
                <Transition
                    className={'w-full h-full'}
                    appear={true}
                    show={true}
                    enter="transition-opacity duration-700"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {memberCtx.state && (
                        <Fragment>
                            {(status !== 'completed' && (
                                <GridContainer>
                                    <Grid type="list">
                                        <LoadingCard />
                                        <LoadingCard />
                                        <LoadingCard />
                                        <LoadingCard />
                                        <LoadingCard />
                                        <LoadingCard />
                                        <LoadingCard />
                                        <LoadingCard />
                                        <InvisCard />
                                    </Grid>
                                </GridContainer>
                            ))}
                            {
                                (status === 'completed' && !error) && (
                                    <GridContainer page={curPage}>
                                        <Fragment>
                                            {splitResults.map((grid, i) => (
                                                <Grid type="list" key={i}>
                                                    {grid.length > 4 && (
                                                        <InvisCard />
                                                    )}
                                                    {grid.map((card) => (
                                                        <Card state='spark' key={card.id} collHandle={props.collHandle} data={{ connected: card.connected_count, ...card }} />
                                                    ))}
                                                </Grid>
                                            ))}
                                        </Fragment>
                                    </GridContainer>
                                )
                            }
                        </Fragment>
                    )}
                    <HeadingCard type='explore' />
                </Transition>
            </Outer>
        </Fragment>
    )
}

export default ExploreCollectionResults;